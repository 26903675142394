import { SurveyUtils } from '@valotvince/equireglementation-utils';

const defaultSurvey = {
  title: '...',
  categories: [],
};

const defaultCategory = {
  questions: [],
};

export const getSurveys = ({ survey: { surveys } }) => surveys;

export const getFirstSurveyId = ({ survey: { surveys } }) => {
  const orderedSurveyIds = Object.keys(surveys);
  orderedSurveyIds.sort((a, b) => a.order - b.order);

  return orderedSurveyIds[0];
};

export const getSurveysAnswers = ({ survey: { answers } }) => answers;

export const getSurvey = surveyId => ({ survey: { surveys } }) => surveys[surveyId] || defaultSurvey;

export const isSurveyPaymentEnabled = surveyId => state => {
  const { payment } = getSurvey(surveyId)(state);

  return payment && payment.enabled;
};

export const getSurveyCategory = (surveyId, categoryId) => state =>
  getSurvey(surveyId)(state).categories.find(({ id }) => id === categoryId) || defaultCategory;

export const getSurveySideCategory = (surveyId, categoryId, orderAddition = 1) => state => {
  const survey = getSurvey(surveyId)(state);
  const category = survey.categories.find(({ id }) => id === categoryId);

  if (!category) {
    return null;
  }

  return survey.categories.find(({ order }) => order === category.order + orderAddition);
};

export const getSurveyQuestions = surveyId => state =>
  getSurvey(surveyId)(state).categories.reduce((accumulator, category) => [...accumulator, ...category.questions], []);

export const getSurveyAnsweredQuestions = surveyId => state => {
  const answers = getSurveysAnswers(state);

  return getSurveyQuestions(surveyId)(state).filter(
    ({ id, conditions = [] }) => answers[id] || !SurveyUtils.areConditionsMet('show', conditions),
  );
};

export const getSurveyAnsweredPercentage = surveyId => state =>
  Math.round((getSurveyAnsweredQuestions(surveyId)(state).length / getSurveyQuestions(surveyId)(state).length) * 100);

export const getGivenAnswerForQuestion = questionId => state => state.survey.answers[questionId];
