import React, { ComponentType } from 'react';
import { createStackNavigator as createNativeStackNavigator } from '@react-navigation/stack';
import { NavigationProp } from '@react-navigation/core';
import { Platform } from 'react-native';

import { Header } from './components/header.component';

type SurveyTabRouteOptions = Record<string, any> & {
  screen: string;
  surveyId: string;
};

type NavigateRoute = [string, Record<string, any>];

export const getSurveyTabRoute = ({ screen, surveyId = 'review', ...extraParams }: SurveyTabRouteOptions) => ({
  ...extraParams,
  surveyId,
  screen: 'SurveyTab',
  params: { screen },
});

export const getMessagingRoute = (defaultExpandMessageId?: string): NavigateRoute => [
  'MessagingModal',
  { defaultExpandMessageId },
];

// Use openExternalLink instead
export const getWebviewRoute = (uri: string, withExternalNavigatorInsentive = true): NavigateRoute => [
  'WebViewModal',
  { uri, withExternalNavigatorInsentive },
];

type StackNavigatorOptions = {
  component: ComponentType<any>;
  name: string;
  withHeader: boolean;
};

export const createStackNavigator = ({ component, name, withHeader = false }: StackNavigatorOptions) => {
  const Stack = createNativeStackNavigator();

  const screenOptions = withHeader ? { header: Header } : undefined;

  return () => (
    <Stack.Navigator screenOptions={screenOptions}>
      <Stack.Screen name={name} component={component} />
    </Stack.Navigator>
  );
};

export const openExternalLink = (navigation: NavigationProp<any>, uri: string) => {
  if (Platform.OS === 'web') {
    window.open(uri, '_blank');

    return;
  }

  navigation.navigate(...getWebviewRoute(uri));
};
