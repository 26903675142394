import React, { memo, useState, useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View, TouchableOpacity, Animated } from 'react-native';
import styled, { css } from 'styled-components/native';

import { Text } from './text.component';
import { colors } from '../constants/colors';
import { getThemeColor, condition } from '../utils/theme.utils';
import { Icon } from './icon.component';
import { isRemoteTextDefined } from '../../data/firebase';

export const MessageTypes = {
  ERROR: 'ERROR',
  INFO: 'INFO',
};

export const MessageSizes = {
  SMALL: 'SMALL',
  NORMAL: 'NORMAL',
};

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'column',
    alignContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  collapseTitle: { flexDirection: 'row', alignContent: 'center', alignItems: 'center', flex: 1 },
  collapseIcon: { flex: 1, alignItems: 'flex-end' },
  text: {
    color: colors.secondary,
    fontWeight: '500',
    fontSize: 20,
    textAlign: 'center',
    flexShrink: 1,
  },
  [`text${MessageSizes.SMALL}`]: {
    fontSize: 16,
  },
});

const paddingVertical = 10;

const Wrapper = styled(View)`
  background-color: ${getThemeColor('primary')};
  padding-vertical: 10px;
  padding-horizontal: 20px;
  margin-vertical: 10px;
  border-radius: 5px;

  ${condition(
    { type: MessageTypes.ERROR },
    css`
      background-color: ${colors.red};
    `,
  )}
`;

const MessageTextWrapper = styled(View)`
  flex-direction: row;
  align-content: center;
  align-items: center;
  flex-basis: auto;
`;

export const Message = memo(
  ({
    text,
    subText,
    type = MessageTypes.INFO,
    size = MessageSizes.NORMAL,
    centered = false,
    defaultCollapsed = false,
    title,
    iconName,
    contentRemoteKey,
    onPress,
  }) => {
    const useCollapsed = !!title;
    const [maxHeight, setMaxHeight] = useState(0);
    const [collapsed, setCollapsed] = useState(defaultCollapsed);
    const [measuring, setMeasuring] = useState(false);
    const animatedHeight = useRef(new Animated.Value(0)).current;

    const toggleCollapse = () => {
      Animated.timing(animatedHeight, {
        duration: 200,
        toValue: collapsed ? maxHeight : 0,
        useNativeDriver: false,
      }).start();

      setCollapsed(!collapsed);
      onPress(!collapsed);
    };

    let opacity = 1;

    if (useCollapsed) {
      opacity = maxHeight ? 1 : 0;

      if (collapsed) {
        opacity = 0;
      }
    }

    useLayoutEffect(() => {
      setMaxHeight(0);
      setCollapsed(true);
      setMeasuring(true);

      animatedHeight.setValue(0);
    }, [text]);

    if (contentRemoteKey && !isRemoteTextDefined(contentRemoteKey)) {
      return null;
    }

    const icon = iconName ? <Icon name={iconName} size={30} color={colors.iconFocused} marginRight="10" /> : null;

    const MessageComponentWrapper = onPress && !useCollapsed ? TouchableOpacity : Animated.View;

    const messageStyle = {
      opacity,
      height: useCollapsed && !measuring ? animatedHeight : undefined,
      visibility: opacity ? 'visible' : 'hidden',
    };

    if (measuring) {
      messageStyle.position = 'absolute';
      messageStyle.opacity = 0;
      messageStyle.visibility = 'hidden';
      messageStyle.height = undefined;
    }

    return (
      <Wrapper type={type}>
        {useCollapsed ? (
          <TouchableOpacity accessibilityLabel={title} style={styles.collapseTitle} onPress={toggleCollapse}>
            {icon}
            <Text numberOfLines={2} style={[styles.text, styles[`text${size}`], { textAlign: 'left' }]}>
              {title}
            </Text>
            <View style={styles.collapseIcon}>
              <Animated.View
                style={{
                  transform: [
                    {
                      rotateZ: animatedHeight.interpolate({
                        inputRange: [0, maxHeight],
                        outputRange: ['0deg', '90deg'],
                      }),
                    },
                  ],
                }}
              >
                <Icon name="arrow-forward" size={30} color={colors.iconFocused} />
              </Animated.View>
            </View>
          </TouchableOpacity>
        ) : null}
        <MessageComponentWrapper
          as={onPress && !useCollapsed ? TouchableOpacity : Animated.View}
          style={[styles.wrapper, messageStyle]}
          onPress={onPress}
        >
          <MessageTextWrapper>
            {!useCollapsed && icon}
            <Text
              onLayout={event => {
                const { height } = event.nativeEvent.layout;

                if (measuring && height && !maxHeight) {
                  setMaxHeight(height + paddingVertical * 2);
                  setMeasuring(false);
                }
              }}
              style={[
                styles.text,
                styles[`text${size}`],
                { textAlign: centered ? 'center' : undefined, paddingVertical },
              ]}
              remoteKey={contentRemoteKey}
            >
              {text}
            </Text>
          </MessageTextWrapper>
          {subText && <Text fontSize="14">{subText}</Text>}
        </MessageComponentWrapper>
      </Wrapper>
    );
  },
);

Message.propTypes = {
  text: PropTypes.string,
  subText: PropTypes.string,
  contentRemoteKey: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string,
  centered: PropTypes.bool,
  defaultCollapsed: PropTypes.bool,
  title: PropTypes.string,
  iconName: PropTypes.string,
  onPress: PropTypes.func,
};
