import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { TouchableOpacity, StyleSheet, Platform, Animated } from 'react-native';

import { Text } from './text.component';

const shadow = {
  shadowColor: 'black',
  shadowOffset: { height: 2, width: 2 },
  shadowOpacity: 0.1,
  shadowRadius: 3,
};

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    ...Platform.select({
      ios: shadow,
      web: shadow,
    }),
  },
  inner: {
    color: 'white',
    fontSize: 20,
    textAlign: 'center',
    fontWeight: 'bold',
  },
});

// Animated value to 0.01 to prevent a graphic bug on Android animation
const scaleZeroValue = Platform.select({ ios: 0, android: 0.01, web: 0 });

export class Bubble extends PureComponent {
  constructor(props) {
    super(props);

    const { backgroundColor, animate } = this.props;

    this.state = {
      backgroundColor,
      scale: new Animated.Value(animate ? scaleZeroValue : 1),
    };
  }

  componentDidMount() {
    const { animate } = this.props;

    if (animate) {
      this.animate();
    }
  }

  componentDidUpdate(prevProps) {
    const { backgroundColor: targetBackgroundColor, animate } = this.props;

    if (animate && prevProps.backgroundColor !== targetBackgroundColor) {
      this.animate();
    }
  }

  animate() {
    const { backgroundColor: targetBackgroundColor } = this.props;
    const { scale } = this.state;

    Animated.timing(scale, {
      toValue: scaleZeroValue,
      duration: 200,
      useNativeDriver: Platform.select({ web: false, native: true }),
    }).start(() => {
      this.setState({ backgroundColor: targetBackgroundColor }, () => {
        Animated.timing(scale, {
          toValue: 1,
          duration: 200,
          useNativeDriver: Platform.select({ web: false, native: true }),
        }).start();
      });
    });
  }

  render() {
    const { scale, backgroundColor } = this.state;
    const { text, color, fontSize, style, innerStyle, size, ...otherProps } = this.props;

    return (
      <TouchableOpacity
        {...otherProps}
        delayPressIn={50}
        style={[
          styles.wrapper,
          style,
          {
            backgroundColor,
            color,
            height: size,
            width: size,
            borderRadius: size,
            transform: [{ scale }],
          },
        ]}
      >
        {text && <Text style={[styles.inner, innerStyle, { fontSize }]}>{text}</Text>}
      </TouchableOpacity>
    );
  }
}

Bubble.defaultProps = {
  size: 40,
  color: 'black',
  textColor: 'white',
  fontSize: 20,
  animate: true,
};

Bubble.propTypes = {
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.number,
  fontSize: PropTypes.number,
  style: PropTypes.any,
  innerStyle: PropTypes.object,
  text: PropTypes.any,
  animate: PropTypes.bool,
};
