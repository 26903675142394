import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FlatList } from 'react-native';
import { EquiReglementation } from '@valotvince/equireglementation-utils';

import { withErrorBoundary } from '../../components/errorBoundary.component';
import { ResponsiveFlatList } from '../ui/components/responsiveFlatList.component';
import { Card, CARD_HEIGHT } from '../ui/components/card.component';
import { Icon } from '../ui/components/icon.component';
import { colors } from '../ui/constants/colors';
import { Chip } from '../ui/components/chip.component';
import { useBreakpoint } from '../ui/utils/theme.utils';
import { BREAKPOINTS } from '../ui/constants/breakpoints';
import { Picker } from '../ui/components/picker.component';
import { getRemoteText } from '../data/firebase';
import { Typography } from '../ui/components/typography.component';

type CoachingDataScreenProps = {
  dataFetcher: () => void;
  dataSelector: () => any[];
  dataFetchedSelector: () => boolean;
  categoriesSelector: () => EquiReglementation.CoachingCategory[];

  noDataTextKey: string;
  categoryTextKey: string;

  analyticsConfig: {
    category: string;
    label: string;
  };

  defaultItemImage?: string;
};

export const CoachingDataScreen = withErrorBoundary(
  ({
    dataFetcher,
    dataSelector,
    dataFetchedSelector,
    analyticsConfig,
    defaultItemImage,
    categoriesSelector,
    noDataTextKey,
    categoryTextKey,
  }: CoachingDataScreenProps) => {
    const dispatch = useDispatch();
    const data = useSelector(dataSelector);
    const dataFetched = useSelector(dataFetchedSelector);
    const categories = useSelector(categoriesSelector);

    const [currentCategory, setCurrentCategory] = useState(categories.length ? categories[0] : undefined);

    const [refreshing, setRefreshing] = useState(false);

    const onRefresh = useCallback(async () => {
      setRefreshing(true);

      await dispatch(dataFetcher());

      setRefreshing(false);
    }, [dispatch]);

    useEffect(() => {
      dispatch(dataFetcher());
    }, []);

    const renderItem = useCallback(
      ({ item: { id, uri, date, name, ...item } }) => {
        const { category, label } = analyticsConfig;

        return (
          <Card
            key={id}
            title={name}
            styles={{
              content: {
                paddingRight: uri ? 50 : undefined,
              },
            }}
            linkProps={{
              to: uri,
              analytics: uri ? [`${category}_open_${label}`, { name, uri }] : undefined,
              hrefAttrs: {
                target: uri ? '_blank' : undefined,
              },
            }}
            defaultImage={defaultItemImage}
            extraDescription={date}
            {...item}
          >
            {uri ? (
              <Icon
                style={[
                  {
                    position: 'absolute',
                    right: 10,
                    top: CARD_HEIGHT / 2 - 15,
                  },
                ]}
                size={30}
                name="open"
                color={colors.iconFocused}
              />
            ) : null}
          </Card>
        );
      },
      [analyticsConfig],
    );

    const filteredData = useMemo(
      () => (currentCategory ? data.filter(({ category }) => currentCategory.id === category) : data),
      [currentCategory, data],
    );

    const ListHeaderComponentMedium = useMemo(
      () => (
        <FlatList
          horizontal
          data={categories}
          contentContainerStyle={{ paddingVertical: 5 }}
          renderItem={({ item }) => (
            <Chip
              label={item.name}
              full={currentCategory && currentCategory.id === item.id}
              onPress={() => setCurrentCategory(item)}
            />
          )}
        ></FlatList>
      ),
      [currentCategory, categories],
    );

    const ListHeaderComponentSmall = useMemo(
      () => (
        <Picker
          value={currentCategory && currentCategory.id}
          placeholder={getRemoteText(categoryTextKey)}
          values={categories.map(({ id: key, name: label }) => ({ key, label }))}
          onValueChange={(value: string) => setCurrentCategory(categories.find(({ id }) => value === id))}
          height={200}
        ></Picker>
      ),
      [currentCategory, categories],
    );

    const ListHeaderComponent = useBreakpoint(
      { [BREAKPOINTS.MEDIUM]: ListHeaderComponentMedium },
      ListHeaderComponentSmall,
    );

    const ListEmptyComponent = useMemo(() => <Typography>{getRemoteText(noDataTextKey)}</Typography>, [noDataTextKey]);

    return (
      <ResponsiveFlatList
        data={filteredData}
        loading={!dataFetched}
        renderItem={renderItem}
        onRefresh={onRefresh}
        refreshing={refreshing}
        ListEmptyComponent={ListEmptyComponent}
        ListHeaderComponentStyle={{ marginBottom: categories.length ? 10 : 0 }}
        ListHeaderComponent={ListHeaderComponent}
      />
    );
  },
);
