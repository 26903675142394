import { AppRegistry, Platform } from 'react-native';

import { Root } from './src/root';
import * as Sentry from './src/utils/sentry';

Sentry.init();

AppRegistry.registerComponent('main', () => Root);

if (Platform.OS === 'web') {
  AppRegistry.runApplication('main', { rootTag: document.getElementById('react-root') });
}
