import React from 'react';
import { BottomTabNavigationOptions } from '@react-navigation/bottom-tabs';

import { PassesResultsScreen } from '../survey/screens/passesResults.screen';
import { PassesCertificatesScreen } from '../survey/screens/passesCertificates.screen';

import { TabBarIcon } from './components/tabBarIcon.component';
import { TabBarLabel } from './components/tabBarLabel.component';
import { createMixedTabBarNavigator } from './utils/createMixedTabBarNavigator.utils';

const screens = [
  {
    name: 'PassesResults',
    component: PassesResultsScreen,
    options: {
      tabBarIcon: props => <TabBarIcon {...props} name="flag" />,
      tabBarLabel: props => <TabBarLabel {...props} title="Résultats" />,
      title: 'Mes Pass',
    } as BottomTabNavigationOptions,
  },
  {
    name: 'PassesCertificates',
    component: PassesCertificatesScreen,
    options: {
      tabBarIcon: props => <TabBarIcon {...props} name="ribbon" />,
      tabBarLabel: props => <TabBarLabel {...props} title="Attestations" />,
      title: 'Mes Pass - Attestations',
    } as BottomTabNavigationOptions,
  },
];

export const PassesTab = createMixedTabBarNavigator({ screens });
