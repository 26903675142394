import { USER_SIGN_OUT } from '../user/user.actions';
import {
  MESSAGING_FETCH_REMOTE_MESSAGES,
  MESSAGING_READ_REMOTE_MESSAGE,
  MESSAGING_STORE_REMOTE_MESSAGE,
} from './messaging.actions';

const initialState = {
  messages: [],
};

export const messaging = (state = initialState, action) => {
  switch (action.type) {
    case MESSAGING_FETCH_REMOTE_MESSAGES:
      return { ...state, messages: action.messages };

    case MESSAGING_STORE_REMOTE_MESSAGE: {
      const { message } = action;

      // Let's not add duplicate notification into the state
      if (
        state.messages.find(
          ({ data }) => message.data && data && data.originalNotificationId === message.data.originalNotificationId,
        )
      ) {
        return state;
      }

      return {
        ...state,
        messages: [message, ...state.messages],
      };
    }

    case MESSAGING_READ_REMOTE_MESSAGE: {
      const { message: remoteMessage } = action;

      return {
        ...state,
        messages: state.messages.map(message => {
          if (
            message.id === remoteMessage.id ||
            (message.data &&
              remoteMessage.data &&
              message.data.originalNotificationId === remoteMessage.data.originalNotificationId)
          ) {
            return { ...message, read: true };
          }

          return message;
        }),
      };
    }

    case USER_SIGN_OUT:
      return initialState;

    default:
      return state;
  }
};
