import React from 'react';
import PropTypes from 'prop-types';
import { View, StyleSheet } from 'react-native';

import { Text } from '../modules/ui/components/text.component';

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: 'white',
  },
  text: {
    color: 'blue',
  },
});

export const DebugText = ({ children }) => (
  <View style={styles.wrapper}>
    <Text style={styles.text}>==== DEBUG MESSAGE ====</Text>
    <Text style={styles.text}>{children}</Text>
  </View>
);

DebugText.propTypes = {
  children: PropTypes.any,
};
