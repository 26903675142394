import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider as StyledThemeProvider } from 'styled-components/native';
import { Dimensions, ScaledSize, useColorScheme } from 'react-native';

import { colors } from '../constants/colors';
import { getBreakpoint } from '../utils/theme.utils';

type ThemeProviderProps = {
  children: any;
  dark?: boolean;
};

export const ThemeProvider = ({ children, dark = false }: ThemeProviderProps) => {
  const colorScheme = useColorScheme();
  const prevDimensions = useRef<ScaledSize>();
  const [breakpoint, setBreakpoint] = useState(getBreakpoint(Dimensions.get('window').width));

  useEffect(() => {
    const listener = ({ window }: { window: ScaledSize }) => {
      if (prevDimensions.current?.width !== window.width) {
        setBreakpoint(getBreakpoint(window.width));
      }

      prevDimensions.current = window;
    };

    Dimensions.addEventListener('change', listener);

    return () => {
      Dimensions.removeEventListener('change', listener);
    };
  }, []);

  const theme = useMemo(() => {
    // const darkColorScheme = colorScheme === 'dark';

    // if (darkColorScheme) {
    //   return {
    //     breakpoint,
    //     dark,
    //     darkScheme: true,
    //     colors: {
    //       ...colors,
    //       // Darkened primary by 50%
    //       primary: 'rgb(48, 83, 53)',
    //       secondary: 'black',
    //       tertiary: 'white',
    //       text: 'white',
    //       icon: 'rgba(255, 255, 255, 0.5)',
    //       iconFocused: 'white',
    //       button: {
    //         background: colors.secondary,
    //         foreground: colors.tertiary,
    //       },
    //       checkbox: {
    //         inner: colors.secondary,
    //         outer: 'rgba(255, 255, 255, 0.75)',
    //       },
    //       input: {
    //         foreground: colors.secondary,
    //       },
    //       question: {
    //         background: colors.tertiary,
    //       },
    //     },
    //   };
    // }

    if (dark) {
      return {
        breakpoint,
        dark,
        colors: {
          ...colors,
          text: colors.secondary,
          button: {
            background: colors.secondary,
            foreground: colors.tertiary,
          },
          checkbox: {
            inner: colors.secondary,
            outer: 'rgba(255, 255, 255, 0.75)',
          },
          input: {
            foreground: colors.secondary,
          },
        },
      };
    }

    return {
      breakpoint,
      dark,
      colors: {
        ...colors,
        text: colors.tertiary,
        button: {
          background: colors.primary,
          foreground: colors.secondary,
        },
        checkbox: {
          inner: 'black',
          outer: 'rgba(0, 0, 0, 0.75)',
        },
        input: {
          foreground: colors.tertiary,
        },
        question: {
          background: '#F7F7F7',
        },
      },
    };
  }, [dark, colorScheme, breakpoint]);

  return <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>;
};

ThemeProvider.propTypes = {
  children: PropTypes.any,
  dark: PropTypes.bool,
};
