import React from 'react';
import styled, { css } from 'styled-components/native';
import { Platform, View, TouchableOpacity } from 'react-native';

import { condition, getThemeColor } from '../utils/theme.utils';
import { Typography } from './typography.component';

const PADDING_VERTICAL = 8;
const PADDING_HORIZONTAL = 12;

const ValuesTextWrapper = styled(View)<{ small?: boolean; full?: boolean }>`
  padding-vertical: ${PADDING_VERTICAL - 1}px;
  padding-horizontal: ${PADDING_HORIZONTAL - 1}px;
  border-radius: 16px;
  border: 2px solid ${getThemeColor('primary')};
  align-items: center;
  align-content: center;
  justify-content: center;
  margin-bottom: 5px;
  margin-left: 5px;

  ${Platform.select({
    web: css`
      box-sizing: border-box;
    `,
    default: null,
  })}

  min-width: 150px;

  ${condition(
    'small',
    css`
      width: 40px;
      min-width: 40px;
    `,
  )}

  ${condition(
    'full',
    css`
      padding-vertical: ${PADDING_VERTICAL}px;
      padding-horizontal: ${PADDING_HORIZONTAL}px;
      background-color: ${getThemeColor('primary')};
      border: 1px solid rgba(255, 255, 255, 0.5);
    `,
  )}
`;

type ChipProps = {
  label: string;
  small?: boolean;
  full?: boolean;
  onPress?: () => void;
};

export const Chip = ({ label, small, full, onPress }: ChipProps) => {
  return (
    <ValuesTextWrapper
      as={onPress ? TouchableOpacity : View}
      small={small}
      full={full}
      accessibilityRole={onPress ? 'button' : undefined}
      accessibilityLabel={label}
      {...Platform.select({
        web: { onClick: onPress, onPress },
        default: { onPress },
      })}
    >
      <Typography numberOfLines={1} color={full ? 'white' : undefined}>
        {label}
      </Typography>
    </ValuesTextWrapper>
  );
};
