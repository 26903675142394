import React from 'react';

import { Privacy } from '../components/privacy.component';
import { ModalScreen } from '../../navigation/components/modalScreen.component';
import { ScrollView } from '../../navigation/components/scrollView.component';
import { Separator } from '../../ui/components/separator.component';

export const BeforeAuthPrivacyScreen = () => (
  <ModalScreen title="Données personnelles">
    <ScrollView raw>
      <Separator size={40} />
      <Privacy withTitle={false} />
    </ScrollView>
  </ModalScreen>
);
