import {
  setAnalyticsCollectionEnabled,
  resetAnalyticsData,
  setUserId,
  setUserProperties,
  logEvent,
} from '../data/firebase/analytics';

import { User } from '../user/user.types';

const wrapError = (promise?: Promise<any>) => {
  if (!promise) {
    return;
  }

  promise.catch(error => {
    if (__DEV__) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  });
};

export const analytics = new (class {
  initiated = false;

  init() {
    if (!this.initiated) {
      this.initiated = true;
      setAnalyticsCollectionEnabled(true);
    }
  }

  release() {
    this.initiated = false;

    resetAnalyticsData();
    setAnalyticsCollectionEnabled(false);
  }

  screenHit(screenName: string) {
    if (!this.initiated || !screenName) {
      return;
    }

    if (__DEV__) {
      // eslint-disable-next-line no-console
      console.log('[analytics] logScreenView', screenName);
    }

    // TBF WEB
    this.event('screen_view', { screen_class: screenName, screen_name: screenName });
  }

  setUser(user: User) {
    wrapError(setUserId((user && user.id) || null));
  }

  setUserProperties(properties: any) {
    wrapError(setUserProperties(properties));
  }

  event(eventName: string, customDimensions = {}) {
    if (!this.initiated) {
      return;
    }

    if (__DEV__) {
      // eslint-disable-next-line no-console
      console.log('[analytics] event', eventName, customDimensions);
    }

    wrapError(logEvent(eventName, { ...customDimensions, userId: undefined }));
  }
})();
