import {
  getAnalytics,
  logEvent as logEventFirebase,
  setAnalyticsCollectionEnabled as setAnalyticsCollectionEnabledFirebase,
  setUserId as setUserIdFirebase,
  setUserProperties as setUserPropertiesFirebase,
} from 'firebase/analytics';

import { firebaseApp } from './app.web';

const analytics = getAnalytics(firebaseApp);

export const logEvent = (eventName: string, eventParams?: { [key: string]: any }) =>
  logEventFirebase(analytics, eventName, eventParams);

export const setAnalyticsCollectionEnabled = (enabled: boolean) =>
  setAnalyticsCollectionEnabledFirebase(analytics, enabled);

export const setUserId = (id: string) => setUserIdFirebase(analytics, id);

export const setUserProperties = (properties: { [key: string]: unknown }) =>
  setUserPropertiesFirebase(analytics, properties);

export const resetAnalyticsData = () => {};
