import React, { useLayoutEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components/native';
import { View } from 'react-native';

import * as Sentry from '../../../utils/sentry';
import { Modal } from '../../ui/components/modal.component';
import {
  getUsersJobs,
  getUsersActivityDomains,
  getUser,
  isConnected as isConnectedSelector,
  getDepartments,
} from '../user.selectors';
import { userEdit } from '../user.actions';
import { Picker } from '../../ui/components/picker.component';
import { getRemoteText } from '../../data/firebase';
import { MultiPicker } from '../../ui/components/multiPicker.component';
import { TYPOGRAPHIES, Typography } from '../../ui/components/typography.component';

const ItemWrapper = styled(View)`
  margin-top: 20px;
`;

export const CatchingUpModal = () => {
  const dispatch = useDispatch();
  const usersJobs = useSelector(getUsersJobs);
  const isConnected = useSelector(isConnectedSelector);
  const user = useSelector(getUser);
  const usersActivityDomains = useSelector(getUsersActivityDomains);

  const departments = useSelector(getDepartments);

  const [loading, setLoading] = useState(false);
  const [department, setDepartment] = useState(user.department || (departments.length && departments[0].code));
  const [userJobs, setUserJobs] = useState(user.jobs || []);
  const [userActivityDomains, setUserActivityDomains] = useState(user.activityDomains || []);

  // Horrible but don't have anything else without changing the whole modal, something I don't want...
  useLayoutEffect(
    () => {
      setUserJobs(user.jobs);
    },
    [user.jobs],
  );

  useLayoutEffect(
    () => {
      setUserActivityDomains(user.activityDomains);
    },
    [user.activityDomains],
  );

  useLayoutEffect(
    () => {
      setDepartment(user.department);
    },
    [user.department],
  );

  if (
    !isConnected ||
    (user.jobs && user.jobs.length && user.activityDomains && user.activityDomains.length && user.department)
  ) {
    return null;
  }

  const onSubmit = async () => {
    setLoading(true);

    try {
      await dispatch(userEdit({ jobs: userJobs, activityDomains: userActivityDomains, department }));
    } catch (error) {
      Sentry.captureException(error);
    }

    setLoading(false);
  };

  const mapFieldValues = ({ order, id: key, name: label }) => ({ order, key, label });

  return (
    <Modal
      titleLabel="Compléter votre profil"
      titleIcon="person"
      actions={[
        { title: 'Sauvegarder', disabled: loading, iconName: loading ? 'loading' : undefined, onPress: onSubmit },
      ]}
    >
      <Typography size={TYPOGRAPHIES.LARGE}>
        Merci de compléter votre profil avec les informations suivantes :
      </Typography>
      <ItemWrapper>
        <MultiPicker
          value={userJobs}
          onValueChange={setUserJobs}
          values={usersJobs.map(mapFieldValues)}
          placeholder={getRemoteText('user_job')}
        />
      </ItemWrapper>
      <ItemWrapper>
        <MultiPicker
          value={userActivityDomains}
          onValueChange={setUserActivityDomains}
          values={usersActivityDomains.map(mapFieldValues)}
          placeholder={getRemoteText('user_activity_domain')}
        />
      </ItemWrapper>
      <ItemWrapper>
        <Picker
          value={department}
          onValueChange={setDepartment}
          mode="dropdown"
          values={departments}
          placeholder={getRemoteText('user_department')}
          height={200}
          style={{ marginTop: 20 }}
        />
      </ItemWrapper>
    </Modal>
  );
};
