import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { View, ScrollView, Platform, StyleSheet } from 'react-native';
import { getVersion, getBuildNumber } from 'react-native-device-info';
import { css } from 'styled-components/native';

import { openExternalLink } from '../navigation.utils';
import { getRemoteConfigKey } from '../../data/firebase';
import { ModalScreen } from '../components/modalScreen.component';
import { TYPOGRAPHIES, Typography } from '../../ui/components/typography.component';
import { AppTitle } from '../../ui/components/appTitle.component';
import { Separator } from '../../ui/components/separator.component';
import { Link } from '../components/link.component';
import { mq } from '../../ui/utils/theme.utils';

const Wrapper = styled(View)`
  align-content: center;
  align-items: center;
  margin-top: 30px;

  ${mq.md(css`
    margin-top: 50px;
  `)}
`;

const ExplanationWrapper = styled(View)`
  max-width: 500px;
`;

const styles = StyleSheet.create({
  contentContainerStyle: { paddingBottom: 20 },
});

export const PresentationScreen = ({ navigation }) => {
  const openModal = link => () => openExternalLink(navigation, link);

  return (
    <ModalScreen title="À propos">
      <ScrollView contentContainerStyle={styles.contentContainerStyle}>
        <AppTitle withPartners>
          <ExplanationWrapper>
            <Typography size={TYPOGRAPHIES.LARGE} remoteKey="app_explanation_level1" />
            <Separator size={20} />
            <Typography size={TYPOGRAPHIES.MEDIUM} remoteKey="app_explanation_level2" />
          </ExplanationWrapper>
          <Wrapper>
            <Link
              {...Platform.select({
                web: {
                  to: getRemoteConfigKey('links_terms_of_use'),
                  hrefAttrs: { target: '_blank' },
                },
                default: {
                  onPress: openModal(getRemoteConfigKey('links_terms_of_use')),
                },
              })}
            >
              <Typography size={TYPOGRAPHIES.MEDIUM} remoteKey="terms_of_use" />
            </Link>
            <Separator size={10} />
            <Link
              {...Platform.select({
                web: {
                  to: getRemoteConfigKey('links_privacy_policy'),
                  hrefAttrs: { target: '_blank' },
                },
                default: {
                  onPress: openModal(getRemoteConfigKey('links_privacy_policy')),
                },
              })}
            >
              <Typography size={TYPOGRAPHIES.MEDIUM} remoteKey="privacy_policy" />
            </Link>
          </Wrapper>
          <Wrapper>
            <Typography size={TYPOGRAPHIES.SMALL}>Développée par Vincent Valot</Typography>
            <Typography size={TYPOGRAPHIES.SMALL}>valot.vince@gmail.com</Typography>
          </Wrapper>
          <Wrapper>
            <Typography size={TYPOGRAPHIES.SMALL}>
              Version v{getVersion()} ({getBuildNumber()})
            </Typography>
          </Wrapper>
        </AppTitle>
      </ScrollView>
    </ModalScreen>
  );
};

PresentationScreen.propTypes = {
  navigation: PropTypes.shape({
    navigate: PropTypes.func.isRequired,
  }).isRequired,
};
