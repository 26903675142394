import * as Sentry from '@sentry/react';
import { getVersion } from 'react-native-device-info';

export const init = () =>
  Sentry.init({
    dsn: 'https://e676ea28f8fc42298ffee08b027d0959@o141946.ingest.sentry.io/5898251',
    // integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,

    debug: __DEV__,
    enabled: true,
    release: getVersion(),
  });

export const captureException = Sentry.captureException;
