import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View, TouchableOpacity } from 'react-native';
import styled, { css } from 'styled-components/native';

import { colors } from '../constants/colors';
import { Loader } from './loader.component';
import { getThemeColor, condition, useThemeColor } from '../utils/theme.utils';
import { useFontScale } from './fontScale.hook';
import { Icon } from './icon.component';
import { Link } from '../../navigation/components/link.component';
import { TYPOGRAPHIES, Typography } from './typography.component';

export const ButtonTypes = {
  PRIMARY: 'PRIMARY',
  NO_OUTLINE: 'NO_OUTLINE',
};

export const ButtonSizes = {
  NORMAL: 'NORMAL',
  SMALL: 'SMALL',
};

const styles = StyleSheet.create({
  fullWidthWrapper: {
    marginVertical: 10,
    alignContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  wrapper: {
    width: '100%',
    maxWidth: 500,
  },
  childrenWrapper: {
    marginTop: 5,
  },
  icon: {
    marginRight: 10,
  },
  mask: {
    borderRadius: 5,
    position: 'absolute',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    backgroundColor: colors.tertiary,
    opacity: 0.4,
  },
});

export const ButtonWrapper = styled(Link)`
  align-items: center;
  flex-direction: row;
  justify-content: center;

  ${condition(
    { type: ButtonTypes.PRIMARY },
    css`
      padding-horizontal: 20px;
      padding-vertical: 10px;
      border-radius: 5px;
      background-color: ${getThemeColor('button.background')};
    `,
  )};
`;

export const ButtonText = styled(Typography)`
  color: ${getThemeColor('button.foreground')};
  font-weight: 500;
  text-align: center;

  ${condition(
    { type: ButtonTypes.NO_OUTLINE },
    css`
      color: ${getThemeColor('button.background')};
    `,
  )} ${condition(
    { type: ButtonTypes.NO_OUTLINE, disabled: true },
    css`
      opacity: 0.75;
    `,
  )};
`;

export const Button = ({
  iconName,
  iconSize: propIconSize,
  disabled = false,
  title,
  onPress,
  type = ButtonTypes.PRIMARY,
  size = ButtonSizes.NORMAL,
  wrapperStyle,
  textStyle,
  testID,
  to,
  hrefAttrs,
  style,
  children,
}) => {
  const fontScale = useFontScale();

  const iconSize = propIconSize || 30 * fontScale;

  let icon;

  const buttonColors = useThemeColor('button');
  const color = type === ButtonTypes.NO_OUTLINE ? buttonColors.background : buttonColors.foreground;

  if (iconName) {
    if (iconName === 'loading') {
      icon = <Loader style={styles.icon} color={color} />;
    } else {
      icon = <Icon size={iconSize} name={iconName} color={color} marginRight="10" />;
    }
  }

  return (
    <View style={[styles.fullWidthWrapper, style]}>
      <View style={styles.wrapper}>
        <ButtonWrapper
          as={to ? Link : TouchableOpacity}
          accessibilityLabel={title}
          type={type}
          activeOpacity={disabled ? 1 : undefined}
          onPress={disabled ? undefined : onPress}
          style={wrapperStyle}
          testID={testID}
          to={to}
          hrefAttrs={hrefAttrs}
        >
          {icon}
          {title ? (
            <ButtonText
              type={type}
              size={size === ButtonSizes.SMALL ? TYPOGRAPHIES.SMALL : TYPOGRAPHIES.MEDIUM}
              style={textStyle}
              disabled={disabled}
            >
              {title}
            </ButtonText>
          ) : null}
        </ButtonWrapper>
        {type === ButtonTypes.PRIMARY && disabled ? <View style={styles.mask} /> : null}
      </View>
      {children ? <View style={[styles.wrapper, styles.childrenWrapper]}>{children}</View> : null}
    </View>
  );
};

export const ButtonPropTypes = {
  children: PropTypes.any,
  disabled: PropTypes.bool,
  iconName: PropTypes.string,
  type: PropTypes.oneOf(Object.keys(ButtonTypes)),
  size: PropTypes.oneOf(Object.keys(ButtonSizes)),
  title: PropTypes.string,
  onPress: PropTypes.func,
  wrapperStyle: PropTypes.object,
  textStyle: PropTypes.object,
  iconSize: PropTypes.number,
  testID: PropTypes.string,
  to: PropTypes.string,
  hrefAttrs: PropTypes.object,
  style: PropTypes.any,
};

Button.propTypes = ButtonPropTypes;
