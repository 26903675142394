import React from 'react';

import { ScrollView } from '../../navigation/components/scrollView.component';
import { BREAKPOINTS } from '../../ui/constants/breakpoints';
import { useBreakpoint } from '../../ui/utils/theme.utils';
import { Privacy } from '../components/privacy.component';

export const PrivacyScreen = () => {
  const shouldDisplayTitle = useBreakpoint({ [BREAKPOINTS.MEDIUM]: false }, true);

  return (
    <ScrollView withFooter>
      <Privacy withTitle={shouldDisplayTitle} />
    </ScrollView>
  );
};
