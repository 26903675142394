import React, { useRef, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Image, View, StyleSheet, Platform, Animated } from 'react-native';
import styled, { css } from 'styled-components/native';

import { colors } from '../constants/colors';

import { TYPOGRAPHIES, Typography } from './typography.component';
import { mq } from '../utils/theme.utils';
import { images } from '../const/images';
import { Link } from '../../navigation/components/link.component';
import { getImageSource } from '../../data/firebase/image';

export const CARD_HEIGHT = 200;
export const CARD_MAX_WIDTH = 500;

const styles = StyleSheet.create({
  image: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    opacity: 0.5,
    borderRadius: 10,
  },
  content: {
    padding: 20,
    justifyContent: 'space-between',
    flexDirection: 'column',
    flex: 1,
  },
});

const Title = styled(Typography).attrs({
  size: TYPOGRAPHIES.XLARGE,
  color: 'white',
})`
  margin-bottom: 10px;

  ${mq.md(css`
    margin-bottom: 20px;
  `)}
`;

const Wrapper = styled(Link)`
  border-radius: 10px;
  background-color: black;

  height: ${CARD_HEIGHT}px;

  width: 100%;
  max-width: 1000px;

  ${Platform.OS === 'ios'
    ? css`
        shadow-color: black;
        shadow-offset: 3px 3px;
        shadow-opacity: 0.1;
        shadow-radius: 3px;
      `
    : null}
`;

export const Card = ({
  image,
  title,
  description,
  extraDescription,
  children,
  defaultImage,
  styles: overrideStyles,
  ghost,
  linkProps,
  testID,
}) => {
  const mounted = useRef(true);
  const [loading, setLoading] = useState(ghost || !!image);
  const opacity = useRef(new Animated.Value(loading ? 0.8 : 1)).current;
  const [imageSource, setImageSource] = useState();

  const onImageError = useCallback(() => setImageSource(null), []);
  const onImageLoadEnd = useCallback(() => setLoading(false), []);

  useEffect(() => {
    if (loading) {
      const animation = Animated.loop(
        Animated.sequence([
          Animated.timing(opacity, {
            toValue: 0.6,
            duration: 1000,
            useNativeDriver: Platform.select({ web: false, native: true }),
          }),
          Animated.timing(opacity, {
            toValue: 0.8,
            duration: 1000,
            useNativeDriver: Platform.select({ web: false, native: true }),
          }),
        ]),
      );

      animation.start();

      return () => {
        animation.stop();
        opacity.stopAnimation();
        opacity.setValue(1);
      };
    }

    return undefined;
  }, [loading]);

  useEffect(
    () => () => {
      mounted.current = false;
    },
    [],
  );

  useEffect(() => {
    if (image) {
      getImageSource(image)
        .then(source => {
          if (!mounted.current) {
            return;
          }

          setImageSource(source);
        })
        // Do nothing, fallbacks on the fallback image
        .catch(() => {
          if (!mounted.current) {
            return;
          }

          setLoading(false);
        });
    }
  }, [image]);

  return (
    <Wrapper
      {...linkProps}
      delayPressIn={50}
      style={[overrideStyles.wrapper, { backgroundColor: loading ? colors.tertiary : 'black' }]}
      testID={testID}
    >
      <Animated.View style={{ opacity: loading ? opacity : undefined, flex: 1 }}>
        {ghost || (image && !imageSource && loading) ? null : (
          <Image
            source={imageSource ? imageSource : images[defaultImage]}
            resizeMode="cover"
            style={styles.image}
            onLoadEnd={onImageLoadEnd}
            onError={onImageError}
          />
        )}
        <View style={[styles.content, overrideStyles.content]}>
          <View>
            <Title>{title.toUpperCase()}</Title>
            {description ? (
              <Typography size={TYPOGRAPHIES.MEDIUM} color="white" numberOfLines={3}>
                {description}
              </Typography>
            ) : null}
          </View>
          {extraDescription ? (
            <Typography size={TYPOGRAPHIES.MEDIUM} color="white" numberOfLines={1} textAlign="center" fontWeight="500">
              {extraDescription}
            </Typography>
          ) : null}
          {children}
        </View>
      </Animated.View>
    </Wrapper>
  );
};

Card.defaultProps = {
  defaultImage: 'horses',
  title: '',
  styles: {},
  ghost: false,
};

Card.propTypes = {
  title: PropTypes.string,
  linkProps: PropTypes.object,

  children: PropTypes.node,
  image: PropTypes.string,
  styles: PropTypes.object,
  description: PropTypes.string,
  extraDescription: PropTypes.string,
  defaultImage: PropTypes.string,
  testID: PropTypes.string,
  ghost: PropTypes.bool,
};
