import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { EquiReglementation } from '@valotvince/equireglementation-utils';

import { SafeViewFlatList } from '../../navigation/components/scrollView.component';
import { getSurveys } from '../survey.selectors';
import { FairUseButton } from '../../ui/components/fairUseButton.component';
import { requestSurveyPDF } from '../survey.utils';
import { Title } from '../../ui/components/title.component';
import { useBreakpoint } from '../../ui/utils/theme.utils';
import { BREAKPOINTS } from '../../ui/constants/breakpoints';

const ListHeaderComponent = () => <Title remoteKey="passes_results_title" centered />;

export const PassesResultsScreen = () => {
  const surveys = useSelector(getSurveys);

  const data = useMemo<EquiReglementation.Survey[]>(
    () => Object.keys(surveys).map(surveyId => surveys[surveyId]),
    [surveys],
  );
  const renderItem = useCallback(
    ({ item: { id: surveyId, name } }) => (
      <FairUseButton
        id={surveyId}
        iconName="download"
        onPress={() => requestSurveyPDF({ surveyId, pdfType: 'questions' })}
        title={name}
        textStyle={{ textAlign: 'center', flex: 1 }}
        wrapperStyle={{ alignContent: 'flex-start', justifyContent: 'flex-start' }}
      />
    ),
    [],
  );

  const ListHeader = useBreakpoint({ [BREAKPOINTS.LARGE]: null }, ListHeaderComponent);

  return <SafeViewFlatList data={data} renderItem={renderItem} ListHeaderComponent={ListHeader} withFooter />;
};
