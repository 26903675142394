"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EquiReglementation = void 0;
var EquiReglementation;
(function (EquiReglementation) {
    var NOTIFICATION_TARGET_TYPES;
    (function (NOTIFICATION_TARGET_TYPES) {
        NOTIFICATION_TARGET_TYPES["NONE"] = "none";
        NOTIFICATION_TARGET_TYPES["SURVEY"] = "survey";
        NOTIFICATION_TARGET_TYPES["COACHING"] = "coaching";
        NOTIFICATION_TARGET_TYPES["LINK"] = "link";
        NOTIFICATION_TARGET_TYPES["PASSES"] = "passes";
    })(NOTIFICATION_TARGET_TYPES = EquiReglementation.NOTIFICATION_TARGET_TYPES || (EquiReglementation.NOTIFICATION_TARGET_TYPES = {}));
    var SURVEY_TYPES;
    (function (SURVEY_TYPES) {
        SURVEY_TYPES["CERTIFICATION"] = "certification";
        SURVEY_TYPES["REVIEW"] = "review";
    })(SURVEY_TYPES = EquiReglementation.SURVEY_TYPES || (EquiReglementation.SURVEY_TYPES = {}));
    var SURVEY_CONDITION_TYPES;
    (function (SURVEY_CONDITION_TYPES) {
        SURVEY_CONDITION_TYPES["PRE_ANSWERED"] = "preAnswered";
        SURVEY_CONDITION_TYPES["ANSWERED_LEGACY"] = "answered";
        SURVEY_CONDITION_TYPES["SHOW"] = "show";
    })(SURVEY_CONDITION_TYPES = EquiReglementation.SURVEY_CONDITION_TYPES || (EquiReglementation.SURVEY_CONDITION_TYPES = {}));
})(EquiReglementation = exports.EquiReglementation || (exports.EquiReglementation = {}));
