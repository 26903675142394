import { firebaseApp } from './app.web';

import {
  getMessaging,
  getToken as getTokenFirebase,
  MessagePayload,
  NextFn,
  Observer,
  onMessage as onMessageFirebase,
} from 'firebase/messaging';

const messaging = getMessaging(firebaseApp);

export const getToken = () => getTokenFirebase(messaging);

export const onMessage = (message: NextFn<MessagePayload> | Observer<MessagePayload>) =>
  onMessageFirebase(messaging, message);

export const onNotificationOpenedApp = () => null;

export const getInitialNotification = () => Promise.resolve();
