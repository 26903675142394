import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { getSurvey, isSurveyPaymentEnabled } from '../survey.selectors';
import { getSubscriptionForSurvey, hasUserConnectedIntegrators } from '../../user/user.selectors';
import { Text } from '../../ui/components/text.component';
import { FairUseButton } from '../../ui/components/fairUseButton.component';
import { requestSurveyPDF } from '../survey.utils';
import { getRemoteText } from '../../data/firebase';

type CertificateButtonProps = {
  surveyId: string;
};

export const CertificateButton = memo(({ surveyId, ...props }: CertificateButtonProps) => {
  const { name, certification, texts = {} } = useSelector(getSurvey(surveyId));
  const surveyPaymentEnabled = useSelector(isSurveyPaymentEnabled(surveyId));
  const activeSubscription = useSelector(getSubscriptionForSurvey(surveyId));
  const userHasConnectedIntegrators = useSelector(hasUserConnectedIntegrators);

  const shouldHaveActiveSubscription = surveyPaymentEnabled && !activeSubscription && !userHasConnectedIntegrators;
  const canReceiveCertificate = !shouldHaveActiveSubscription && certification?.certified;

  const shouldHaveActiveSubscriptionMessage = texts.should_have_active_subscription_to_download_certification || `Vous n'adhérez pas au ${name}, vous ne pouvez donc pas télécharger d'attestation d'adhésion.`;
  const shouldHaveCertificationMessage = texts.should_have_certification_to_download_certification || `Vous ne remplissez pas tous les critères pour recevoir une attestation d'adhésion au ${name}.`;

  return (
    <>
      <FairUseButton
        id={surveyId}
        iconName="ribbon"
        onPress={() => requestSurveyPDF({ surveyId, pdfType: 'certificate' })}
        title={getRemoteText(userHasConnectedIntegrators ? 'certificate_button_integrators' : 'certificate_button')}
        disabled={!canReceiveCertificate}
        {...props}
      >
        {shouldHaveActiveSubscription && (
          <Text>{shouldHaveActiveSubscriptionMessage}</Text>
        )}
        {!certification?.certified && (
          <Text>{shouldHaveCertificationMessage}</Text>
        )}
      </FairUseButton>
    </>
  );
});
