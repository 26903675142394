import { getStorage, ref, getDownloadURL, connectStorageEmulator } from 'firebase/storage';
import { getRemoteConfig, fetchConfig, activate, getString, getBoolean } from 'firebase/remote-config';
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';
import {
  DocumentSnapshot,
  QuerySnapshot,
  DocumentData,
  QueryConstraint,
  DocumentReference,
  deleteDoc,
  collection,
  serverTimestamp,
  doc,
  query,
  where,
  orderBy,
  setDoc,
  addDoc,
  getDoc,
  getDocs,
  connectFirestoreEmulator,
  limit,
  onSnapshot as onFirebaseSnapshot,
  initializeFirestore,
} from 'firebase/firestore';

import * as Sentry from '../../utils/sentry';

import { defaultRemoteConfig } from './defaultRemoteConfig';
import { firebaseApp } from './firebase/app.web';

const firestore = initializeFirestore(firebaseApp, { experimentalForceLongPolling: !!__E2E__ });
const storage = getStorage(firebaseApp);
const functions = getFunctions(firebaseApp, 'europe-west1');
const remoteConfig = getRemoteConfig(firebaseApp);

if (__E2E__) {
  connectStorageEmulator(storage, 'localhost', 9199);
  connectFunctionsEmulator(functions, 'localhost', 10001);
  connectFirestoreEmulator(firestore, 'localhost', 10002);
}

export interface LocalDocument {
  [key: string]: any;
  id?: string;
  snapshot?: DocumentSnapshot;
}

export const fetchAndActivateRemoteConfig = async () => {
  remoteConfig.defaultConfig = defaultRemoteConfig;
  remoteConfig.settings.minimumFetchIntervalMillis = __DEV__ ? 1200000 : 21600000;
  remoteConfig.settings.fetchTimeoutMillis = 60000;

  await fetchConfig(remoteConfig);
  await activate(remoteConfig);
};

export const getRemoteConfigKey = (key: string) => getString(remoteConfig, key);

export const getRemoteFeatureFlag = (key: string) => getBoolean(remoteConfig, key);

export const getRemoteConfigKeyJSON = (key: string, defaultValue: any) => {
  try {
    return JSON.parse(getRemoteConfigKey(key));
  } catch (error) {
    Sentry.captureException(error);

    return defaultValue;
  }
};

export const isRemoteTextDefined = (key: string) => {
  const remoteKey = getRemoteConfigKey(`texts_${key}`);

  return remoteKey && remoteKey.length;
};

export const getRemoteText = (key: string) => getRemoteConfigKey(`texts_${key}`) || `texts_${key}`;

export const runCloudFunction = async (functionName: string, parameters: object) => {
  try {
    const { data } = await httpsCallable(functions, functionName)(parameters);

    return data;
  } catch (exception) {
    if (!__DEV__) {
      Sentry.captureException(exception);
    }

    throw exception;
  }
};

export const getServerTimestampField = serverTimestamp;

export const snapshotToCollection = (snapshots: QuerySnapshot<DocumentData>, withSnapshot = false) => {
  const items: LocalDocument[] = [];

  snapshots.forEach(snapshot =>
    items.push({
      snapshot: withSnapshot ? snapshot : undefined,
      ...snapshot.data(),
      id: snapshot.id,
    }),
  );

  return items;
};

export const getFileURL = (refName: string) => getDownloadURL(ref(storage, refName));

export const saveDocument = (collectionName: string, { id, ...documentFields }: LocalDocument) => {
  const data = {
    ...documentFields,
    updated: serverTimestamp(),
  };

  if (id) {
    return setDoc(doc(firestore, collectionName, id), data, { merge: true });
  }

  return addDoc(collection(firestore, collectionName), {
    ...data,
    created: serverTimestamp(),
  });
};

export const updateDocument = (collectionName: string, id: string, documentFields: object) => {
  const data = { ...documentFields, updated: serverTimestamp() };

  return setDoc(doc(firestore, collectionName, id), data, { merge: true });
};

export const deleteDocument = (collectionName: string, id: string) => deleteDoc(doc(firestore, collectionName, id));

export const getDocument = async (collectionName: string, id: string) => {
  const document = await getDoc(doc(firestore, collectionName, id));

  return document.exists() ? { ...document.data(), id: document.id } : null;
};

const filterOperations = {
  where,
  orderBy,
  limit,
};

const buildQuery = (collectionName: string, filters: Record<string, string[]> = {}) =>
  query(
    collection(firestore, collectionName),
    ...Object.keys(filters).reduce<QueryConstraint[]>(
      (accumulator, filterName) => [
        ...accumulator,
        ...filters[filterName].map(filter => filterOperations[filterName](...filter)),
      ],
      [],
    ),
  );

export const getCollection = async (collectionName: string, filters: Record<string, string[]> = {}) => {
  const querySnapshot = await getDocs(buildQuery(collectionName, filters));

  return snapshotToCollection(querySnapshot);
};

export const onSnapshot = (ref: DocumentReference, callback: (snapshot: DocumentSnapshot<LocalDocument>) => void) =>
  onFirebaseSnapshot(ref, callback);

export const onCollectionSnapshot = (
  collectionName: string,
  callback: (documents: LocalDocument[]) => void,
  filters: Record<string, string[]> = {},
) => onFirebaseSnapshot(buildQuery(collectionName, filters), snapshot => callback(snapshotToCollection(snapshot)));
