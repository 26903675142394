import { getCollection } from '../data/firebase';

export const LABELS_FETCH_DATA = 'labels/FETCH_DATA';
export const labelsFetchData = () => async dispatch => {
  const labels = await getCollection('labels', { orderBy: [['order', 'asc']] });

  dispatch({
    type: LABELS_FETCH_DATA,
    labels,
  });

  return labels;
};
