import { useState, useEffect } from 'react';
import { Dimensions } from 'react-native';

export const useFontScale = (maximum = 2) => {
  const [fontScale, setFontScale] = useState(Dimensions.get('window').fontScale);

  useEffect(() => {
    const onChange = ({ window }) => {
      setFontScale(window.fontScale);
    };

    Dimensions.addEventListener('change', onChange);

    return () => Dimensions.removeEventListener('change', onChange);
  }, []);

  // Don't allow fontScale above 2
  return Math.min(maximum, fontScale);
};
