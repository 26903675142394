import { Image } from 'react-native';

import { getFileURL } from './../firebase';

type ImageSource = {
  uri: string;
  width: number;
  height: number;
  cache: string;
};

const references: Record<string, ImageSource> = {};

export const getImageSource = async (reference: string) => {
  if (references[reference]) {
    return references[reference];
  }

  const uri = await getFileURL(reference);

  return new Promise((resolve, reject) => {
    Image.getSize(
      uri,
      (width, height) => {
        const source = { uri, width, height, cache: 'force-cache' };

        references[reference] = source;

        resolve(source);
      },
      reject,
    );
  });
};
