import { LinkingOptions } from '@react-navigation/native';
import { Linking, Platform } from 'react-native';

const idGetter = (id: string) => {
  const splitted = id.split('_');

  return splitted.length === 1 ? id : splitted[1];
};

const stringify = {
  surveyId: idGetter,
  id: idGetter,
};

export enum ROUTE_NAMES {
  SURVEY,
  SURVEY_CATEGORY,
  COACHING,
  LABELS,
  USER_PROFILE,
  USER_PASSES,

  REGISTER,
  PRIVACY,
  FORGOT_PASSWORD,
}

export const routes = {
  [ROUTE_NAMES.SURVEY]: '/questionnaires/:surveyId/categories',
  [ROUTE_NAMES.SURVEY_CATEGORY]: '/questionnaires/:surveyId/categories/:categoryId',
  [ROUTE_NAMES.COACHING]: '/coaching',
  [ROUTE_NAMES.LABELS]: '/labels',
  [ROUTE_NAMES.USER_PROFILE]: '/profil',
  [ROUTE_NAMES.USER_PASSES]: '/mes-pass',

  [ROUTE_NAMES.REGISTER]: '/inscription',
  [ROUTE_NAMES.PRIVACY]: '/donnees-personnelles',
  [ROUTE_NAMES.FORGOT_PASSWORD]: '/mot-de-passe-oublie',
};

export const linking = {
  async getInitialURL() {
    const url = await Linking.getInitialURL();

    return url;
  },
  prefixes: ['/'],
  config: {
    initialRouteName: 'Main',
    screens: {
      Main: {
        initialRouteName: 'Survey',
        screens: {
          Survey: {
            path: 'questionnaires/:surveyId',
            initialRouteName: 'SurveyTab',
            screens: {
              SurveyCategory: {
                path: 'categories/:categoryId',
                stringify,
              },
              SurveyTab: {
                initialRouteName: 'SurveyTabMain',
                screens: {
                  SurveyTabMain: {
                    path: 'categories',
                    stringify,
                  },
                  SurveyTabActions: {
                    path: 'resultats',
                    stringify,
                  },
                  SurveyTabPayment: {
                    path: 'adhesion',
                    stringify,
                  },
                },
              },
            },
          },
          Coaching: {
            initialRouteName: 'CoachingTabDocuments',
            screens: {
              CoachingTabDocuments: 'coaching',
              CoachingTabTrainings: 'coaching/formations',
            },
          },
          Labels: 'labels',
          Profile: {
            initialRouteName: 'ProfileTabUser',
            screens: {
              ProfileTabUser: 'profil',
              ProfileTabIAP: 'profil/adhesions',
              ProfileTabAnalytics: 'profil/donnees-personnelles',
              ProfileTabActions: 'profil/actions',
            },
          },
          Passes: {
            initialRouteName: 'PassesCertificates',
            screens: {
              PassesCertificates: 'mes-passes/attestations',
              PassesResults: 'mes-pass',
            },
          },
        },
      },
      Register: 'inscription',
      Auth: '',
      ForgotPassword: 'mot-de-passe-oublie',
      Privacy: 'donnees-personnelles',
      MessagingModal: 'notifications',
      AboutModal: 'a-propos',
      SubscriptionModal: 'profil/adhesions/:subscriptionId',
      ...(Platform.OS === 'web' ? { NotFound: '*' } : {}),
    },
  },
} as LinkingOptions;
