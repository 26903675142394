import React, { useMemo } from 'react';
import { createBottomTabNavigator, BottomTabNavigationOptions } from '@react-navigation/bottom-tabs';
import { createMaterialTopTabNavigator, MaterialTopTabNavigationOptions } from '@react-navigation/material-top-tabs';
import { useTheme } from 'styled-components/native';

import { BREAKPOINTS } from '../../ui/constants/breakpoints';
import { useBreakpoint } from '../../ui/utils/theme.utils';

type MixedTabBarScreen = {
  name: string;
};

type CreateMixedTabBarNavigatorOptions = {
  screens: MixedTabBarScreen[];
};

export const useMixedTabBarNavigator = (): [
  ReturnType<typeof createBottomTabNavigator | typeof createMaterialTopTabNavigator>,
  Partial<MaterialTopTabNavigationOptions | BottomTabNavigationOptions>,
  boolean,
] => {
  const bottomTabBar = useBreakpoint({ [BREAKPOINTS.MEDIUM]: false }, true);
  const {
    colors: { primary, icon, iconFocused },
  } = useTheme();

  return useMemo(() => {
    return [
      bottomTabBar ? createBottomTabNavigator() : createMaterialTopTabNavigator(),
      {
        tabBarLabelPosition: 'below-icon',
        tabBarActiveTintColor: iconFocused,
        tabBarInactiveTintColor: icon,
        tabBarIndicatorStyle: { backgroundColor: iconFocused },
        tabBarShowIcon: true,
        headerShown: false,
        tabBarStyle: {
          padding: 2,
          backgroundColor: primary,
          borderTopWidth: 0,
        },
      },
      bottomTabBar,
    ];
  }, [bottomTabBar, icon, iconFocused, primary]);
};

export const createMixedTabBarNavigator = ({ screens }: CreateMixedTabBarNavigatorOptions) => {
  return () => {
    const [TabComponent, screenOptions] = useMixedTabBarNavigator();

    return (
      <TabComponent.Navigator screenOptions={screenOptions}>
        {screens.map(screen => (
          <TabComponent.Screen key={screen.name} {...screen} />
        ))}
      </TabComponent.Navigator>
    );
  };
};
