import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';

import { ThemeProvider } from '../../ui/components/theme.component';
import { AppTitle } from '../../ui/components/appTitle.component';
import { Text } from '../../ui/components/text.component';
import { Button } from '../../ui/components/button.component';
import { ScrollView } from '../../navigation/components/scrollView.component';
import { IS_TABLET } from '../../ui/utils/os.utils';

const styles = StyleSheet.create({
  subtitle: {
    fontSize: IS_TABLET ? 25 : 18,
    fontWeight: '500',
    marginTop: IS_TABLET ? 30 : 10,
  },
  description: {
    fontSize: IS_TABLET ? 22 : 16,
    marginTop: IS_TABLET ? 30 : 10,
  },
  item: {
    marginTop: IS_TABLET ? 10 : 5,
    fontSize: IS_TABLET ? 22 : 16,
    marginLeft: IS_TABLET ? 30 : 10,
  },
  button: {
    marginTop: IS_TABLET ? 30 : 10,
    flex: 1,
  },
});

export const FirstStart = ({ onComplete, children }) => {
  return (
    <ThemeProvider dark>
      <ScrollView>
        <AppTitle withPartners>
          <Text style={styles.subtitle} remoteKey="app_explanation_level1" />
          <Text style={styles.description} remoteKey="app_explanation_level2" />
          {onComplete ? (
            <View style={styles.button}>
              <Button onPress={onComplete} iconName="checkmark-circle" title="Continuer" testID="first-start-action" />
            </View>
          ) : null}
          {children}
        </AppTitle>
      </ScrollView>
    </ThemeProvider>
  );
};

FirstStart.propTypes = {
  onComplete: PropTypes.func,
  children: PropTypes.any,
};
