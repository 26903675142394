import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  StyleSheet,
  RefreshControl,
  FlatList,
  View,
  Platform,
  SafeAreaView as NativeSafeAreaView,
  ScrollView as NativeScrollView,
} from 'react-native';
import styled, { css, withTheme, useTheme } from 'styled-components/native';
import { KeyboardAwareScrollView as NativeKeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

import { getThemeColor, condition, useScreenPadding } from '../../ui/utils/theme.utils';
import { Footer, ListFooterProps } from './footer.component';

const styles = StyleSheet.create({
  contentContainer: {
    flexGrow: 1,
    padding: 15,
    alignItems: 'stretch',
  },
  safeAreaContent: { flex: 1, paddingHorizontal: 0 },
});

export const backgroundMixin = () => css`
  background-color: ${getThemeColor('secondary')};

  ${condition(
    'theme.dark',
    css`
      background-color: ${getThemeColor('primary')};
    `,
  )}
`;

export const SafeAreaView = styled(NativeSafeAreaView)`
  flex: 1;

  ${condition({ raw: false }, backgroundMixin())}
`;

const NativeStyledScrollView = styled(NativeScrollView)`
  flex: 1;

  ${condition({ raw: false }, backgroundMixin())}
`;

export const KeyboardAwareScrollView = withTheme(
  ({
    theme: {
      dark,
      colors: { primary, secondary },
    },
    children,
    style: propStyle,
    contentContainerStyle: propContentContainerStyle,
    raw = false,
    withFooter = false,
    ...props
  }) => {
    const contentContainerPaddings = useScreenPadding({ withFooter });
    const contentContainerStyle = useMemo(
      () => [
        styles.contentContainer,
        contentContainerPaddings,
        raw ? null : { backgroundColor: dark ? primary : secondary },
        propContentContainerStyle,
      ],
      [contentContainerPaddings, raw, dark, propContentContainerStyle],
    );
    const style = useMemo(
      () => [{ flexGrow: 1 }, raw ? null : { backgroundColor: dark ? primary : secondary }, style],
      [raw, dark, propStyle],
    );

    return (
      <SafeAreaView raw={raw}>
        <NativeKeyboardAwareScrollView
          enableOnAndroid={false}
          enableAutomaticScroll={Platform.OS === 'ios'}
          keyboardOpeningTime={0}
          style={style}
          contentContainerStyle={contentContainerStyle}
          {...props}
        >
          {children}
          {withFooter && <Footer />}
        </NativeKeyboardAwareScrollView>
      </SafeAreaView>
    );
  },
);

export const ScrollView = withTheme(
  ({
    theme: {
      dark,
      colors: { primary, secondary },
    },
    children,
    safeAreaStyle,
    containerStyle,
    contentContainerStyle: propContentContainerStyle,
    refreshing,
    onRefresh,
    innerRef,
    raw = false,
    withFooter = false,
  }) => {
    const contentContainerPaddings = useScreenPadding({ withFooter });

    const contentContainerStyle = useMemo(
      () => [
        styles.contentContainer,
        contentContainerPaddings,
        raw ? null : { backgroundColor: dark ? primary : secondary },
        propContentContainerStyle,
      ],
      [contentContainerPaddings, raw, dark, propContentContainerStyle],
    );
    const style = useMemo(() => [{ flexGrow: 1 }, containerStyle], [containerStyle]);

    return (
      <SafeAreaView style={safeAreaStyle} raw={raw}>
        <NativeStyledScrollView
          ref={innerRef}
          style={style}
          refreshControl={onRefresh ? <RefreshControl refreshing={refreshing} onRefresh={onRefresh} /> : null}
          contentContainerStyle={contentContainerStyle}
        >
          {children}
          {withFooter && <Footer />}
        </NativeStyledScrollView>
      </SafeAreaView>
    );
  },
);

export const SafeViewList = ({
  component: ListComponent = FlatList,
  safeAreaStyle,
  contentContainerStyle: propContentContainerStyle,
  innerRef,
  withFooter = false,
  raw = false,
  ...props
}) => {
  const contentContainerPaddings = useScreenPadding({ withFooter });
  const {
    dark,
    colors: { primary, secondary },
  } = useTheme();

  const contentContainerStyle = useMemo(
    () => [
      styles.contentContainer,
      contentContainerPaddings,
      raw ? null : { backgroundColor: dark ? primary : secondary },
      propContentContainerStyle,
    ],
    [contentContainerPaddings, raw, dark, propContentContainerStyle],
  );

  return (
    <SafeAreaView raw={raw}>
      <View style={[safeAreaStyle, styles.safeAreaContent]}>
        <ListComponent
          {...props}
          {...(withFooter ? ListFooterProps : {})}
          ref={innerRef}
          contentContainerStyle={contentContainerStyle}
        />
      </View>
    </SafeAreaView>
  );
};

export const SafeViewFlatList = SafeViewList;

SafeViewList.propTypes = {
  component: PropTypes.any,
  safeAreaStyle: PropTypes.object,
  contentContainerStyle: PropTypes.object,
  innerRef: PropTypes.object,
};
SafeViewFlatList.propTypes = SafeViewList.propTypes;
