type AlertAction = {
  text: string;
  style?: string;
  onPress?: () => void;
};

export const Alert = {
  alert: (title: string, description: string, actions: AlertAction[]) => {
    const okAction = actions.find(({ text, style }) => text === 'OK' || style === 'default');
    const cancelAction = actions.find(({ style }) => style === 'cancel');

    const message = `${title}\n${description}`;

    if (cancelAction && okAction) {
      if (window.confirm(message) && okAction.onPress) {
        okAction.onPress();
      }

      return;
    }

    window.alert(message);
  },
};
