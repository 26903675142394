import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { DateUtils } from '@valotvince/equireglementation-utils';

import { Typography } from '../../ui/components/typography.component';

export const Subscription = ({ autoRenew, endDate }) => {
  const endDateText = DateUtils.getFormattedDate(endDate.toDate());

  return (
    <View>
      <Typography>Votre souscription est active.</Typography>
      {autoRenew ? (
        <>
          <Typography>Elle est automatiquement renouvelé à la fin de la période.</Typography>
          <Typography>
            La prochaine échéance est le <Typography fontWeight="600">{endDateText}</Typography>.
          </Typography>
        </>
      ) : (
        <Typography>
          Elle se terminera le <Typography fontWeight="600">{endDateText}</Typography>, sans renouvellement automatique.
        </Typography>
      )}
    </View>
  );
};

Subscription.propTypes = {
  autoRenew: PropTypes.bool,
  endDate: PropTypes.object,
};
