export const getCoachingTrainings = ({ coaching: { trainings } }) => trainings;

export const isCoachingTrainingsFetched = ({ coaching: { trainingsFetched } }) => trainingsFetched;

export const getCoachingDocuments = ({ coaching: { documents } }) => documents;

export const isCoachingDocumentsFetched = ({ coaching: { documentsFetched } }) => documentsFetched;

export const getCoachingDocumentsCategories = ({ coaching: { documentsCategories } }) => documentsCategories;

export const getCoachingTrainingsCategories = ({ coaching: { trainingsCategories } }) => trainingsCategories;
