import { GDPR_ACCEPT_ALL, GDPR_DENY_ALL, GDPR_INIT, GDPR_RESET, GDPR_TOGGLE } from './gdpr.actions';
import { gdprPurposes } from './gdpr.constants';

const initialState = {
  hasUserInteracted: false,
  initiated: false,
  consents: gdprPurposes.reduce((accumulator, key) => ({ ...accumulator, [key]: false }), {}),
};

export const gdpr = (state = initialState, action) => {
  switch (action.type) {
    case GDPR_INIT:
    case GDPR_DENY_ALL:
    case GDPR_ACCEPT_ALL:
    case GDPR_TOGGLE: {
      const { hasUserInteracted = initialState.hasUserInteracted, consents = initialState.consents } = action;

      return {
        ...state,
        initiated: true,
        hasUserInteracted,
        consents,
      };
    }

    case GDPR_RESET:
      return { ...initialState };

    default:
      return state;
  }
};
