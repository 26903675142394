import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { View } from 'react-native';
import { useNavigation } from '@react-navigation/native';

import { userRedirectToIntegrator, userRegister } from '../user.actions';
import { VALIDATION_EMAIL } from '../../ui/constants/validation';
import { Form } from '../../ui/components/form.component';
import { KeyboardAwareScrollView } from '../../navigation/components/scrollView.component';
import { ModalScreen } from '../../navigation/components/modalScreen.component';
import { Separator } from '../../ui/components/separator.component';
import { TYPOGRAPHIES, Typography } from '../../ui/components/typography.component';
import { useScreenPadding } from '../../ui/utils/theme.utils';
import { getRemoteFeatureFlag } from '../../data/firebase';

const getErrorMessage = ({ code }) => {
  if (code === 'auth/email-already-in-use') {
    return "Cet email est déjà utilisé par un compte. Si vous avez oublié votre mot de passe, vous pouvez le ré-initialiser via l'écran de connexion";
  }

  return 'Merci de bien vouloir recommencer ultérieurement';
};

export const RegisterScreen = () => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const onSubmit = useCallback(async values => dispatch(userRegister(values)), []);

  const shouldHaveFormSteps = getRemoteFeatureFlag('feature_flag_shf');

  const redirectToIntegrator = useCallback(
    async email => !(await dispatch(userRedirectToIntegrator(navigation, email))),
    [dispatch, navigation],
  );

  const fields = useMemo(
    () => [
      {
        name: 'email',
        iconName: 'mail',
        placeholder: 'Email',
        autoCompleteType: 'email',
        autoCapitalize: 'none',
        keyboardType: 'email-address',
        textContentType: 'emailAddress',
        validation: value => VALIDATION_EMAIL.test(value),
        validationWarning: "L'adresse email n'est pas valide",

        ...(shouldHaveFormSteps
          ? { beforeNextStep: redirectToIntegrator, nextSteps: ['firstName', 'lastName', 'password'] }
          : {}),
      },
      {
        defaultHidden: shouldHaveFormSteps,
        name: 'firstName',
        autoFocus: true,
        iconName: 'person',
        placeholder: 'Prénom',
        autoCompleteType: 'name',
        textContentType: 'name',
      },
      {
        defaultHidden: shouldHaveFormSteps,
        name: 'lastName',
        iconName: 'people',
        placeholder: 'Nom',
        autoCompleteType: 'name',
        textContentType: 'familyName',
      },
      {
        defaultHidden: shouldHaveFormSteps,
        name: 'password',
        iconName: 'lock-closed',
        placeholder: 'Mot de passe',
        autoCapitalize: 'none',
        textContentType: 'newPassword',
        secureTextEntry: true,
        validation: value => value.length >= 6,
        validationWarning: 'Le mot de passe doit faire au moins 6 caractères',
      },
    ],
    [redirectToIntegrator],
  );

  const paddings = useScreenPadding();

  return (
    <ModalScreen title="Inscription">
      <KeyboardAwareScrollView persistentScrollbar raw>
        <View style={{ flex: 1, ...paddings }}>
          <Typography size={TYPOGRAPHIES.XXLARGE} textAlign="center" remoteKey="app_name" />
          <Separator size={20} />
          <Typography size={TYPOGRAPHIES.LARGE} remoteKey="app_explanation_level1" />
          <Separator size={20} />
          <Typography size={TYPOGRAPHIES.MEDIUM} remoteKey="app_explanation_level2" />
          <Separator size={40} />
          <Form fields={fields} actionTitle="S'enregistrer" onSubmit={onSubmit} getErrorMessage={getErrorMessage} />
        </View>
      </KeyboardAwareScrollView>
    </ModalScreen>
  );
};
