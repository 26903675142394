export const images = {
  library: {
    uri: '/assets/images/library.png',
    cache: 'force-cache',
  },
  horses: {
    uri: '/assets/images/horses.png',
    cache: 'force-cache',
  },
  classroom: {
    uri: '/assets/images/classroom.png',
    cache: 'force-cache',
  },
  equipass_splash: {
    uri: '/assets/images/equipass_splash.png',
    cache: 'force-cache',
  },
  equipass_full_medium: {
    uri: '/assets/images/logos/equipass-full-medium.png',
    cache: 'force-cache',
  },
  equipass_small_white: {
    uri: '/assets/images/logos/equipass-small-white.png',
    cache: 'force-cache',
  },
};
