import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import { survey } from './modules/survey/survey.reducer';
import { labels } from './modules/labels/labels.reducer';
import { gdpr } from './modules/gdpr/gdpr.reducer';
import { user } from './modules/user/user.reducer';
import { coaching } from './modules/coaching/coaching.reducer';
import { partners } from './modules/partners/partners.reducer';
import { messaging } from './modules/messaging/messaging.reducer';

export const store = createStore(
  combineReducers({ survey, labels, gdpr, user, coaching, partners, messaging }),
  applyMiddleware(thunk),
);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
