import React, { useCallback, MouseEvent as ReactMouseEvent } from 'react';
import { useLinkTo, useNavigation } from '@react-navigation/native';
import { GestureResponderEvent, Platform, View, TouchableOpacity } from 'react-native';
import { analytics } from '../../data/analytics';
import { getWebviewRoute } from '../navigation.utils';

type LinkProps = {
  to?: string;
  hrefAttrs?: Record<string, string>;
  analytics?: [string, Record<string, string>?];
  action?: any;
  onPress?: () => void;
};

export const Link = ({
  to,
  action,
  hrefAttrs,
  analytics: propAnalytics,
  onPress: propOnPress,
  ...props
}: LinkProps) => {
  const Component = Platform.select({ native: TouchableOpacity, web: View });
  const linkTo = useLinkTo();
  const navigation = useNavigation();

  const onPress = useCallback(
    (event?: ReactMouseEvent<HTMLAnchorElement, MouseEvent> | GestureResponderEvent) => {
      if (propOnPress) {
        propOnPress();
      }

      const absoluteUrl = to?.startsWith('http') || hrefAttrs?.target === '_blank';

      if (propAnalytics) {
        analytics.event(...propAnalytics);
      }

      if (absoluteUrl) {
        if (Platform.OS !== 'web' && to) {
          navigation.navigate(...getWebviewRoute(to));
        }

        return;
      }

      if (Platform.OS === 'web' && event) {
        if (absoluteUrl) {
          return;
        }

        event.preventDefault();
        event.stopPropagation();
      }

      if (action) {
        navigation.navigate(action);
      } else if (to) {
        linkTo(to);
      }
    },
    [to, propOnPress],
  );

  return (
    <Component
      {...Platform.select({
        web: { onClick: onPress, onPress },
        default: { onPress },
      })}
      accessibilityRole={to ? 'link' : 'button'}
      href={to}
      hrefAttrs={hrefAttrs}
      {...props}
    />
  );
};
