"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFormattedDate = exports.getTimeSince = void 0;
var SECOND = 1;
var MINUTE = SECOND * 60;
var HOUR = MINUTE * 60;
var DAY = HOUR * 24;
var MONTH = DAY * 30;
var YEAR = MONTH * 12;
var getTimeSince = function (date) {
    var diffSeconds = Date.now() / 1000 - date.getTime() / 1000;
    if (diffSeconds < 60) {
        return 'Quelques secondes';
    }
    if (diffSeconds < MINUTE * 5) {
        return 'Quelques minutes';
    }
    if (diffSeconds < 3600) {
        var minutes = Math.ceil(diffSeconds / 60);
        return minutes + " minute" + (minutes > 1 ? 's' : '');
    }
    if (diffSeconds < DAY) {
        var hours = Math.ceil(diffSeconds / 3600);
        return hours + " heure" + (hours > 1 ? 's' : '');
    }
    if (diffSeconds < MONTH) {
        var days = Math.ceil(diffSeconds / DAY);
        return days + " jour" + (days > 1 ? 's' : '');
    }
    if (diffSeconds < YEAR) {
        var months = Math.ceil(diffSeconds / MONTH);
        return months + " mois";
    }
    var years = Math.ceil(diffSeconds / YEAR);
    return years + " an" + (years > 1 ? 's' : '');
};
exports.getTimeSince = getTimeSince;
var getFormattedDate = function (date, precise) {
    if (precise === void 0) { precise = false; }
    var formattedDate = '';
    var day = date.getDate();
    if (day < 10) {
        formattedDate = "0" + day;
    }
    else {
        formattedDate = "" + day;
    }
    var month = date.getMonth() + 1;
    if (month < 10) {
        formattedDate = formattedDate + "/0" + month;
    }
    else {
        formattedDate = formattedDate + "/" + month;
    }
    var part1 = formattedDate + "/" + date.getFullYear();
    if (!precise) {
        return part1;
    }
    return part1 + " " + date.getHours() + ":" + date.getMinutes();
};
exports.getFormattedDate = getFormattedDate;
