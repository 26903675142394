import { Platform, Dimensions } from 'react-native';

export const IS_TABLET = (function() {
  if (Platform.OS === 'ios') {
    return Platform.isPad;
  }

  const { height, width } = Dimensions.get('window');
  const aspectRatio = height / width;

  return aspectRatio < 1.6;
})();
