import { SurveyUtils } from '@valotvince/equireglementation-utils';
import { Alert } from '../ui/utils/alert';

import * as Sentry from '../../utils/sentry';
import { analytics } from '../data/analytics';
import { getRemoteText, runCloudFunction } from '../data/firebase';

export const answerAndMeetSurveysQuestions = (surveys, answers) =>
  Object.keys(surveys).reduce(
    (accumulator, surveyId) => ({
      ...accumulator,
      [surveyId]: SurveyUtils.prepareSurveyData(surveys[surveyId], answers),
    }),
    {},
  );

export const requestSurveyPDF = ({ surveyId, pdfType }) => {
  analytics.event('survey_export', { surveyId, exportType: pdfType });

  const title = pdfType === 'certificate' ? 'Réception de votre attestation' : 'Réception de PDF de bilan';
  const content = getRemoteText(
    pdfType === 'certificate' ? 'surveys_download_pdf_certificate_success' : 'surveys_download_pdf_review_success',
  );

  Alert.alert(title, content, [{ text: 'OK' }], {
    cancelable: true,
  });

  return (
    runCloudFunction('survey-getSurveyPDF', { surveyId, pdfType })
      // Silent fail
      .catch(error => {
        Sentry.captureException(error);

        return Promise.reject(error);
      })
  );
};
