import React, { useEffect, useState } from 'react';
import { Image } from 'react-native';
import PropTypes from 'prop-types';

import { getImageSource } from '../../data/firebase/image';

export const AsyncImage = ({ imageRef, fallbackImage, style, height, width, ...props }) => {
  const [source, setSource] = useState();

  const setFallbackImage = () => {
    if (fallbackImage) {
      setSource(fallbackImage);
    }
  };

  useEffect(() => {
    getImageSource(imageRef)
      .then(setSource)
      // Do nothing, fallbacks on the fallback image
      .catch(setFallbackImage);
  }, [imageRef]);

  let computedWidth = width;
  let computedHeight = height;

  if (source) {
    if (height && !width) {
      computedWidth = (height / source.height) * source.width;
    }

    if (width && !height) {
      computedHeight = (width / source.width) * source.height;
    }
  }

  return <Image source={source} {...props} style={[style, { width: computedWidth, height: computedHeight }]} />;
};

AsyncImage.defaultProps = {
  style: {},
};

AsyncImage.propTypes = {
  imageRef: PropTypes.string.isRequired,
  fallbackImage: PropTypes.any,
  style: PropTypes.any,
  height: PropTypes.number,
  width: PropTypes.number,
};
