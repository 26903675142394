import React, { ForwardedRef, forwardRef, useCallback } from 'react';
import { useMemo } from 'react';
import { FlatList, FlatListProps, StyleSheet, View, Dimensions } from 'react-native';
import { useTheme } from 'styled-components/native';
import { ListFooterProps } from '../../navigation/components/footer.component';

import { SafeAreaView } from '../../navigation/components/scrollView.component';

import { BREAKPOINTS } from '../constants/breakpoints';
import { useBreakpoint, useScreenPadding } from '../utils/theme.utils';
import { CARD_HEIGHT } from './card.component';
import { Separator } from './separator.component';

const numColumnsPerBreakpoints = {
  [BREAKPOINTS.MEDIUM]: 1,
  [BREAKPOINTS.XXLARGE]: 1,
};

const emptyData = [
  { id: 'ghost-1', ghost: true },
  { id: 'ghost-2', ghost: true },
  { id: 'ghost-3', ghost: true },
  { id: 'ghost-4', ghost: true },
];

const styles = StyleSheet.create({
  columnWrapperStyle: { flex: 1 },
});

type ResponsiveFlatListProps = FlatListProps<any> & {
  loading: boolean;
  itemHeight?: number;
};

export const ResponsiveFlatList = forwardRef(
  (
    { loading, itemHeight = CARD_HEIGHT, data, renderItem, ...props }: ResponsiveFlatListProps,
    ref: ForwardedRef<FlatList>,
  ) => {
    const numColumns = useBreakpoint(numColumnsPerBreakpoints, 1);
    const windowHeight = Dimensions.get('window').height;

    const renderItemWithColumns = useCallback(
      info => {
        return (
          <View
            style={{
              flex: numColumns > 1 ? 1 / numColumns : undefined,
              marginRight: numColumns > 1 && info.index % numColumns === 0 ? 20 : undefined,
              alignItems: 'center',
            }}
          >
            {renderItem!(info)}
          </View>
        );
      },
      [numColumns, renderItem],
    );

    const {
      dark,
      colors: { primary, secondary },
    } = useTheme();

    const paddings = useScreenPadding({ withFooter: true });
    const contentContainerStyle = useMemo(
      () => [paddings, props.contentContainerStyle, { flexGrow: 1, backgroundColor: dark ? primary : secondary }],
      [paddings, props.contentContainerStyle, dark, primary, secondary],
    );

    const columnWrapperStyle = useMemo(
      () => (numColumns > 1 ? [styles.columnWrapperStyle, props.columnWrapperStyle] : undefined),
      [numColumns, props.columnWrapperStyle],
    );

    // Optimizes render on small devices
    // 150 to take into account the modal's header and padding
    const initialNumToRender = useMemo(
      () => Math.min(10, Math.ceil((windowHeight - 150) / itemHeight)),
      [windowHeight],
    );

    const getItemLayout = useCallback((data, index) => ({ length: itemHeight, offset: itemHeight * index, index }), []);

    return (
      <SafeAreaView raw={false}>
        <FlatList
          {...props}
          ref={ref}
          key={numColumns}
          data={loading ? emptyData : data}
          ItemSeparatorComponent={Separator}
          renderItem={renderItemWithColumns}
          numColumns={numColumns}
          contentContainerStyle={contentContainerStyle}
          columnWrapperStyle={columnWrapperStyle}
          initialNumToRender={initialNumToRender}
          getItemLayout={getItemLayout}
          {...ListFooterProps}
        />
      </SafeAreaView>
    );
  },
);
