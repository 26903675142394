import React, { useMemo } from 'react';
import { DrawerContentComponentProps, DrawerContentScrollView, DrawerItem } from '@react-navigation/drawer';
import { useSelector } from 'react-redux';
import styled, { useTheme } from 'styled-components/native';
import { StyleSheet, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useLinkTo } from '@react-navigation/native';

import { getSurveys } from '../../survey/survey.selectors';
import { DrawerParamList } from '../navigation.types';

import { getLinkTo } from '../utils/getLinkTo.utils';
import { ROUTE_NAMES } from '../const/linking';
import { DrawerHeader } from './drawer/drawerHeader.component';
import { TYPOGRAPHIES, Typography } from '../../ui/components/typography.component';

const SurveysHeading = styled(Typography)`
  padding: 8px;
  margin-vertical: 10px;
  margin-horizontal: 10px;
`;

const ContentWrapper = styled(View)`
  flex: 1;
  padding-top: 20px;
  padding-bottom: 40px;

  justify-content: space-between;
`;

const styles = StyleSheet.create({
  surveyDrawerItem: {
    marginLeft: 30,
  },
});

export const AbstractDrawerContent = (props: DrawerContentComponentProps) => {
  const { state } = props;
  const {
    colors: { primary, secondary, text },
  } = useTheme();

  const surveys = useSelector(getSurveys);

  const drawerItemProps = {
    activeBackgroundColor: secondary,
    activeTintColor: text,
    inactiveTintColor: 'white',
  };

  const { routes = [], index = 0 } = state;
  const { name: routeName, params: routeParams } = routes[index] || {};

  const surveyRouteParams = routeParams as DrawerParamList['Survey'];

  const linkTo = useLinkTo();

  const safeAreaInsets = useSafeAreaInsets();

  const drawerStyle = useMemo(() => ({ backgroundColor: primary }), [primary]);
  const drawerContentContainerStyle = useMemo(
    () => ({ paddingTop: Math.max(safeAreaInsets.top, 40) }),
    [safeAreaInsets.top],
  );

  return (
    <DrawerContentScrollView contentContainerStyle={drawerContentContainerStyle} style={drawerStyle} {...props}>
      <DrawerHeader routeName={routeName} />

      <ContentWrapper>
        <View>
          <SurveysHeading size={TYPOGRAPHIES.MEDIUM} color="white" remoteKey="surveys" />
          {Object.keys(surveys)
            .map(surveyId => surveys[surveyId])
            .filter(survey => survey.display === 'drawer')
            .sort((first, second) => first.order - second.order)
            .map(({ name, id: surveyId }) => {
              return (
                <DrawerItem
                  {...drawerItemProps}
                  key={surveyId}
                  label={name}
                  focused={routeName === 'Survey' && surveyRouteParams && surveyRouteParams.surveyId === surveyId}
                  to={getLinkTo(ROUTE_NAMES.SURVEY, { surveyId })}
                  onPress={() => linkTo(getLinkTo(ROUTE_NAMES.SURVEY, { surveyId }))}
                  style={styles.surveyDrawerItem}
                />
              );
            })}
          <DrawerItem
            {...drawerItemProps}
            label="Mon Coaching"
            focused={routeName === 'Coaching'}
            to={getLinkTo(ROUTE_NAMES.COACHING)}
            onPress={() => linkTo(getLinkTo(ROUTE_NAMES.COACHING))}
          />
          <DrawerItem
            {...drawerItemProps}
            label="Mes Labels"
            focused={routeName === 'Labels'}
            to={getLinkTo(ROUTE_NAMES.LABELS)}
            onPress={() => linkTo(getLinkTo(ROUTE_NAMES.LABELS))}
          />
          <DrawerItem
            {...drawerItemProps}
            label="Mes Pass"
            focused={routeName === 'Passes'}
            to={getLinkTo(ROUTE_NAMES.USER_PASSES)}
            onPress={() => linkTo(getLinkTo(ROUTE_NAMES.USER_PASSES))}
          />
          <DrawerItem
            {...drawerItemProps}
            label="Mon Profil"
            focused={routeName === 'Profile'}
            to={getLinkTo(ROUTE_NAMES.USER_PROFILE)}
            onPress={() => linkTo(getLinkTo(ROUTE_NAMES.USER_PROFILE))}
          />
        </View>
      </ContentWrapper>
    </DrawerContentScrollView>
  );
};

export const DrawerContent = (props: DrawerContentComponentProps) => <AbstractDrawerContent {...props} />;
