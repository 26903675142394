import React from 'react';
import { BottomTabNavigationOptions } from '@react-navigation/bottom-tabs';

import { TabBarIcon } from '../navigation/components/tabBarIcon.component';
import { TabBarLabel } from '../navigation/components/tabBarLabel.component';
import { createMixedTabBarNavigator } from '../navigation/utils/createMixedTabBarNavigator.utils';
import { CoachingDataScreen } from './coaching.screen';
import { coachingDocumentsFetchData, coachingTrainingsFetchData } from './coaching.actions';
import {
  getCoachingDocuments,
  getCoachingDocumentsCategories,
  getCoachingTrainings,
  getCoachingTrainingsCategories,
  isCoachingDocumentsFetched,
  isCoachingTrainingsFetched,
} from './coaching.selectors';

const CoachingDocumentsScreen = () => (
  <CoachingDataScreen
    dataFetcher={coachingDocumentsFetchData}
    dataSelector={getCoachingDocuments}
    dataFetchedSelector={isCoachingDocumentsFetched}
    categoriesSelector={getCoachingDocumentsCategories}
    analyticsConfig={{
      category: 'coaching',
      label: 'documents',
    }}
    noDataTextKey="coaching_documents_no_data"
    categoryTextKey="coaching_documents_category"
  />
);

const CoachingTrainingsScreen = () => (
  <CoachingDataScreen
    dataFetcher={coachingTrainingsFetchData}
    dataSelector={getCoachingTrainings}
    dataFetchedSelector={isCoachingTrainingsFetched}
    categoriesSelector={getCoachingTrainingsCategories}
    analyticsConfig={{
      category: 'coaching',
      label: 'trainings',
    }}
    noDataTextKey="coaching_trainings_no_data"
    categoryTextKey="coaching_trainings_category"
  />
);

const screens = [
  {
    name: 'CoachingTabDocuments',
    component: CoachingDocumentsScreen,
    options: {
      tabBarIcon: props => <TabBarIcon {...props} name="book" />,
      tabBarLabel: props => <TabBarLabel {...props} title="Documents" />,
      swipeEnabled: false,
      title: 'Mon Coaching - Documents',
    } as BottomTabNavigationOptions,
  },
  {
    name: 'CoachingTabTrainings',
    component: CoachingTrainingsScreen,
    options: {
      tabBarIcon: props => <TabBarIcon {...props} name="folder-open" />,
      tabBarLabel: props => <TabBarLabel {...props} title="Formations" />,
      swipeEnabled: false,
      title: 'Mon Coaching - Formations',
    } as BottomTabNavigationOptions,
  },
];

export const CoachingTab = createMixedTabBarNavigator({ screens });
