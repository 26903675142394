import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Platform, TouchableOpacity, View } from 'react-native';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/native';
import { DateUtils } from '@valotvince/equireglementation-utils';
import Animated, { useAnimatedStyle, useSharedValue, withSpring } from 'react-native-reanimated';

import { condition, get, getThemeColor, mq } from '../../ui/utils/theme.utils';
import { ThemeProvider } from '../../ui/components/theme.component';
import { Icon } from '../../ui/components/icon.component';
import { Link } from '../../navigation/components/link.component';

import { TYPOGRAPHIES, Typography } from '../../ui/components/typography.component';

export const NOTIFICATION_HEIGHT = 140;

const Wrapper = styled(Link)`
  flex: 1;
  flex-direction: column;
  padding-vertical: 15px;
  padding-horizontal: 10px;
  border-radius: 10px;
  align-content: space-between;

  height: ${NOTIFICATION_HEIGHT}px;

  background-color: ${getThemeColor('primary')};
  border: 1px solid rgba(255, 255, 255, 0.5);

  ${mq.sm(css`
    padding-horizontal: 15px;
  `)}

  ${mq.md(css`
    padding: 20px;
  `)}

  ${condition(
    'read',
    css`
      border: 1px solid rgba(0, 0, 0, 0.1);
      background-color: ${getThemeColor('secondary')};
    `,
  )}

  ${Platform.select({
    default: css`
      shadow-color: black;
      shadow-offset: 3px 3px;
      shadow-opacity: 0.15;
      shadow-radius: 3px;
    `,
    android: css`
      elevation: 8;
    `,
  })}
`;

const ContentWrapper = styled(View)`
  flex-direction: row;
  flex: 1;
  overflow: hidden;
`;

const CalculusTypography = styled(Typography)`
  opacity: 1;

  ${condition(
    'columnWidth',
    css`
      column-width: ${get('columnWidth')}px;
    `,
  )}
`;

const ContentTypography = styled(CalculusTypography)`
  flex: 1;

  max-height: 100%;
`;

const ExtraWrapper = styled(View)`
  flex-direction: row;
  justify-content: space-between;
`;

const IconWrapper = styled(View)`
  justify-content: center;
  margin-left: 10px;
`;

const springConfig = {
  damping: 20,
  stiffness: 90,
};

// Hack for line-clamp to work on web
const BodyWrapper = Platform.select({
  default: ({ children }) => children,
  // eslint-disable-next-line
  web: ({ children }) => (
    <View style={{ flex: 1, marginBottom: 10 }}>
      <View style={{ display: 'block', width: '100%', height: '100%' }}>{children}</View>
    </View>
  ),
});

const expandHitSlop = { top: 10, right: 50, bottom: 10, left: 10 };

export const AbstractNotification = ({ iconName, body, sent, read, linkProps, defaultExpanded = false }) => {
  const [expanded, setExpanded] = useState(false);
  const [textDimensions, setTextDimensions] = useState({ width: 0, height: 0 });
  const [expandedTextHeight, setExpandedTextHeight] = useState();
  const animatedHeight = useSharedValue(NOTIFICATION_HEIGHT);

  const couldExpand = textDimensions.height && expandedTextHeight && expandedTextHeight > textDimensions.height;

  useEffect(() => {
    if (!couldExpand) {
      return;
    }

    if (expanded) {
      animatedHeight.value = withSpring(NOTIFICATION_HEIGHT - textDimensions.height + expandedTextHeight, springConfig);
    } else {
      animatedHeight.value = withSpring(NOTIFICATION_HEIGHT, springConfig);
    }
  }, [expanded, couldExpand]);

  // Let the layout compute the values before default expanding a notification
  useEffect(() => {
    if (!couldExpand || !defaultExpanded) {
      return;
    }

    setExpanded(defaultExpanded);
  }, [couldExpand, defaultExpanded]);

  const expandedStyle = useAnimatedStyle(
    () => ({
      height: animatedHeight.value,
    }),
    [expandedTextHeight],
  );

  const textHeightLayoutStyle = useMemo(
    () => ({
      position: 'absolute',
      opacity: 0,
      backgroundColor: 'rgba(255, 0, 0, 0.3)',
      width: textDimensions.width,
    }),
    [textDimensions.width],
  );

  const onLayoutExpandedTextHeight = useCallback(
    event => {
      // The expanded height can only be correctly computed when the text width is correctly set.
      // Any calls before that will result in wrong computations
      if (!textDimensions.width) {
        return;
      }

      // Add a little bit more of height for native devices since it seems they are more / less precise on heights
      setExpandedTextHeight(event.nativeEvent.layout.height + Platform.select({ native: 5, default: 0 }));
    },
    [textDimensions.width],
  );

  const onLayoutText = useCallback(
    event => {
      const { width, height } = event.nativeEvent.layout;

      // Allows to recompute when the width changes (resize in web, or device orientation changes)
      // But not when the actual layout is changed when we expand the notification
      if (!expanded && textDimensions.width !== width) {
        setTextDimensions({ width, height });
      }
    },
    [textDimensions.width, expanded],
  );

  const onToggleExpand = useCallback(event => {
    // Prevents the click to be caught by a potential anchor link above
    if (Platform.OS === 'web') {
      event.preventDefault();
      event.stopPropagation();
    }

    setExpanded(previousExpanded => !previousExpanded);
  }, []);

  return (
    <ThemeProvider dark={!read}>
      <Animated.View style={expandedStyle}>
        <Wrapper {...linkProps} read={read} expanded={expanded}>
          <ContentWrapper>
            <BodyWrapper>
              <CalculusTypography
                columnWidth={textDimensions.width ? textDimensions.width : undefined}
                style={textHeightLayoutStyle}
                onLayout={onLayoutExpandedTextHeight}
              >
                {body}
              </CalculusTypography>
              <ContentTypography
                columnWidth={textDimensions.width ? textDimensions.width : undefined}
                numberOfLines={expanded ? undefined : 4}
                onLayout={onLayoutText}
              >
                {body}
              </ContentTypography>
            </BodyWrapper>
            <IconWrapper>
              {iconName ? <Icon color={read ? 'black' : 'white'} name={iconName} size={32} /> : null}
            </IconWrapper>
          </ContentWrapper>
          <ExtraWrapper>
            {couldExpand ? (
              <TouchableOpacity onPress={onToggleExpand} hitSlop={expandHitSlop}>
                <Typography size={TYPOGRAPHIES.SMALL}>
                  <Icon name={expanded ? 'caret-up' : 'caret-down'} size={16} />
                  {expanded ? 'Voir moins ...' : 'Voir plus ...'}
                </Typography>
              </TouchableOpacity>
            ) : (
              <View />
            )}
            <Typography size={TYPOGRAPHIES.SMALL}>{DateUtils.getTimeSince(new Date(sent.seconds * 1000))}</Typography>
          </ExtraWrapper>
        </Wrapper>
      </Animated.View>
    </ThemeProvider>
  );
};

export const Notification = props => <AbstractNotification {...props} />;

AbstractNotification.propTypes = {
  body: PropTypes.string,
  iconName: PropTypes.string,
  read: PropTypes.bool,
  defaultExpanded: PropTypes.bool,
  sent: PropTypes.object.isRequired,
  linkProps: PropTypes.object.isRequired,
};
