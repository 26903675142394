import React, { useEffect, useMemo } from 'react';
import { Platform } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';
import { useDispatch, useSelector } from 'react-redux';

import { useMixedTabBarNavigator } from './utils/createMixedTabBarNavigator.utils';
import { getSurvey, isSurveyPaymentEnabled } from '../survey/survey.selectors';
import { surveyFetchData } from '../survey/survey.actions';

import { TabBarIcon } from './components/tabBarIcon.component';
import { TabBarLabel } from './components/tabBarLabel.component';
import { Header } from './components/header.component';
import { SimpleHeader } from './components/simpleHeader.component';
import { Route } from '@react-navigation/routers';
import { SurveyContext, useContextualSurvey } from './utils/useContextualSurvey.hook';
import { withSuspense } from './utils/withSuspense.utils';
import { hasUserConnectedIntegrators } from '../user/user.selectors';
import { getRemoteText } from '../data/firebase';

type SurveyNavigatorProps = {
  route: Route<any, { surveyId: string }>;
};

const HomeScreen = withSuspense(React.lazy(() => import(/* webpackChunkName: "survey" */ './screens/home.screen')));
const SurveyScreen = withSuspense(React.lazy(() => import(/* webpackChunkName: "survey" */ '../survey/survey.screen')));
const SurveyActions = withSuspense(
  React.lazy(() => import(/* webpackChunkName: "survey" */ '../survey/screens/surveyActions.screen')),
);
const SurveyPayment = withSuspense(
  React.lazy(() => import(/* webpackChunkName: "survey" */ '../survey/screens/surveyPayment.screen')),
);

const SurveyTabNavigator = () => {
  const [TabComponent, screenOptions] = useMixedTabBarNavigator();
  const { id: surveyId, name } = useContextualSurvey();

  const userHasConnectedIntegrators = useSelector(hasUserConnectedIntegrators);
  const surveyPaymentEnabled = useSelector(isSurveyPaymentEnabled(surveyId));

  const tabMainOptions = useMemo(
    () => ({
      tabBarIcon: props => <TabBarIcon {...props} name="layers" />,
      tabBarLabel: props => <TabBarLabel {...props} title="Autodiagnostic" />,
      title: name,
    }),
    [name],
  );

  const tabActionsOptions = useMemo(
    () => ({
      tabBarIcon: props => <TabBarIcon {...props} name="flag" />,
      tabBarLabel: props => <TabBarLabel {...props} title="Résultats" />,
      title: `${name} - Résultats`,
    }),
    [name],
  );

  const tabPaymentOptions = useMemo(
    () => ({
      tabBarIcon: props => <TabBarIcon {...props} name="ribbon" />,
      tabBarLabel: props => <TabBarLabel {...props} title={getRemoteText('subscription')} />,
      title: `${name} - ${getRemoteText('subscription')}`,
    }),
    [name],
  );

  return (
    <TabComponent.Navigator screenOptions={screenOptions}>
      <TabComponent.Screen name="SurveyTabMain" component={HomeScreen} options={tabMainOptions} />
      <TabComponent.Screen name="SurveyTabActions" component={SurveyActions} options={tabActionsOptions} />
      {surveyPaymentEnabled &&
        !userHasConnectedIntegrators && (
          <TabComponent.Screen name="SurveyTabPayment" component={SurveyPayment} options={tabPaymentOptions} />
        )}
    </TabComponent.Navigator>
  );
};

const Stack = createStackNavigator();

export const SurveyNavigator = ({ route: { params: routeParams } }: SurveyNavigatorProps) => {
  const { surveyId } = routeParams || {};
  const { name } = useSelector(getSurvey(surveyId));
  const dispatch = useDispatch();

  useEffect(
    () => {
      dispatch(surveyFetchData(surveyId));
    },
    [surveyId],
  );

  let sanitizedName = Platform.select({ default: name, android: `${name} ` });
  sanitizedName = sanitizedName ? sanitizedName.toUpperCase() : sanitizedName;

  const surveyTabOptions = useMemo(() => ({ title: sanitizedName, header: Header }), [sanitizedName]);
  const surveyCategoryOptions = useMemo(() => ({ title: sanitizedName, header: SimpleHeader }), [sanitizedName]);

  return (
    <SurveyContext.Provider value={surveyId}>
      <Stack.Navigator>
        <Stack.Screen name="SurveyTab" component={SurveyTabNavigator} options={surveyTabOptions} />
        <Stack.Screen name="SurveyCategory" component={SurveyScreen} options={surveyCategoryOptions} />
      </Stack.Navigator>
    </SurveyContext.Provider>
  );
};
