import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { StyleSheet, View, ScrollView as NativeScrollView, SafeAreaView as NativeSafeAreaView } from 'react-native';

import { getThemeColor } from '../utils/theme.utils';
import { Title } from './title.component';
import { Button } from './button.component';

const SafeAreaView = styled(NativeSafeAreaView)`
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

const Wrapper = styled(View)`
  background-color: ${getThemeColor('secondary')};

  max-width: 600px;

  margin-horizontal: 20px;
  margin-vertical: 20px;
  padding-vertical: 40px;
  padding-horizontal: 20px;
  border-radius: 10px;
`;

const ScrollView = styled(NativeScrollView)`
  max-height: 80%;
`;

const ContentWrapper = styled(View)`
  flex-direction: column;
`;

const ActionsWrapper = styled(View)`
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
`;

const styles = StyleSheet.create({
  scrollViewContentContainer: {
    justifyContent: 'center',
    flexGrow: 1,
  },
});

export const Modal = ({ testID, titleLabel, titleIcon, children, actions }) => {
  return (
    <SafeAreaView>
      <ScrollView contentContainerStyle={styles.scrollViewContentContainer}>
        <Wrapper testID={testID}>
          {titleLabel && <Title label={titleLabel} icon={titleIcon} />}
          <ContentWrapper>{children}</ContentWrapper>
          {actions.length ? (
            <ActionsWrapper>
              {actions.map((action, index) => (
                <Button key={action.title} testID={`${testID}-action-${index}`} {...action} />
              ))}
            </ActionsWrapper>
          ) : null}
        </Wrapper>
      </ScrollView>
    </SafeAreaView>
  );
};

Modal.defaultProps = {
  actions: [],
};

Modal.propTypes = {
  testID: PropTypes.string,
  titleLabel: PropTypes.string,
  titleIcon: PropTypes.string,
  children: PropTypes.any,
  actions: PropTypes.array,
};
