export const gdprNames = {
  tracking: "Mesure d'audience",
};

export const gdprExplanations = {
  tracking:
    'Ces cookies permettent d’établir des statistiques de fréquentation du service. Les désactiver nous empêche de suivre et d’améliorer la qualité du service au moyen de cookies.',
};

export const gdprPurposes = Object.keys(gdprNames);

export const gdprUsedPurposes = {
  tracking: true,
};
