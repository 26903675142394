import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Animated, Platform } from 'react-native';

import { colors } from '../constants/colors';

// Animated value to 0.01 to prevent a graphic bug on Android animation
const scaleZeroValue = Platform.select({ ios: 0, android: 0.01, web: 0 });

export const Loader = ({ size = 30, color = colors.tertiary, style: propStyle }) => {
  const scale = useRef(new Animated.Value(scaleZeroValue)).current;

  useEffect(() => {
    const animation = Animated.loop(
      Animated.sequence([
        Animated.timing(scale, {
          toValue: 1,
          duration: 500,
          useNativeDriver: Platform.select({ web: false, native: true }),
        }),
        Animated.timing(scale, {
          toValue: scaleZeroValue,
          duration: 750,
          useNativeDriver: Platform.select({ web: false, native: true }),
        }),
      ]),
    );

    animation.start();

    return () => {
      animation.stop();
      scale.stopAnimation();
      scale.setValue(1);
    };
  }, []);

  const style = [
    {
      borderRadius: size,
      height: size,
      width: size,
      backgroundColor: color,
      transform: [{ scale }],
    },
  ];

  if (Array.isArray(propStyle)) {
    style.push(...propStyle);
  } else if (style) {
    style.push(propStyle);
  }

  return <Animated.View style={style} />;
};

Loader.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};
