import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components/native';

import { gdprAcceptAll, gdprDenyAll } from './gdpr.actions';
import { ButtonTypes } from '../ui/components/button.component';
import { Modal } from '../ui/components/modal.component';
import { TYPOGRAPHIES, Typography } from '../ui/components/typography.component';

const ChangeText = styled(Typography)`
  margin-vertical: 10px;
`;

export const GdprScreen = () => {
  const hasUserInteracted = useSelector(({ gdpr }) => gdpr.hasUserInteracted);
  const dispatch = useDispatch();

  const onAccept = useCallback(() => dispatch(gdprAcceptAll()), []);
  const onDeny = useCallback(() => dispatch(gdprDenyAll()), []);

  if (hasUserInteracted) {
    return null;
  }

  return (
    <Modal
      titleLabel="Protection des données"
      titleIcon="people"
      testID="gdpr-modal"
      actions={[
        { title: 'Je refuse', type: ButtonTypes.NO_OUTLINE, onPress: onDeny },
        { title: "J'accepte", type: ButtonTypes.PRIMARY, onPress: onAccept },
      ]}
    >
      <Typography size={TYPOGRAPHIES.LARGE} remoteKey="privacy_analytics_explanation" />
      <ChangeText size={TYPOGRAPHIES.MEDIUM}>
        Vous pouvez changer votre choix à tout moment dans votre espace "Mon Profil", accessible en haut à droit de
        l'application
      </ChangeText>
    </Modal>
  );
};
