import React, { useMemo } from 'react';
import { StyleSheet, TouchableOpacity, View, Image } from 'react-native';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useSelector } from 'react-redux';
import { useLinkProps, useNavigation } from '@react-navigation/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { colors } from '../../ui/constants/colors';
import { getThemeColor, useBreakpoint } from '../../ui/utils/theme.utils';
import { useFontScale } from '../../ui/components/fontScale.hook';
import { Icon } from '../../ui/components/icon.component';
import { getUnreadMessagesNumber } from '../../messaging/messaging.selectors';
import { Bubble } from '../../ui/components/bubble.component';

import { TYPOGRAPHIES, Typography } from '../../ui/components/typography.component';
import { SubHeader } from './subHeader.component';
import { BREAKPOINTS } from '../../ui/constants/breakpoints';
import { images } from '../../ui/const/images';

const styles = StyleSheet.create({
  rightButton: {
    alignItems: 'flex-end',
    flex: 1,
  },
  drawerButton: {
    alignItems: 'flex-start',
    flex: 1,
  },
  middle: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center',
  },
  notificationsBubble: {
    position: 'absolute',
    right: -10,
    bottom: -5,
  },
});

const SafeAreaWrapper = styled(View)`
  background-color: ${getThemeColor('primary')};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
`;

const HeaderWrapper = styled(View)`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-content: center;

  height: 64px;
  width: 100%;

  padding-horizontal: 20px;
`;

const AppName = styled(Typography).attrs({
  size: TYPOGRAPHIES.XLARGE,
  color: 'white',
})`
  margin-left: 5px;
`;

const CenterComponentPropTypes = {
  fontScale: PropTypes.number,
  iconSize: PropTypes.number,
};

const SmallCenterComponent = ({ fontScale, iconSize }) => {
  const aboutProps = useLinkProps({ to: '/a-propos' });

  return (
    <TouchableOpacity style={styles.middle} {...aboutProps}>
      <Image
        style={{
          width: iconSize,
          height: iconSize,
          borderRadius: iconSize / 2,
        }}
        source={images.equipass_small_white}
      />
      {fontScale < 1.3 ? <AppName remoteKey="app_name" /> : null}
    </TouchableOpacity>
  );
};

SmallCenterComponent.propTypes = CenterComponentPropTypes;

const LargeCenterComponent = () => {
  return (
    <View style={styles.middle}>
      <Typography remoteKey="app_description" color="white" numberOfLines={1} size={TYPOGRAPHIES.MEDIUM} />
    </View>
  );
};

LargeCenterComponent.propTypes = CenterComponentPropTypes;

const centerComponentBreakpoints = {
  [BREAKPOINTS.SMALL]: SmallCenterComponent,
  [BREAKPOINTS.LARGE]: LargeCenterComponent,
};

const withDrawerBreakpoints = {
  [BREAKPOINTS.LARGE]: false,
};

export const Header = ({ options }) => {
  const fontScale = useFontScale(1.5);
  const iconSize = 30 * fontScale;
  const navigation = useNavigation();

  const unreadMessagesNumber = useSelector(getUnreadMessagesNumber);
  const withDrawer = useBreakpoint(withDrawerBreakpoints, true);
  const CenterComponent = useBreakpoint(centerComponentBreakpoints, SmallCenterComponent);
  const messagingProps = useLinkProps({ to: '/notifications' });
  const insets = useSafeAreaInsets();

  const safeAreaStyle = useMemo(() => ({ paddingTop: insets.top }), [insets.top]);

  return (
    <SafeAreaWrapper style={safeAreaStyle}>
      <HeaderWrapper>
        {withDrawer ? (
          <TouchableOpacity style={styles.drawerButton} onPress={() => navigation.toggleDrawer()}>
            <Icon name="menu" size={iconSize} color={colors.iconFocused} />
          </TouchableOpacity>
        ) : (
          <View style={styles.drawerButton} />
        )}
        <CenterComponent iconSize={iconSize} fontScale={fontScale} />
        <TouchableOpacity style={styles.rightButton} {...messagingProps}>
          <Icon name="notifications" size={iconSize * 0.85} color={colors.iconFocused} />
          {unreadMessagesNumber ? (
            <Bubble
              {...messagingProps}
              style={styles.notificationsBubble}
              size={iconSize * 0.7 * fontScale}
              fontSize={12}
              backgroundColor={colors.red}
              text={unreadMessagesNumber > 9 ? '9+' : unreadMessagesNumber}
            />
          ) : null}
        </TouchableOpacity>
      </HeaderWrapper>
      <SubHeader title={options.title} />
    </SafeAreaWrapper>
  );
};

Header.propTypes = {
  options: PropTypes.object.isRequired,
};
