import React from 'react';
import { BottomTabNavigationOptions } from '@react-navigation/bottom-tabs';

import { TabBarIcon } from './components/tabBarIcon.component';
import { ProfileScreen } from '../user/screens/profile.screen';
import { PrivacyScreen } from '../user/screens/privacy.screen';
import { InAppPurchasesScreen } from '../user/screens/inAppPurchases.screen';
import { ProfileActionsScreen } from '../user/screens/profileActions.screen';
import { TabBarLabel } from './components/tabBarLabel.component';
import { createMixedTabBarNavigator } from './utils/createMixedTabBarNavigator.utils';
import { BREAKPOINTS } from '../ui/constants/breakpoints';
import { getRemoteText } from '../data/firebase';

const screens = [
  {
    name: 'ProfileTabUser',
    component: ProfileScreen,
    options: {
      tabBarIcon: props => <TabBarIcon {...props} name="person" />,
      tabBarLabel: props => <TabBarLabel {...props} title="Profil" showOn={BREAKPOINTS.SMALL} />,
      title: 'Profil',
    } as BottomTabNavigationOptions,
  },
  {
    name: 'ProfileTabIAP',
    component: InAppPurchasesScreen,
    options: {
      tabBarIcon: props => <TabBarIcon {...props} name="ribbon" />,
      tabBarLabel: props => (
        <TabBarLabel {...props} title={getRemoteText('subscriptions')} showOn={BREAKPOINTS.SMALL} />
      ),
      title: `Profil - ${getRemoteText('subscriptions')}`,
    } as BottomTabNavigationOptions,
  },
  {
    name: 'ProfileTabAnalytics',
    component: PrivacyScreen,
    options: {
      tabBarIcon: props => <TabBarIcon {...props} name="analytics" />,
      tabBarLabel: props => <TabBarLabel {...props} title="Données personnelles" showOn={BREAKPOINTS.SMALL} />,
      title: 'Profil - Données personnelles',
    } as BottomTabNavigationOptions,
  },
  {
    name: 'ProfileTabActions',
    component: ProfileActionsScreen,
    options: {
      tabBarIcon: props => <TabBarIcon {...props} name="ellipsis-horizontal" />,
      tabBarLabel: props => <TabBarLabel {...props} title="Autres" showOn={BREAKPOINTS.SMALL} />,
      title: 'Profil - Autres',
    } as BottomTabNavigationOptions,
  },
];

export const ProfileTab = createMixedTabBarNavigator({ screens });
