"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SHF = void 0;
// @ts-ignore
var sandbox = global.__DEV__ || global.__STAGING__;
exports.SHF = {
    ssoEndpoint: function (email) {
        var emailParameter = email ? "&email=" + encodeURIComponent(email) : '';
        return sandbox
            ? "https://beta-shf.equideclic.com/fr/compte-shf/?site_location=" + encodeURIComponent('https://europe-west1-equi-reglementation-staging.cloudfunctions.net/integrators-shf-sso') + emailParameter
            : "https://www.shf.eu/fr/compte-shf/?site_location=" + encodeURIComponent('https://europe-west1-outil-auto-diagnostique-cheval.cloudfunctions.net/integrators-shf-sso') + emailParameter;
    },
};
