import React, { ComponentType, LazyExoticComponent, Suspense } from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';

import { withErrorBoundary } from '../../../components/errorBoundary.component';
import { Loader } from '../../ui/components/loader.component';
import { getThemeColor } from '../../ui/utils/theme.utils';

const LoaderWrapper = styled(View)`
  flex: 1;

  background-color: ${getThemeColor('secondary')};

  align-items: center;
  padding-vertical: 50px;
`;

export const withSuspense = (LazyComponent: LazyExoticComponent<ComponentType>) => {
  const ErrorBoundLazyComponent = withErrorBoundary(LazyComponent);

  return props => {
    return (
      <Suspense
        fallback={
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        }
      >
        <ErrorBoundLazyComponent {...props} />
      </Suspense>
    );
  };
};
