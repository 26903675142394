import React from 'react';
import { View, Image } from 'react-native';
import styled, { useTheme } from 'styled-components/native';

import { useBreakpoint } from '../../../ui/utils/theme.utils';
import { Typography } from '../../../ui/components/typography.component';
import { BREAKPOINTS } from '../../../ui/constants/breakpoints';
import { images } from '../../../ui/const/images';

const HEADER_HEIGHT = 64;
const HEADER_TOP_TABS_HEIGHT = 74;
const SUB_HEADER_HEIGHT = 44;

const HeadingWrapper = styled(View)`
  align-items: center;
  justify-content: center;
  padding-horizontal: 10px;
`;

const DescriptionWrapper = styled(View)`
  justify-content: center;
  align-items: center;
`;

const LogoImage = styled(Image)`
  height: ${HEADER_HEIGHT + SUB_HEADER_HEIGHT + HEADER_TOP_TABS_HEIGHT}px;
  width: 100%;

  resize-mode: contain;
`;

const shouldDisplayLogoPerBreakpoints = {
  [BREAKPOINTS.LARGE]: true,
};

export const DrawerHeader = () => {
  const {
    colors: { secondary },
  } = useTheme();

  const shouldDisplayLogo = useBreakpoint(shouldDisplayLogoPerBreakpoints, false);

  return (
    <HeadingWrapper>
      <LogoImage source={images.equipass_full_medium} />

      {!shouldDisplayLogo && (
        <DescriptionWrapper>
          <Typography textAlign="center" fontWeight="600" remoteKey="app_description" color={secondary} />
        </DescriptionWrapper>
      )}
    </HeadingWrapper>
  );
};
