import { Platform } from 'react-native';
import {
  getDocument,
  saveDocument,
  updateDocument,
  getCollection,
  getServerTimestampField,
  onCollectionSnapshot,
  getRemoteFeatureFlag,
  runCloudFunction,
} from '../data/firebase';
import { getDepartments, getUser, isConnected as isConnectedSelector } from './user.selectors';
import { analytics } from '../data/analytics';
import { messagingFetchRemoteMessage } from '../messaging/messaging.actions';
import { fetchWithCache } from '../data/fetch';
import {
  onAuthStateChanged,
  sendPasswordResetEmail,
  signOut,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  updateCurrentUserEmail,
  signInWithCustomToken,
} from '../data/firebase/auth';
import { getToken } from '../data/firebase/messaging';
import { captureException } from '../../utils/sentry';
import { getWebviewRoute } from '../navigation/navigation.utils';

export const USER_SET_PROPERTIES = 'user/SET_PROPERTIES';
export const userSetProperties = () => (dispatch, getState) => {
  const state = getState();

  const isConnected = isConnectedSelector(state);

  if (!isConnected) {
    return;
  }

  const user = getUser(state);

  const departments = getDepartments(state);

  const foundDepartment = departments.find(({ code }) => code === user.department);

  analytics.setUserProperties({
    department: (foundDepartment && foundDepartment.name) || 'none',
  });

  dispatch({ type: USER_SET_PROPERTIES });
};

export const USER_ASK_NOTIFICATIONS_PERMISSION = 'user/ASK_NOTIFICATIONS_PERMISSION';
export const userAskNotificationsPermission = () => async (dispatch, getState) => {
  try {
    const user = getUser(getState());

    const token = await getToken();

    if (token) {
      const { fcm = {} } = user;

      await updateDocument('users', user.id, {
        fcm: {
          [token]: {
            platform: Platform.OS,
            updated: getServerTimestampField(),
            created: fcm[token] ? fcm[token].created : getServerTimestampField(),
          },
        },
      });
    }

    dispatch({ type: USER_ASK_NOTIFICATIONS_PERMISSION });
  } catch (error) {
    // Fail silently
    if (__DEV__) {
      // eslint-disable-next-line
      console.error(error);
    }
  }
};

export const USER_GET_SUBSCRIPTION = 'user/ADD_SUBSCRIPTION';
export const userGetSubscription = subscriptionId => async (dispatch, getState) => {
  const user = getUser(getState());
  const subscription = await getDocument(`users/${user.id}/subscriptions`, subscriptionId);

  return dispatch({
    type: USER_GET_SUBSCRIPTION,
    subscription,
  });
};

export const USER_GET_SUBSCRIPTIONS = 'user/GET_SUBSCRIPTIONS';
export const userGetSubscriptions = () => async (dispatch, getState) => {
  const user = getUser(getState());

  const subscriptions = await getCollection(`users/${user.id}/subscriptions`, {
    where: [['endDate', '>', new Date()]],
  });

  return dispatch({
    type: USER_GET_SUBSCRIPTIONS,
    subscriptions,
  });
};

export const USER_AUTO_LOGIN = 'user/AUTO_LOGIN';
export const userAutoLogin = () => dispatch =>
  new Promise(resolve => {
    let unsubscribe;

    const timeout = setTimeout(() => {
      dispatch({ type: USER_AUTO_LOGIN });

      unsubscribe();
      resolve();
    }, 10000);

    unsubscribe = onAuthStateChanged(async response => {
      let user;

      if (response) {
        user = await getDocument('users', response.uid);
      }

      dispatch({ type: USER_AUTO_LOGIN, user });

      if (user) {
        analytics.setUser(user);
        analytics.event('user_auto_login');

        dispatch(userAskNotificationsPermission());
        dispatch(userGetSubscriptions());
        dispatch(messagingFetchRemoteMessage());
        dispatch(userSetProperties());
      }

      clearTimeout(timeout);

      unsubscribe();
      resolve();
    });
  });

export const USER_FORGOT_PASSWORD = 'user/FORGOT_PASSWORD';
export const userForgotPassword = email => async dispatch => {
  await sendPasswordResetEmail(email);

  analytics.event('user_forgot_password');

  dispatch({ type: USER_FORGOT_PASSWORD });
};

export const USER_SIGN_OUT = 'user/SIGN_OUT';
export const userSignOut = () => async dispatch => {
  await signOut();

  analytics.event('user_sign_out');

  dispatch({ type: USER_SIGN_OUT });

  analytics.setUser(null);
};

export const USER_SIGN_IN = 'user/SIGN_IN';
export const userSignIn = ({ user: { uid } }) => async dispatch => {
  const user = await getDocument('users', uid);

  analytics.setUser(user);
  analytics.event('user_sign_in');

  dispatch({ type: USER_SIGN_IN, user });
  dispatch(userAskNotificationsPermission());
  dispatch(userGetSubscriptions());
  dispatch(messagingFetchRemoteMessage());
};

export const userPasswordSignIn = (email, password) => async dispatch =>
  dispatch(userSignIn(await signInWithEmailAndPassword(email, password)));

export const userCustomSignIn = token => async dispatch => dispatch(userSignIn(await signInWithCustomToken(token)));

export const userSignInFromIntegrator = () => async dispatch => {
  if (Platform.OS !== 'web') {
    return;
  }

  const { search } = window.location;

  if (!search) {
    return;
  }

  const searchParams = new URLSearchParams(window.location.search);
  const customAuthToken = searchParams.get('customAuthToken');

  if (!customAuthToken) {
    return;
  }

  try {
    await dispatch(userCustomSignIn(customAuthToken));
  } catch (error) {
    captureException(error);
  }
};

export const USER_REGISTER = 'user/REGISTER';
export const userRegister = ({ email, password, ...user }) => async dispatch => {
  const {
    user: { uid: id },
  } = await createUserWithEmailAndPassword(email, password);

  const data = { id, email, ...user };

  await saveDocument('users', data);

  analytics.setUser(data);
  analytics.event('user_register');

  dispatch(userAskNotificationsPermission());

  dispatch({ type: USER_REGISTER, user: data });
};

export const USER_EDIT = 'user/EDIT';
export const userEdit = (data = {}) => async (dispatch, getState) => {
  const user = getUser(getState());

  await updateDocument('users', user.id, data);

  analytics.event('user_edit');

  dispatch({ type: USER_EDIT, user: { ...user, ...data } });
  dispatch(userSetProperties());
};

export const USER_EDIT_EMAIL = 'user/EDIT_EMAIL';
export const userEditEmail = email => async (dispatch, getState) => {
  const user = getUser(getState());
  await updateCurrentUserEmail(email);
  await updateDocument('users', user.id, { email });

  analytics.event('user_edit_email');

  dispatch({ type: USER_EDIT_EMAIL, user: { ...user, email } });
};

export const USER_FETCH_EXTRA_INFO = 'user/FETCH_EXTRA_INFO';
export const userFetchExtraInfo = () => async dispatch => {
  dispatch({
    type: USER_FETCH_EXTRA_INFO,
    usersActivityDomains: await getCollection('usersActivityDomains', { orderBy: [['order', 'asc']] }),
    usersJobs: await getCollection('usersJobs', { orderBy: [['order', 'asc']] }),
  });
};

export const USER_FETCH_GEO = 'user/USER_FETCH_GEO';
export const userFetchGeo = () => async dispatch => {
  const { departments } = await fetchWithCache(
    'https://europe-west1-outil-auto-diagnostique-cheval.cloudfunctions.net/geo',
    undefined,
    { key: 'fr/geoZones' },
  );

  dispatch({
    type: USER_FETCH_GEO,
    departments,
  });
};

export const userRealtimeSubscriptions = (dispatch, getState) => {
  const { id } = getUser(getState());

  return onCollectionSnapshot(
    `users/${id}/subscriptions`,
    subscriptions => {
      return dispatch({
        type: USER_GET_SUBSCRIPTIONS,
        subscriptions,
      });
    },
    {
      where: [['endDate', '>', new Date()]],
    },
  );
};

export const USER_REDIRECT_TO_INTEGRATOR = 'user/REDIRECT_TO_INTEGRATOR';
export const userRedirectToIntegrator = (navigation, email) => async dispatch => {
  try {
    if (!getRemoteFeatureFlag('feature_flag_shf')) {
      return false;
    }

    const redirection = await runCloudFunction('integrators-getSignInRedirection', { email });

    if (redirection) {
      dispatch({ type: USER_REDIRECT_TO_INTEGRATOR, redirection });

      analytics.event('integrator_signin_redirection', { redirection });

      if (Platform.OS === 'web') {
        document.location = redirection;
      } else {
        navigation.navigate(...getWebviewRoute(redirection, false));
      }

      return true;
    }
  } catch (error) {
    captureException(error);
  }

  return false;
};
