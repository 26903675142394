import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { colors } from '../../ui/constants/colors';

import { getUser, getUsersJobs, getUsersActivityDomains, getDepartments } from '../user.selectors';
import { userEdit, userEditEmail, userSignOut } from '../user.actions';

import { KeyboardAwareScrollView } from '../../navigation/components/scrollView.component';
import { Title } from '../../ui/components/title.component';
import { Form } from '../../ui/components/form.component';
import { VALIDATION_EMAIL } from '../../ui/constants/validation';
import { getRemoteText } from '../../data/firebase';
import { withErrorBoundary } from '../../../components/errorBoundary.component';
import { Alert } from '../../ui/utils/alert';
import { useBreakpoint } from '../../ui/utils/theme.utils';
import { BREAKPOINTS } from '../../ui/constants/breakpoints';
import { Separator } from '../../ui/components/separator.component';

const fields = [
  {
    name: 'firstName',
    iconName: 'person',
    placeholder: 'Prénom',
    autoCompleteType: 'name',
    textContentType: 'name',
  },
  {
    name: 'lastName',
    iconName: 'people',
    placeholder: 'Nom',
    autoCompleteType: 'name',
    textContentType: 'familyName',
  },
];

const emailFields = [
  {
    name: 'email',
    iconName: 'mail',
    placeholder: 'Email',
    autoCompleteType: 'email',
    autoCapitalize: 'none',
    keyboardType: 'email-address',
    textContentType: 'emailAddress',
    validation: value => VALIDATION_EMAIL.test(value),
    validationWarning: "L'adresse email n'est pas valide",
  },
];

export const ProfileScreen = withErrorBoundary(() => {
  const { firstName, lastName, email, activityDomains = [], jobs = [], department } = useSelector(getUser);
  const usersJobs = useSelector(getUsersJobs);
  const usersActivityDomains = useSelector(getUsersActivityDomains);
  const departments = useSelector(getDepartments);
  const dispatch = useDispatch();

  const editProfile = async values => dispatch(userEdit(values));

  const editEmail = async ({ email: newEmail }) => {
    try {
      await dispatch(userEditEmail(newEmail));
    } catch (error) {
      if (error.code === 'auth/requires-recent-login') {
        Alert.alert(
          "Changement d'adresse email",
          "Pour changer d'adresse e-mail, vous devez vous re-connecter. Merci de revenir sur cet écran après avoir entré vos identifiants",
          [
            {
              text: 'Annuler',
              style: 'cancel',
            },
            { text: 'OK', onPress: () => dispatch(userSignOut()) },
          ],
          { cancelable: false },
        );
      } else if (error.code === 'auth/email-already-in-use') {
        Alert.alert("Changement d'adresse email", 'Cette adresse email est déjà utilisée pour un autre compte');
      } else {
        // Let the form handle the error
        throw error;
      }
    }
  };

  const mapFieldValues = ({ order, id: key, name: label }) => ({ order, key, label });

  const memoizedFields = useMemo(
    () => [
      ...fields,
      {
        name: 'jobs',
        values: usersJobs.map(mapFieldValues),
        multiPicker: true,
        placeholder: getRemoteText('user_job'),
      },
      {
        name: 'activityDomains',
        placeholder: getRemoteText('user_activity_domain'),
        values: usersActivityDomains.map(mapFieldValues),
        multiPicker: true,
      },
      {
        name: 'department',
        placeholder: getRemoteText('user_department'),
        values: departments,
        picker: true,
        height: 200,
      },
    ],
    [fields, usersJobs, usersActivityDomains, departments],
  );

  const shouldDisplayTitle = useBreakpoint({ [BREAKPOINTS.MEDIUM]: false }, true);

  return (
    <KeyboardAwareScrollView withFooter>
      {shouldDisplayTitle && <Title label="Profil" icon="person" />}
      <Form
        foregroundColor={colors.tertiary}
        fields={memoizedFields}
        defaultValues={{ firstName, lastName, jobs, activityDomains, department }}
        actionTitle="Valider les modifications"
        onSubmit={editProfile}
      />
      <Separator />
      <Form
        foregroundColor={colors.tertiary}
        fields={emailFields}
        defaultValues={{ email }}
        actionTitle="Changer d'email"
        onSubmit={editEmail}
      />
    </KeyboardAwareScrollView>
  );
});
