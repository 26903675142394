import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, ButtonPropTypes } from './button.component';

export const FairUseButton = memo(props => {
  const [fairUse, setFairUse] = useState(false);
  const fairUseTimeout = useRef();
  const mounted = useRef(true);

  const { onPress: onButtonPress, timeout = 15000, disabled, id } = props;

  const onPress = useCallback(async () => {
    onButtonPress().catch(() => {
      if (mounted.current) {
        setFairUse(false);
      }
    });

    setFairUse(true);

    fairUseTimeout.current = setTimeout(() => {
      setFairUse(false);
    }, timeout);
  }, [onButtonPress, timeout]);

  useEffect(() => {
    setFairUse(false);

    return () => {
      mounted.current = false;

      clearTimeout(fairUseTimeout.current);
    };
  }, [id]);

  return <Button {...props} onPress={onPress} disabled={disabled || fairUse} />;
});

FairUseButton.propTypes = {
  ...ButtonPropTypes,
  id: PropTypes.string,
  timeout: PropTypes.number,
};
