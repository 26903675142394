import React from 'react';
import PropTypes from 'prop-types';
import Ionicons from 'react-native-vector-icons/Ionicons';
import styled from 'styled-components/native';

import { get } from '../utils/theme.utils';
import { IconProps as NativeIconProps } from 'react-native-vector-icons/Icon';

type IconProps = NativeIconProps & {
  name: string;
  marginRight?: string;
};

const IconWrapper = styled(Ionicons)<IconProps>`
  margin-right: ${get('marginRight', 0)}px;
`;

export const Icon = ({ name, marginRight, ...props }: IconProps) => (
  <IconWrapper marginRight={marginRight} name={name} {...props} />
);

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  marginRight: PropTypes.string,
};
