import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { Provider } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { GestureHandlerRootView } from 'react-native-gesture-handler';

const stripePromise = loadStripe(
  __DEV__ || __STAGING__
    ? 'pk_test_51HDWwkKVjRBbleplYWSZA24tPjbHQ8b4TU4EUP3FNHGfN7V0zVFriPPwpGs2D2QhSNTTQY4at3jNDlTMrmTkDTL000vc6rHz7o'
    : 'pk_live_51HDWwkKVjRBblepl18tLrgcsMgeSPdJjmrWqUQwCyw45JYm2CrM56q3I6FA7p4o0AGdn91GvDCtPq60VrTeFPXcI00w5WReiGg',
);

import App from './app';
import { store } from './store';
import { ThemeProvider } from './modules/ui/components/theme.component';

const GlobalStyle = createGlobalStyle`
  @font-face {
    src: url('/assets/fonts/Ionicons.ttf');
    font-family: Ionicons;
    font-display: block;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: black;
    transition: background-color 5000s ease-in-out 0s;
  }

  * {
    font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  }
`;

export const Root = () => (
  <>
    <GlobalStyle />
    <Provider store={store}>
      <Elements stripe={stripePromise}>
        <ThemeProvider>
          <GestureHandlerRootView style={{ flex: 1 }}>
            <App />
          </GestureHandlerRootView>
        </ThemeProvider>
      </Elements>
    </Provider>
  </>
);

if (module.hot) {
  module.hot.accept();
}
