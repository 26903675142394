import React, { useMemo } from 'react';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { Platform } from 'react-native';

import { DrawerContent } from './components/drawerContent.component';
import { ProfileTab } from './profile.navigator';
import { PassesTab } from './passes.navigator';
import { useBreakpoint } from '../ui/utils/theme.utils';
import { BREAKPOINTS } from '../ui/constants/breakpoints';
import { SurveyNavigator } from './survey.navigator';
import { Header } from './components/header.component';
import { withSuspense } from './utils/withSuspense.utils';
import { CoachingTab } from '../coaching/coaching.navigator';
import { useSelector } from 'react-redux';
import { getFirstSurveyId } from '../survey/survey.selectors';
import { getSurveyTabRoute } from './navigation.utils';

const LabelsScreen = withSuspense(React.lazy(() => import(/* webpackChunkName: "labels" */ '../labels/labels.screen')));

const surveyScreenOptions = { headerShown: false };

const Drawer = createDrawerNavigator();

export const DrawerNavigator = () => {
  const firstSurveyId = useSelector(getFirstSurveyId);
  const permanentDrawer = useBreakpoint(
    {
      [BREAKPOINTS.LARGE]: true,
    },
    false,
  );

  const screenOptions = useMemo(
    () => ({
      drawerType: permanentDrawer ? 'permanent' : Platform.select({ default: 'front', ios: 'slide' }),
      headerShown: true,
      header: props => <Header {...props} />,
    }),
    [permanentDrawer],
  );

  const surveyInitialParams = useMemo(
    () => getSurveyTabRoute({ screen: 'SurveyTabMain', surveyId: firstSurveyId }),
    [firstSurveyId],
  );

  return (
    <Drawer.Navigator
      drawerContent={DrawerContent}
      initialRouteName="Survey"
      screenOptions={screenOptions}
      // Do not use "open" default status as it doesn't work as expected
      defaultStatus="closed"
    >
      <Drawer.Screen
        name="Survey"
        component={SurveyNavigator}
        options={surveyScreenOptions}
        initialParams={surveyInitialParams}
      />
      <Drawer.Screen name="Coaching" component={CoachingTab} options={{ title: 'Mon Coaching' }} />
      <Drawer.Screen name="Labels" component={LabelsScreen} options={{ title: 'Mes Labels' }} />
      <Drawer.Screen name="Profile" component={ProfileTab} options={{ title: 'Mon Profil' }} />
      <Drawer.Screen name="Passes" component={PassesTab} options={{ title: 'Mes Pass' }} />
    </Drawer.Navigator>
  );
};
