import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { SectionList } from 'react-native';
import { EquiReglementation } from '@valotvince/equireglementation-utils';

import { SafeViewList } from '../../navigation/components/scrollView.component';
import { getSurveys } from '../survey.selectors';
import { Title } from '../../ui/components/title.component';
import { CertificateButton } from '../components/certificateButton.component';
import { getRemoteText } from '../../data/firebase';
import { Text } from '../../ui/components/text.component';
import { useBreakpoint } from '../../ui/utils/theme.utils';
import { BREAKPOINTS } from '../../ui/constants/breakpoints';

const ListHeaderComponent = () => <Title remoteKey="passes_certificates_title" centered />;

export const PassesCertificatesScreen = () => {
  const surveys = useSelector(getSurveys);

  const sections = useMemo<{ title: string; data: EquiReglementation.Survey[] }[]>(() => {
    const certifications = Object.keys(surveys)
      .map(surveyId => surveys[surveyId])
      .filter(
        ({ type, preventCertificationProcess }) =>
          type === EquiReglementation.SURVEY_TYPES.CERTIFICATION && !preventCertificationProcess,
      );

    return [
      {
        title: getRemoteText('subscriptions'),
        data: certifications.filter(({ payment }) => payment && payment.enabled),
      },
      {
        title: getRemoteText('eligibility'),
        data: certifications.filter(({ payment }) => !payment || !payment.enabled),
      },
    ];
  }, [surveys]);

  const renderItem = useCallback(
    ({ item: { id: surveyId, name } }) => (
      <CertificateButton
        surveyId={surveyId}
        iconName="download"
        title={name}
        textStyle={{ textAlign: 'center', flex: 1 }}
        wrapperStyle={{ alignContent: 'flex-start', justifyContent: 'flex-start' }}
      />
    ),
    [],
  );
  const renderSectionHeader = useCallback(
    ({ section: { title } }) => <Title label={title} centered separator={false} />,
    [],
  );

  const renderSectionFooter = useCallback(
    ({ section: { data } }) =>
      data.length ? null : <Text remoteKey="passes_no_certificates_available" textAlign="center" />,
    [],
  );

  const ListHeader = useBreakpoint({ [BREAKPOINTS.LARGE]: null }, ListHeaderComponent);

  return (
    <SafeViewList
      withFooter
      component={SectionList}
      sections={sections}
      renderItem={renderItem}
      renderSectionHeader={renderSectionHeader}
      renderSectionFooter={renderSectionFooter}
      ListHeaderComponent={ListHeader}
    />
  );
};
