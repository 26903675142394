import React from 'react';
import { SafeAreaView } from 'react-native';
import styled, { useTheme } from 'styled-components/native';
import { Icon } from '../../ui/components/icon.component';

import { TYPOGRAPHIES, Typography } from '../../ui/components/typography.component';
import { backgroundMixin } from '../../ui/utils/theme.utils';
import { ThemeProvider } from '../../ui/components/theme.component';
import { AppTitle } from '../../ui/components/appTitle.component';

export const StyledSafeAreaView = styled(SafeAreaView)`
  ${backgroundMixin()}

  flex: 1;
  justify-content: center;
  align-content: center;
  align-items: center;
`;

const ErrorMessage = styled(Typography).attrs({ size: TYPOGRAPHIES.XLARGE, textAlign: 'center' })`
  margin-vertical: 20px;
`;

export const NotFoundScreen = () => {
  const {
    colors: { iconFocused },
  } = useTheme();

  return (
    <ThemeProvider dark>
      <StyledSafeAreaView>
        <AppTitle>
          <Icon name="search-outline" size={64} color={iconFocused} />
          <ErrorMessage>La page que vous recherchez est introuvable.</ErrorMessage>
        </AppTitle>
      </StyledSafeAreaView>
    </ThemeProvider>
  );
};
