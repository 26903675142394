import React, { useState, useRef, useEffect } from 'react';
import { TextInput as TextInputRef, View } from 'react-native';
import styled from 'styled-components/native';

import { TextInput } from './textInput.component';
import { Button } from './button.component';
import { colors } from '../constants/colors';
import { DebugText } from '../../../components/debugText.component';
import { Message, MessageTypes } from './message.component';
import { Picker } from './picker.component';
import { MultiPicker } from './multiPicker.component';

const FormWrapper = styled(View)`
  width: 100%;
  min-width: 300px;
`;

const FormButton = styled(Button)`
  margin-top: 20px;
`;

interface FormField {
  name: string;

  picker: boolean;
  multiPicker: boolean;

  defaultHidden?: boolean;
  defaultValue?: string;

  beforeNextStep?: (value: any) => boolean | Promise<boolean>;
  nextSteps?: string[];

  validation?: (value: any) => boolean;
  validationWarning?: string;
}

interface FormProps {
  fields: FormField[];
  actionTitle: string;
  completeMessage: string;
  onSubmit: (values: Record<string, any>) => any;
  defaultValues: Record<string, any>;
  getErrorMessage: (error: any) => string;
  resetFieldsOnComplete: boolean;

  testID?: string;
}

export const Form = ({
  fields,
  actionTitle,
  completeMessage,
  onSubmit,
  getErrorMessage,
  resetFieldsOnComplete,
  defaultValues,
  testID,
}: FormProps) => {
  const [values, setValues] = useState(defaultValues);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const [complete, setComplete] = useState(false);
  const [warns, setWarns] = useState<Record<string, boolean>>({});
  const [showDefaultHiddens, setShowDefaultHiddens] = useState<Record<string, any>>({});
  const inputRefs = useRef<TextInputRef[]>([]);
  const isMounted = useRef(true);

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    [],
  );

  const updateShowDefaultHiddens = (nextSteps: string[], shouldStepNext: boolean, resetLatest?: boolean) =>
    setShowDefaultHiddens(defaultHiddens => ({
      ...defaultHiddens,
      // Always focus the first nextStep
      latest: resetLatest ? false : nextSteps[0],
      ...nextSteps.reduce((accumulator, nextStep) => ({ ...accumulator, [nextStep]: shouldStepNext }), {}),
    }));

  const runBeforeNextStep = async ({ beforeNextStep, name, nextSteps }: FormField) => {
    if (!beforeNextStep || !nextSteps || !nextSteps.length) {
      return;
    }

    setLoading(true);

    const shouldStepNext = await beforeNextStep(values[name]);

    updateShowDefaultHiddens(nextSteps, shouldStepNext);

    setLoading(false);
  };

  const onPress = async () => {
    setLoading(true);
    setError(false);
    setComplete(false);

    const fieldWithNonCompletedNextStep = fields.find(
      ({ nextSteps, beforeNextStep }) =>
        nextSteps && nextSteps.length && beforeNextStep && nextSteps.find(nextStep => !showDefaultHiddens[nextStep]),
    );

    if (fieldWithNonCompletedNextStep) {
      await runBeforeNextStep(fieldWithNonCompletedNextStep);

      return;
    }

    try {
      // Delay a little bit to have the loader to show even if the real action took 50ms to complete
      await Promise.all([onSubmit(values), new Promise(resolve => setTimeout(resolve, 1000))]);

      if (!isMounted.current) {
        return;
      }

      if (resetFieldsOnComplete) {
        setValues({});
      }

      setComplete(true);
    } catch (caught) {
      setError(caught);
    }

    setLoading(false);
  };

  const hasValuesMoved = () => {
    const defaultValuesKeys = Object.keys(defaultValues);

    if (!defaultValuesKeys.length) {
      return true;
    }

    return !!defaultValuesKeys.filter(defaultValueKey => {
      const defaultValue = defaultValues[defaultValueKey] || '';

      const value = values[defaultValueKey];

      if (Array.isArray(value)) {
        return value.length !== defaultValue.length || !value.every(subValue => defaultValue.includes(subValue));
      }

      return values[defaultValueKey] !== defaultValue;
    }).length;
  };

  const isFieldValid = ({ name, validation }: FormField) => {
    const value = values[name] || '';

    return value.length && (!validation || validation(value));
  };

  const isFormValid = () => {
    const invalidField = fields.find(field => {
      // Fake valid form to get through to the next step.
      if (field.defaultHidden && !showDefaultHiddens[field.name]) {
        return false;
      }

      return !isFieldValid(field);
    });

    return !invalidField;
  };

  return (
    <FormWrapper testID={testID}>
      {fields
        .filter(({ name, defaultHidden }) => !defaultHidden || (defaultHidden && showDefaultHiddens[name]))
        .map((field, index) => {
          const {
            validation,
            validationWarning,
            name,
            picker,
            multiPicker,
            defaultValue = '',
            beforeNextStep,
            nextSteps,
            ...rest
          } = field;
          const hasWarn = warns[name];
          const hasNextStep = nextSteps && nextSteps.length && beforeNextStep;

          const onValueChange = (value: any) => {
            setValues(previousValues => ({ ...previousValues, [name]: value }));

            if (hasNextStep) {
              updateShowDefaultHiddens(nextSteps, false, true);
            }
          };

          const setRef = (ref: TextInputRef) => {
            inputRefs.current[index] = ref;

            // Focus the newly created ref because of the previous step being completed
            if (showDefaultHiddens.latest === name && ref && ref.focus) {
              // Set the latest to null because we don't want to focus it again at next render
              setShowDefaultHiddens(defaultHiddens => ({ ...defaultHiddens, latest: null }));

              ref.focus();
            }
          };

          const onSubmitEditing = async () => {
            if (hasNextStep) {
              await runBeforeNextStep(field);
            }

            const nextRef = inputRefs.current[index + 1];

            if (nextRef && nextRef.focus) {
              nextRef.focus();
            }
          };

          if (picker) {
            return (
              <Picker
                key={name}
                onValueChange={onValueChange}
                onSubmitEditing={onSubmitEditing}
                ref={setRef}
                value={values[name] || defaultValue}
                style={{ marginTop: 20 }}
                {...rest}
              />
            );
          }

          if (multiPicker) {
            return (
              <MultiPicker
                key={name}
                onValueChange={onValueChange}
                onSubmitEditing={onSubmitEditing}
                // ref={setRef}
                value={values[name] || defaultValue}
                {...rest}
              />
            );
          }

          return (
            <TextInput
              key={name}
              onChangeText={onValueChange}
              color={hasWarn ? colors.red : undefined}
              onFocus={hasWarn ? () => setWarns(previousWarns => ({ ...previousWarns, [name]: false })) : undefined}
              onBlur={() => {
                if (!isFieldValid(field)) {
                  setWarns(previousWarns => ({ ...previousWarns, [name]: true }));
                }
              }}
              onSubmitEditing={onSubmitEditing}
              ref={setRef}
              value={values[name] || ''}
              testID={`${testID}-${name}`}
              {...rest}
            />
          );
        })}
      {complete && completeMessage && <Message text={completeMessage} centered />}
      {error && (
        <>
          <Message text={`Une erreur est survenue ! ${getErrorMessage(error)}`} type={MessageTypes.ERROR} centered />
          {__DEV__ ? (
            <DebugText>
              {error.code}: {error.message}
              {error.stack}
            </DebugText>
          ) : null}
        </>
      )}
      <FormButton
        disabled={loading || !isFormValid() || !hasValuesMoved()}
        title={actionTitle}
        iconName={loading ? 'loading' : 'send'}
        onPress={onPress}
        testID={`${testID}-submit`}
      />
    </FormWrapper>
  );
};

Form.defaultProps = {
  getErrorMessage: () => '',
  resetFieldsOnComplete: false,
  defaultValues: {},
};
