import { analytics } from '../data/analytics';
import { getCollection, updateDocument } from '../data/firebase';
import { getUser } from '../user/user.selectors';
import { remoteMessageToNotification } from './messaging.utils';

export const MESSAGING_FETCH_REMOTE_MESSAGES = 'messaging/FETCH_REMOTE_MESSAGES';
export const messagingFetchRemoteMessage = () => async (dispatch, getState) => {
  const user = getUser(getState());

  if (!user) {
    return;
  }

  dispatch({
    type: MESSAGING_FETCH_REMOTE_MESSAGES,
    messages: await getCollection(`users/${user.id}/notifications`, { orderBy: [['sent', 'desc']], limit: [[20]] }),
  });
};

export const MESSAGING_STORE_REMOTE_MESSAGE = 'messaging/STORE_REMOTE_MESSAGE';
export const messagingStoreRemoteMessage = remoteMessage => dispatch => {
  dispatch({
    type: MESSAGING_STORE_REMOTE_MESSAGE,
    message: remoteMessageToNotification(remoteMessage),
  });
};

export const MESSAGING_READ_REMOTE_MESSAGE = 'messaging/READ_REMOTE_MESSAGE';
export const messagingReadRemoteMessage = remoteMessage => async (dispatch, getState) => {
  const user = getUser(getState());

  if (!user || !remoteMessage.data) {
    return;
  }

  const message = remoteMessageToNotification(remoteMessage);

  analytics.event('notification_read');

  const [notification] = await getCollection(`users/${user.id}/notifications`, {
    where: [['data.originalNotificationId', '==', message.data.originalNotificationId]],
    limit: [[1]],
  });

  if (notification) {
    await updateDocument(`users/${user.id}/notifications`, notification.id, { read: true });
  }

  dispatch({ type: MESSAGING_READ_REMOTE_MESSAGE, message: notification || message });
};
