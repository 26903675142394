import React, { memo, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Image, Animated, Platform } from 'react-native';
import styled from 'styled-components/native';
import { SafeAreaView } from 'react-native-safe-area-context';

import { Loader } from './loader.component';
import { Button } from './button.component';
import { Icon } from './icon.component';
import { images } from '../const/images';
import { TYPOGRAPHIES, Typography } from './typography.component';

const ErrorMessage = styled(Typography).attrs({ size: TYPOGRAPHIES.LARGE, textAlign: 'center', color: 'white' })`
  margin-vertical: 20px;
`;

export const StyledSafeAreaView = styled(SafeAreaView)`
  background-color: #20441f;

  flex: 1;
  padding-vertical: 30px;
  padding-horizontal: 30px;
  justify-content: center;
  align-content: center;
  align-items: center;
`;

export const SplashScreen = memo(({ onRetry, error, visible }) => {
  const opacity = useRef(new Animated.Value(1)).current;
  const [animationDone, setAnimationDone] = useState(false);

  useEffect(() => {
    if (!error) {
      setAnimationDone(false);

      const animation = Animated.timing(opacity, {
        toValue: visible ? 1 : 0,
        duration: 500,
        useNativeDriver: Platform.select({ web: false, native: true }),
      });

      animation.start(() => {
        setAnimationDone(true);
      });

      return () => {
        animation.stop();
        opacity.stopAnimation();
      };
    }

    return undefined;
  }, [visible, error]);

  if (!visible && animationDone && !error) {
    return null;
  }

  return (
    <Animated.View
      style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        opacity,
      }}
    >
      <StyledSafeAreaView>
        {error ? (
          <>
            <Icon name="bug" size={64} color="white" />
            <ErrorMessage>
              Une erreur est survenue. Merci de bien vouloir relancer l'application ultérieurement
            </ErrorMessage>
            <Button title="Ré-essayer" onPress={onRetry} />
            {__DEV__ ? <Typography color="white">{error.message}</Typography> : null}
          </>
        ) : (
          <>
            <Image
              source={images.equipass_splash}
              style={{ width: '75%', maxWidth: 512, height: undefined, aspectRatio: 260 / 308 }}
            />
            {visible && <Loader size={32} color="white" style={{ position: 'absolute', bottom: '15%' }} />}
          </>
        )}
      </StyledSafeAreaView>
    </Animated.View>
  );
});

SplashScreen.propTypes = {
  onRetry: PropTypes.func.isRequired,
  error: PropTypes.object,
  visible: PropTypes.bool,
};
