import AsyncStorage from '@react-native-async-storage/async-storage';
import { getUniqueId } from 'react-native-device-info';

import { gdprPurposes } from './gdpr.constants';
import { saveDocument, updateDocument } from '../data/firebase';
import { analytics } from '../data/analytics';
import { getGdprConsents } from './gdpr.consents';
import { getUser } from '../user/user.selectors';

const getGdprSafeConsents = value =>
  gdprPurposes.reduce((accumulator, key) => ({ ...accumulator, [key]: !!value }), {});

export const GDPR_INIT = 'gdpr/INIT';
export const gdprInit = () => async dispatch => {
  try {
    const storedGdpr = await AsyncStorage.getItem('gdpr');

    if (storedGdpr) {
      const parsedGdpr = JSON.parse(storedGdpr);

      if (parsedGdpr.hasUserInteracted && parsedGdpr.consents.tracking) {
        analytics.init();
      }

      dispatch({
        type: GDPR_INIT,
        ...parsedGdpr,
      });
    }
  } catch {
    dispatch({
      type: GDPR_INIT,
    });
  }
};

export const GDPR_ACCEPT_ALL = 'gdpr/ACCEPT_ALL';
export const gdprAcceptAll = () => async dispatch => {
  const hasUserInteracted = Date.now() / 1000;
  const consents = getGdprSafeConsents(true);

  analytics.init();

  AsyncStorage.setItem('gdpr', JSON.stringify({ hasUserInteracted, consents }));

  analytics.event('gdpr_accept_all');

  saveDocument('gdprConsents', {
    id: await getUniqueId(),
    ...consents,
  });

  return dispatch({
    type: GDPR_ACCEPT_ALL,
    hasUserInteracted,
    consents,
  });
};

export const GDPR_DENY_ALL = 'gdpr/DENY_ALL';
export const gdprDenyAll = () => async dispatch => {
  const hasUserInteracted = Date.now() / 1000;
  const consents = getGdprSafeConsents(false);

  analytics.event('gdpr_deny_all');

  analytics.release();

  AsyncStorage.setItem('gdpr', JSON.stringify({ hasUserInteracted, consents }));

  saveDocument('gdprConsents', {
    id: await getUniqueId(),
    ...consents,
  });

  return dispatch({
    type: GDPR_DENY_ALL,
    hasUserInteracted,
    consents,
  });
};

export const GDPR_TOGGLE = 'gdpr/TOGGLE';
export const gdprToggle = (key, value) => async (dispatch, getState) => {
  const state = getState();
  const hasUserInteracted = Date.now() / 1000;
  const consents = {
    ...getGdprConsents(getState()),
    [key]: value,
  };

  if (key === 'tracking') {
    if (value) {
      analytics.init();
      analytics.setUser(getUser(state));
    } else {
      analytics.release();
    }
  }

  analytics.event(`gdpr_${value ? 'accept' : 'deny'}`, { consentType: key });

  AsyncStorage.setItem('gdpr', JSON.stringify({ hasUserInteracted, consents }));

  updateDocument('gdprConsents', await getUniqueId(), {
    ...consents,
  });

  return dispatch({
    type: GDPR_TOGGLE,
    hasUserInteracted,
    consents,
  });
};

export const GDPR_RESET = 'gdpr/RESET';
export const gdprReset = () => {
  analytics.event('gdpr_reset');
  analytics.release();

  AsyncStorage.removeItem('gdpr');

  return {
    type: GDPR_RESET,
  };
};
