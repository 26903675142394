import { Platform } from 'react-native';
import { useRef, useState, useEffect } from 'react';
import { EquiReglementation } from '@valotvince/equireglementation-utils';

import { runCloudFunction } from '../../data/firebase';

export const useSubscriptionManagementLink = (subscription?: EquiReglementation.Database.Subscription) => {
  const mounted = useRef(true);
  const [billingPortalLink, setBillinkPortalLink] = useState(null);

  const productId = subscription && subscription[Platform.OS] && subscription[Platform.OS]!.productId;

  useEffect(() => {
    if (productId && Platform.OS === 'web') {
      runCloudFunction('stripe-createPortalLink', { returnUrl: window.location.href }).then(({ url }) => {
        if (mounted.current) {
          setBillinkPortalLink(url);
        }
      });
    }
  }, [productId]);

  useEffect(
    () => () => {
      mounted.current = false;
    },
    [],
  );

  if (Platform.OS !== 'web') {
    return Platform.select({
      ios: 'https://apps.apple.com/account/subscriptions',
      android: `https://play.google.com/store/account/subscriptions?package=fr.fcc.qualicheval${
        productId ? `&sku=${productId}` : ''
      }`,
    });
  }

  return billingPortalLink;
};
