import { initializeApp } from 'firebase/app';

export const firebaseApp = initializeApp(
  __DEV__ || __STAGING__
    ? {
        apiKey: 'AIzaSyBHVD20lukPS5asvhvJ4U4rLddYPERUhbY',
        authDomain: 'equi-reglementation-staging.firebaseapp.com',
        databaseURL: 'https://equi-reglementation-staging.firebaseio.com',
        projectId: 'equi-reglementation-staging',
        storageBucket: 'equi-reglementation-staging.appspot.com',
        messagingSenderId: '488368536610',
        appId: '1:488368536610:web:a090d962a783684cba9e7b',
        measurementId: 'G-X07L0QFSG8',
      }
    : {
        apiKey: 'AIzaSyCa6XWniP9IuAiEPGTZyitvmTdA8YCWehU',
        authDomain: 'outil-auto-diagnostique-cheval.firebaseapp.com',
        databaseURL: 'https://outil-auto-diagnostique-cheval.firebaseio.com',
        projectId: 'outil-auto-diagnostique-cheval',
        storageBucket: 'outil-auto-diagnostique-cheval.appspot.com',
        messagingSenderId: '142578479802',
        appId: '1:142578479802:web:21b0b8fa351820f4',
        measurementId: 'G-MK2VTNEV9E',
      },
);
