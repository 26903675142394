import {
  getCollection,
  saveDocument,
  deleteDocument,
  firestore,
  updateDocument,
  getServerTimestampField,
} from '../data/firebase';
import { getUser } from '../user/user.selectors';

export const SURVEY_LOAD_ANSWERS = 'survey/LOAD_ANSWERS';
export const surveyLoadAnswers = () => async (dispatch, getState) => {
  const { user } = getState();

  if (user && user.id) {
    const answers = await getCollection(`users/${user.id}/answers`);

    if (!answers.length) {
      return Promise.resolve();
    }

    return dispatch({
      type: SURVEY_LOAD_ANSWERS,
      answers,
    });
  }

  return Promise.resolve();
};

export const SURVEY_FETCH = 'survey/FETCH';
export const surveyFetch = () => async dispatch => {
  const surveys = await getCollection('surveys', {
    where: [['display', '!=', 'draft']],
    orderBy: [
      ['display', 'asc'],
      ['order', 'asc'],
    ],
  });

  if (!surveys.length) {
    return [];
  }

  dispatch({
    type: SURVEY_FETCH,
    surveys,
  });

  return surveys;
};

export const SURVEY_FETCH_CATEGORIES = 'survey/FETCH_CATEGORIES';
export const surveyFetchCategories = surveyId => async dispatch => {
  const categories = await getCollection(`surveys/${surveyId}/categories`, { orderBy: [['order', 'asc']] });

  if (!categories.length) {
    return [];
  }

  dispatch({
    type: SURVEY_FETCH_CATEGORIES,
    categories,
    surveyId,
  });

  return categories;
};

export const SURVEY_FETCH_QUESTIONS = 'survey/FETCH_QUESTIONS';
export const surveyFetchQuestions = (surveyId, categoryId) => async dispatch => {
  const questions = await getCollection(`surveys/${surveyId}/categories/${categoryId}/questions`, {
    orderBy: [['order', 'asc']],
  });

  dispatch({
    type: SURVEY_FETCH_QUESTIONS,
    categoryId,
    questions,
    surveyId,
  });

  return questions;
};

export const surveyFetchData = surveyId => async dispatch => {
  const categories = await dispatch(surveyFetchCategories(surveyId));

  await Promise.all(categories.map(({ id }) => dispatch(surveyFetchQuestions(surveyId, id))));

  await dispatch(surveyLoadAnswers());

  return dispatch({
    type: 'survey/FETCH_DATA',
  });
};

export const SURVEY_ANSWER_QUESTION = 'survey/ANSWER_QUESTION';
export const surveyAnswerQuestion =
  ({ surveyId, categoryId, questionId, givenAnswer }) =>
  async (dispatch, getState) => {
    dispatch({
      type: SURVEY_ANSWER_QUESTION,
      surveyId,
      categoryId,
      questionId,
      givenAnswer,
    });

    const { user } = getState();

    if (givenAnswer) {
      saveDocument(`users/${user.id}/answers`, { id: questionId, ...givenAnswer });
    } else {
      deleteDocument(`users/${user.id}/answers`, questionId);
    }

    updateDocument('users', user.id, {
      metadata: {
        surveys: {
          [surveyId]: {
            lastEdit: getServerTimestampField(),
            categories: {
              [categoryId]: {
                lastEdit: getServerTimestampField(),
              },
            },
          },
        },
      },
    });
  };

export const SURVEY_STORE_LAST_VISIT = 'survey/STORE_LAST_VISIT';
export const surveyStoreLastVisit = surveyId => (dispatch, getState) => {
  const user = getUser(getState());

  updateDocument('users', user.id, {
    metadata: {
      surveys: {
        [surveyId]: {
          lastVisit: getServerTimestampField(),
        },
      },
    },
  });

  dispatch({ type: SURVEY_STORE_LAST_VISIT, surveyId });
};

export const SURVEY_STORE_LAST_CATEGORY_VISIT = 'survey/STORE_LAST_CATEGORY_VISIT';
export const surveyStoreLastCategoryVisit = (surveyId, categoryId) => (dispatch, getState) => {
  const user = getUser(getState());

  updateDocument('users', user.id, {
    metadata: {
      surveys: {
        [surveyId]: {
          lastVisit: getServerTimestampField(),
          categories: {
            [categoryId]: {
              lastVisit: getServerTimestampField(),
            },
          },
        },
      },
    },
  });

  dispatch({ type: SURVEY_STORE_LAST_CATEGORY_VISIT, surveyId, categoryId });
};

export const SURVEY_RESET = 'survey/RESET';
export const surveyReset = () => async (dispatch, getState) => {
  const { user } = getState();

  const batch = firestore().batch();

  const snapshot = await firestore().collection(`users/${user.id}/answers`).get();

  snapshot.forEach(document => batch.delete(document.ref));

  await batch.commit();

  return dispatch({ type: SURVEY_RESET });
};
