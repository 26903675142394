"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.prepareSurveyData = exports.prepareSurveyCategoryData = exports.areConditionsMet = exports.getPreAnsweredCondition = exports.getColorFromValue = void 0;
var types_1 = require("./types");
var colors_1 = require("./colors");
var getColorFromValue = function (value) {
    switch (String(value)) {
        case '1':
            return colors_1.colors.green;
        case '0.75':
            return colors_1.colors.orangeGreen;
        case '0.5':
            return colors_1.colors.orange;
        case '0.25':
            return colors_1.colors.orangeRed;
        case '0':
            return colors_1.colors.red;
        case '-1':
            return colors_1.colors.gray;
        default:
            return '#FFFFFF';
    }
};
exports.getColorFromValue = getColorFromValue;
var getColorFromBounds = function (value) {
    if (value < 0) {
        return colors_1.colors.gray;
    }
    if (value <= 0.125) {
        return colors_1.colors.red;
    }
    if (value <= 0.375) {
        return colors_1.colors.orangeRed;
    }
    if (value <= 0.625) {
        return colors_1.colors.orange;
    }
    if (value <= 0.875) {
        return colors_1.colors.orangeGreen;
    }
    return colors_1.colors.green;
};
/**
 * Gives back the questions that should count in results computations
 * Removes questions with answer having -1 value (informative or not to be taken into account)
 */
var getAccountableQuestions = function (questions) {
    return questions.filter(function (_a) {
        var informative = _a.informative, givenAnswer = _a.givenAnswer;
        return !informative && givenAnswer.value >= 0;
    });
};
/**
 * Returns a validRate from 0 to 100.
 * Be sure to call this method with "accountable" questions as to have the correct valid rate value
 */
var getValidRate = function (questions) {
    if (questions.length) {
        var rate = (questions.reduce(function (accumulator, _a) {
            var givenAnswer = _a.givenAnswer;
            return accumulator + givenAnswer.value;
        }, 0) /
            questions.length) *
            100;
        return Math.round(rate * 100) / 100;
    }
    return undefined;
};
var getPreAnsweredCondition = function (conditions) {
    if (conditions === void 0) { conditions = []; }
    var conditionsOfType = conditions.filter(function (_a) {
        var type = _a.type;
        return type === types_1.EquiReglementation.SURVEY_CONDITION_TYPES.PRE_ANSWERED;
    });
    return conditionsOfType.find(function (_a) {
        var met = _a.met;
        return met;
    });
};
exports.getPreAnsweredCondition = getPreAnsweredCondition;
var areConditionsMet = function (conditionType, conditions, strict) {
    if (conditions === void 0) { conditions = []; }
    if (strict === void 0) { strict = false; }
    var conditionsOfType = conditions.filter(function (_a) {
        var type = _a.type;
        return type === conditionType;
    });
    if (!conditionsOfType.length) {
        return !strict;
    }
    return !conditionsOfType.find(function (_a) {
        var met = _a.met;
        return !met;
    });
};
exports.areConditionsMet = areConditionsMet;
var meetQuestionConditions = function (question, answers) {
    var conditions = question.conditions || [];
    return __assign(__assign({}, question), { conditions: conditions.map(function (condition) {
            var answer = answers[condition.question];
            // No response on that one, no need to go further
            if (!answer) {
                return __assign(__assign({}, condition), { met: false });
            }
            return __assign(__assign({}, condition), { met: answer && answer.value === condition.givenAnswer.value });
        }) });
};
var meetQuestionsConditions = function (questions, answers) { return questions.map(function (question) { return meetQuestionConditions(question, answers); }); };
var prepareSurveyQuestionsData = function (survey, questions, answers) {
    return meetQuestionsConditions(questions, answers).map(function (_a) {
        var id = _a.id, informative = _a.informative, conditions = _a.conditions, question = __rest(_a, ["id", "informative", "conditions"]);
        var _b = question.answers, values = _b.values, minValueToReach = _b.minValueToReach;
        var givenUserAnswer = answers[id];
        var givenAnswer = givenUserAnswer && values.find(function (answer) { return answer.value === givenUserAnswer.value; });
        var resultColor;
        var score = 0;
        var minScore = 0;
        var maxScore = 0;
        var value = 0;
        var filled = !!givenAnswer;
        var minAnswerToReach = values.find(function (answer) { return answer.value === minValueToReach; });
        var highestAnswer = __spreadArray([], values).sort(function (a, b) { return (b.score || 0) - (a.score || 0); })[0];
        var isCertificationSurvey = survey.type === types_1.EquiReglementation.SURVEY_TYPES.CERTIFICATION;
        if (isCertificationSurvey) {
            minScore = (minAnswerToReach && minAnswerToReach.score) || 0;
            maxScore = (highestAnswer && highestAnswer.score) || 0;
        }
        else {
            // For review surveys, we need to have to highest value to be certified
            minScore = 1;
            // For review surveys, we always max out to 1
            maxScore = 1;
        }
        if (!informative && givenAnswer) {
            resultColor = exports.getColorFromValue(givenAnswer.value);
            value = givenAnswer.value;
            if (isCertificationSurvey) {
                score = givenAnswer.score || 0;
            }
            else {
                score = value;
            }
        }
        // Do not pre-answer question if the user answered by himself
        if (!givenAnswer) {
            var preAnsweredCondition = exports.getPreAnsweredCondition(conditions);
            if (preAnsweredCondition && preAnsweredCondition.targetGivenAnswer) {
                var _c = preAnsweredCondition.targetGivenAnswer, _d = _c.value, targetValue = _d === void 0 ? 0 : _d, _e = _c.score, targetScore = _e === void 0 ? 0 : _e;
                resultColor = exports.getColorFromValue(targetValue);
                value = targetValue;
                if (isCertificationSurvey) {
                    score = targetScore;
                }
                else {
                    score = targetValue;
                }
                filled = true;
            }
            else if (exports.areConditionsMet(types_1.EquiReglementation.SURVEY_CONDITION_TYPES.ANSWERED_LEGACY, conditions, true)) {
                resultColor = colors_1.colors.green;
                score = maxScore;
                value = 1;
                filled = true;
            }
        }
        if (
        // either it was an informative question
        (informative && givenAnswer) ||
            // either it wasn't displayed because the condition wasn't met
            !exports.areConditionsMet(types_1.EquiReglementation.SURVEY_CONDITION_TYPES.SHOW, conditions) ||
            // either we responsed to the question
            (givenAnswer && givenAnswer.value === -1)) {
            resultColor = exports.getColorFromValue(-1);
            value = -1;
        }
        return __assign(__assign({}, question), { id: id,
            conditions: conditions,
            informative: informative,
            filled: filled, certification: {
                minScore: minScore,
                maxScore: maxScore,
                certified: score >= minScore || value === -1,
                score: score,
            }, givenAnswer: {
                label: (givenAnswer && givenAnswer.label) || '',
                value: value,
                score: score,
            }, resultColor: resultColor });
    });
};
var prepareSurveyCategoryData = function (survey, category, answers) {
    var questions = prepareSurveyQuestionsData(survey, category.questions, answers);
    var accountableQuestions = getAccountableQuestions(questions);
    var validRate = getValidRate(accountableQuestions);
    var _a = accountableQuestions.reduce(function (accumulator, _a) {
        var filled = _a.filled, certification = _a.certification;
        return ({
            score: accumulator.score + certification.score,
            minScore: accumulator.minScore + certification.minScore,
            maxScore: accumulator.maxScore + certification.maxScore,
            filled: accumulator.filled + (filled ? 1 : 0),
        });
    }, { minScore: 0, maxScore: 0, score: 0, filled: 0 }), score = _a.score, minScoreWithQuestions = _a.minScore, maxScore = _a.maxScore, filled = _a.filled;
    var minScore = (category.certification && category.certification.minScore) || minScoreWithQuestions;
    var fillRatio = (filled / accountableQuestions.length) * 100;
    return __assign(__assign({}, category), { color: category.color || colors_1.colors.tertiary, questions: questions, fillRatio: Math.round(fillRatio * 100) / 100, successRatio: validRate || 0, certification: {
            minScore: minScore,
            maxScore: maxScore,
            certified: score >= minScore && !accountableQuestions.find(function (_a) {
                var certified = _a.certification.certified;
                return !certified;
            }),
            score: score,
        }, validRate: validRate, answered: questions.filter(function (_a) {
            var id = _a.id;
            return !!answers[id];
        }).length, resultColor: validRate ? getColorFromBounds(validRate / 100) : colors_1.colors.red });
};
exports.prepareSurveyCategoryData = prepareSurveyCategoryData;
var prepareSurveyData = function (survey, answers) {
    if (answers === void 0) { answers = {}; }
    var categories = survey.categories.map(function (category) { return exports.prepareSurveyCategoryData(survey, category, answers); });
    var everyQuestions = categories.reduce(function (accumulator, _a) {
        var questions = _a.questions;
        return __spreadArray(__spreadArray([], accumulator), questions);
    }, []);
    var accountableQuestions = getAccountableQuestions(everyQuestions);
    var questionsFilled = accountableQuestions.filter(function (_a) {
        var filled = _a.filled;
        return filled;
    }).length;
    var fillRatio = (questionsFilled / accountableQuestions.length) * 100;
    return __assign(__assign({}, survey), { fillRatio: Math.round(fillRatio * 100) / 100, successRatio: getValidRate(accountableQuestions) || 0, certification: {
            certified: !categories.find(function (category) { return !category.certification.certified; }),
            score: categories.reduce(function (acc, category) { return acc + category.certification.score; }, 0),
        }, categories: categories });
};
exports.prepareSurveyData = prepareSurveyData;
