import {
  USER_AUTO_LOGIN,
  USER_SIGN_IN,
  USER_SIGN_OUT,
  USER_REGISTER,
  USER_EDIT,
  USER_EDIT_EMAIL,
  USER_GET_SUBSCRIPTIONS,
  USER_FETCH_EXTRA_INFO,
  USER_FETCH_GEO,
  USER_GET_SUBSCRIPTION,
} from './user.actions';

const initialState = {
  id: null,
  email: null,
  lastname: null,
  firstname: null,
  autoLoginDone: false,
  subscriptions: [],
  usersActivityDomains: [],
  usersJobs: [],
  departments: [],
  integrators: {},
};

export const user = (state = initialState, action) => {
  switch (action.type) {
    case USER_AUTO_LOGIN:
      return {
        ...initialState,
        autoLoginDone: true,
        ...action.user,
        usersJobs: state.usersJobs || initialState.usersJobs,
        usersActivityDomains: state.usersActivityDomains || initialState.usersActivityDomains,
        departments: state.departments || initialState.departments,
      };

    case USER_SIGN_OUT:
      return {
        ...state,
        id: null,
        email: null,
        lastname: null,
        firstname: null,
        autoLoginDone: true,
        jobs: [],
        activityDomains: [],
        integrators: {},
        department: null,
      };

    case USER_REGISTER:
    case USER_SIGN_IN:
    case USER_EDIT:
    case USER_EDIT_EMAIL:
      return { ...state, ...action.user };

    case USER_GET_SUBSCRIPTIONS: {
      const { subscriptions = [] } = action;

      return {
        ...state,
        subscriptions,
      };
    }

    case USER_GET_SUBSCRIPTION: {
      const { subscription } = action;

      return {
        ...state,
        subscriptions: [...state.subscriptions, subscription],
      };
    }

    case USER_FETCH_EXTRA_INFO: {
      const { usersJobs, usersActivityDomains } = action;

      return {
        ...state,
        usersJobs,
        usersActivityDomains,
      };
    }

    case USER_FETCH_GEO: {
      const { departments } = action;

      return {
        ...state,
        departments,
      };
    }

    default:
      return state;
  }
};
