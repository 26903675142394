import { EquiReglementation } from '@valotvince/equireglementation-utils';
import { ROUTE_NAMES } from '../navigation/const/linking';
import { getLinkTo } from '../navigation/utils/getLinkTo.utils';

export const remoteMessageToNotification = remoteMessage => ({
  id: remoteMessage.id || remoteMessage.messageId,
  ...remoteMessage,
  ...remoteMessage.notification,
  data: remoteMessage.data,
  sent: remoteMessage.sent || { seconds: Math.round(Date.now() / 1000) },
});

export const messageToIconName = ({ data }) => {
  switch (data && data.target) {
    case 'survey':
      return 'ribbon-outline';
    case 'link':
      return 'open-outline';
    case 'coaching':
      return 'people-outline';

    default:
      return 'notifications-outline';
  }
};

export const messageToLinkProps = ({ data }) => {
  switch (data && data.target) {
    case EquiReglementation.NOTIFICATION_TARGET_TYPES.SURVEY:
      return {
        to: getLinkTo(ROUTE_NAMES.SURVEY, { surveyId: data.target_survey }),
      };

    case EquiReglementation.NOTIFICATION_TARGET_TYPES.LINK:
      return {
        to: data.target_link,
        hrefAttrs: { target: '_blank' },
      };

    case EquiReglementation.NOTIFICATION_TARGET_TYPES.COACHING:
      return {
        to: getLinkTo(ROUTE_NAMES.COACHING),
      };

    case EquiReglementation.NOTIFICATION_TARGET_TYPES.PASSES:
      return {
        to: getLinkTo(ROUTE_NAMES.USER_PASSES),
      };

    default:
      break;
  }
};
