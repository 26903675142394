import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components/native';
import { Linking, Platform, View } from 'react-native';

import { getSubscriptions } from '../user.selectors';
import { getSurveys } from '../../survey/survey.selectors';
import { Title } from '../../ui/components/title.component';
import { Text } from '../../ui/components/text.component';
import { getThemeColor, condition, useBreakpoint } from '../../ui/utils/theme.utils';
import { Icon } from '../../ui/components/icon.component';
import { Button, ButtonTypes } from '../../ui/components/button.component';
import { ScrollView } from '../../navigation/components/scrollView.component';
import { userGetSubscriptions } from '../user.actions';
import { BREAKPOINTS } from '../../ui/constants/breakpoints';
import { Link } from '../../navigation/components/link.component';
import { getRemoteText } from '../../data/firebase';

const SubscriptionWrapper = styled(Link)`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-content: center;

  background-color: ${getThemeColor('primary')};

  padding-vertical: 15px;
  padding-horizontal: 20px;

  ${condition(
    'sibling',
    css`
      margin-top: 5px;
    `,
  )};
`;

const SubscriptionText = styled(Text)`
  color: white;
  flex: 1;
`;

const NoSubscriptionText = styled(Text)`
  text-align: center;
`;

const RestorePurchasesWrapper = styled(View)`
  margin-top: 20px;
`;

export const InAppPurchasesScreen = () => {
  const subscriptions = useSelector(getSubscriptions);
  const surveys = useSelector(getSurveys);
  const dispatch = useDispatch();
  const [refreshing, setRefreshing] = useState(false);

  const openSubscriptionsManagement = useCallback(() => {
    Linking.openURL(
      Platform.select({
        ios: 'https://apps.apple.com/account/subscriptions',
        android: `https://play.google.com/store/account/subscriptions?package=fr.fcc.qualicheval`,
      }),
    );
  }, []);

  const onRefresh = useCallback(async () => {
    setRefreshing(true);

    try {
      await dispatch(userGetSubscriptions());
    } catch {
      // Fail silently
    }

    setRefreshing(false);
  }, []);

  const shouldDisplayTitle = useBreakpoint({ [BREAKPOINTS.MEDIUM]: false }, true);

  return (
    <ScrollView onRefresh={onRefresh} refreshing={refreshing} withFooter>
      {shouldDisplayTitle && <Title remoteKey="subscriptions" icon="ribbon" />}
      {subscriptions.length ? null : (
        <NoSubscriptionText>{getRemoteText('no_active_subscriptions')}</NoSubscriptionText>
      )}

      {subscriptions
        .filter(({ metadata }) => metadata && surveys[metadata.surveyId])
        .map(({ id, metadata: { surveyId } }, index) => (
          <SubscriptionWrapper key={id + Math.random()} sibling={index !== 0} to={`/profil/adhesions/${id}`}>
            <SubscriptionText fontSize="16" numberOfLines={1}>
              {surveys[surveyId].name}
            </SubscriptionText>
            <Icon name="arrow-forward" size={24} color="white" />
          </SubscriptionWrapper>
        ))}

      {Platform.OS !== 'web' ? (
        <RestorePurchasesWrapper>
          <Button
            type={ButtonTypes.NO_OUTLINE}
            title={getRemoteText('handle_subscriptions')}
            onPress={openSubscriptionsManagement}
          />
        </RestorePurchasesWrapper>
      ) : null}
    </ScrollView>
  );
};
