import React, { memo } from 'react';
import { PropTypes } from 'prop-types';
import { View, StyleSheet, Switch } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import { Title } from '../../ui/components/title.component';
import { getGdprConsents } from '../../gdpr/gdpr.consents';
import { gdprToggle } from '../../gdpr/gdpr.actions';
import { colors } from '../../ui/constants/colors';
import { TYPOGRAPHIES, Typography } from '../../ui/components/typography.component';

const styles = StyleSheet.create({
  consentWrapper: {
    marginBottom: 20,
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
});

export const Privacy = memo(({ withTitle = true }) => {
  const consents = useSelector(getGdprConsents);
  const dispatch = useDispatch();

  return (
    <View>
      {withTitle && <Title remoteKey="privacy_name" icon="analytics" />}
      <View style={styles.consentWrapper}>
        <Typography size={TYPOGRAPHIES.LARGE} remoteKey="privacy_analytics_name" />
        <Switch
          trackColor={{ false: colors.secondary, true: colors.green }}
          value={!!consents.tracking}
          onValueChange={value => dispatch(gdprToggle('tracking', value))}
          ios_backgroundColor={colors.secondary}
        />
      </View>
      <Typography size={TYPOGRAPHIES.MEDIUM} remoteKey="privacy_deviceParameter" />
      <Typography size={TYPOGRAPHIES.MEDIUM} remoteKey="privacy_analytics_explanation" />
    </View>
  );
});

Privacy.propTypes = {
  withTitle: PropTypes.bool,
};
