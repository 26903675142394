import {
  getAuth,
  connectAuthEmulator,
  onAuthStateChanged as onAuthStateChangedFirebase,
  sendPasswordResetEmail as sendPasswordResetEmailFirebase,
  signInWithEmailAndPassword as signInWithEmailAndPasswordFirebase,
  createUserWithEmailAndPassword as createUserWithEmailAndPasswordFirebase,
  signInWithCustomToken as signInWithCustomTokenFirebase,
  signOut as signOutFirebase,
  updateEmail as updateEmailFirebase,
  NextOrObserver,
  User,
} from 'firebase/auth';

import { firebaseApp } from './app.web';

const auth = getAuth(firebaseApp);

if (__E2E__) {
  connectAuthEmulator(auth, 'http://localhost:9099');
}

export const onAuthStateChanged = (observer: NextOrObserver<User>) => onAuthStateChangedFirebase(auth, observer);

export const createUserWithEmailAndPassword = (email: string, password: string) =>
  createUserWithEmailAndPasswordFirebase(auth, email, password);

export const signInWithEmailAndPassword = (email: string, password: string) =>
  signInWithEmailAndPasswordFirebase(auth, email, password);

export const signInWithCustomToken = (token: string) => signInWithCustomTokenFirebase(auth, token);

export const updateCurrentUserEmail = (email: string) => updateEmailFirebase(auth.currentUser!, email);

export const sendPasswordResetEmail = (email: string) => sendPasswordResetEmailFirebase(auth, email);

export const signOut = () => signOutFirebase(auth);
