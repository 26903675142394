import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components/native';
import { Platform, View, ScrollView, StyleSheet } from 'react-native';

import { getSubscription } from '../user.selectors';
import { getSurveys } from '../../survey/survey.selectors';
import { Button, ButtonTypes } from '../../ui/components/button.component';
import { Subscription } from '../../iap/components/subscription.component';
import { Typography } from '../../ui/components/typography.component';
import { ModalScreen } from '../../navigation/components/modalScreen.component';
import { useSubscriptionManagementLink } from '../hooks/subscription.hooks';
import { getRemoteText } from '../../data/firebase';

const ButtonsWrapper = styled(View)`
  margin-top: 20px;
`;

const styles = StyleSheet.create({
  contentContainerStyle: { flexGrow: 1, paddingVertical: 20, paddingHorizontal: 20 },
});

export const SubscriptionScreen = ({
  route: {
    params: { subscriptionId },
  },
}) => {
  const subscription = useSelector(getSubscription(subscriptionId));
  const surveys = useSelector(getSurveys);
  const billingPortalLink = useSubscriptionManagementLink(subscription);

  if (!subscription) {
    return null;
  }

  const {
    metadata: { surveyId },
    endDate,
    autoRenew,
    platform,
  } = subscription;

  const { name } = surveys[surveyId];

  return (
    <ModalScreen title={Platform.select({ web: `${getRemoteText('subscription')} ${name}`, native: name })}>
      <ScrollView contentContainerStyle={styles.contentContainerStyle}>
        <Subscription autoRenew={autoRenew} endDate={endDate} />

        <ButtonsWrapper>
          {platform === Platform.OS ? (
            <Button
              to={billingPortalLink}
              disabled={!billingPortalLink}
              type={ButtonTypes.NO_OUTLINE}
              hrefAttrs={{ target: '_blank' }}
              title="Gérer ma souscription"
            />
          ) : (
            <Typography>
              Cet achat a été effectué sur la plateforme <Typography fontWeight="600">{platform}</Typography>.
            </Typography>
          )}
        </ButtonsWrapper>
      </ScrollView>
    </ModalScreen>
  );
};

SubscriptionScreen.propTypes = {
  route: PropTypes.shape({
    params: PropTypes.shape({
      subscriptionId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
