import React, { useRef, useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DrawerActions, NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { Platform } from 'react-native';

import { DrawerNavigator } from './drawer.navigator';

import { Auth } from './screens/auth.screen';
import { RegisterScreen } from '../user/screens/register.screen';
import { ForgotPasswordScreen } from '../user/screens/forgotPassword.screen';
import { BeforeAuthPrivacyScreen } from '../user/screens/beforeAuthPrivacy.screen';

import { isConnected } from '../user/user.selectors';
import { analytics } from '../data/analytics';
import { messagingReadRemoteMessage, messagingStoreRemoteMessage } from '../messaging/messaging.actions';
import { remoteMessageToNotification } from '../messaging/messaging.utils';
import { getInitialNotification, onNotificationOpenedApp } from '../data/firebase/messaging';
import { linking } from './const/linking';
import { getRemoteText } from '../data/firebase';
import { MessagingScreenModal } from '../messaging/messaging.screen';
import { PresentationScreen } from './screens/presentation.screen';
import { transparentModalOptions } from './const/screen';
import { NotFoundScreen } from './screens/notFound.screen';
import { SubscriptionScreen } from '../user/screens/subscription.screen';
import { userRealtimeSubscriptions } from '../user/user.actions';
import { WebViewScreen, webViewScreenOptions } from './screens/webview.screen';
import { getMessagingRoute } from './navigation.utils';

const AppStack = createStackNavigator();

const documentTitle = {
  formatter: options => {
    const appName = getRemoteText('app_name');

    if (!options || !options.title) {
      return appName;
    }

    return `${appName} - ${options.title}`;
  },
};

export const AppNavigator = () => {
  const connected = useSelector(isConnected);
  const navigationRef = useRef();
  const [navigationReady, setNavigationReady] = useState(false);
  const dispatch = useDispatch();

  const onStateChange = useCallback(() => {
    const { name: currentRouteName } = navigationRef.current.getCurrentRoute();

    analytics.screenHit(currentRouteName);
  }, []);

  const onRemoteMessageAction = useCallback(remoteMessage => {
    if (!remoteMessage || !navigationRef.current) {
      return;
    }

    const message = remoteMessageToNotification(remoteMessage);

    // Open the Messaging screen modal right away
    navigationRef.current.navigate(...getMessagingRoute(remoteMessage.data.originalNotificationId));

    dispatch(messagingStoreRemoteMessage(message));
    dispatch(messagingReadRemoteMessage(message));
  }, []);

  useEffect(
    () => {
      if (!navigationReady || !connected) {
        return;
      }

      let timeoutRef;

      onNotificationOpenedApp(onRemoteMessageAction);
      getInitialNotification().then(onRemoteMessageAction);

      // We don't necessarily want that for the web
      if (Platform.OS !== 'web') {
        // Nasty hack to wait for the drawer content to be mounted after the navigator tells it's ready
        timeoutRef = setTimeout(() => {
          // Open the drawer right at the start of the app
          navigationRef.current.dispatch(DrawerActions.openDrawer());
        }, 500);
      }

      const unsubscribe = dispatch(userRealtimeSubscriptions);

      return () => {
        clearTimeout(timeoutRef);
        unsubscribe();
      };
    },
    [connected, navigationReady],
  );

  const onReady = useCallback(() => setNavigationReady(true), []);

  return (
    <NavigationContainer
      ref={navigationRef}
      onStateChange={onStateChange}
      linking={{ ...linking, config: { ...linking.config, initialRouteName: connected ? 'Main' : 'Auth' } }}
      documentTitle={documentTitle}
      onReady={onReady}
    >
      <AppStack.Navigator name="" screenOptions={{ headerShown: false }}>
        {connected ? (
          <AppStack.Group>
            <AppStack.Screen name="Main" component={DrawerNavigator} options={{ title: '', animationEnabled: true }} />

            <AppStack.Group screenOptions={transparentModalOptions} presentation="modal">
              <AppStack.Screen
                name="MessagingModal"
                component={MessagingScreenModal}
                options={{ title: 'Notifications' }}
              />
              <AppStack.Screen name="AboutModal" component={PresentationScreen} options={{ title: 'À propos' }} />
              <AppStack.Screen
                name="SubscriptionModal"
                component={SubscriptionScreen}
                options={{ title: getRemoteText('subscription') }}
              />
            </AppStack.Group>

            <AppStack.Group screenOptions={webViewScreenOptions} presentation="modal">
              <AppStack.Screen name="WebViewModal" component={WebViewScreen} />
            </AppStack.Group>
          </AppStack.Group>
        ) : (
          <AppStack.Group>
            <AppStack.Screen name="Auth" options={{ title: '' }} component={Auth} />

            <AppStack.Group presentation="modal" screenOptions={transparentModalOptions}>
              <AppStack.Screen name="Register" component={RegisterScreen} options={{ title: 'Inscription' }} />
              <AppStack.Screen
                name="ForgotPassword"
                component={ForgotPasswordScreen}
                options={{ title: 'Mot de passe oublié' }}
              />
              <AppStack.Screen
                name="Privacy"
                component={BeforeAuthPrivacyScreen}
                options={{ title: 'Données personnelles' }}
              />

              <AppStack.Group screenOptions={webViewScreenOptions} presentation="modal">
                <AppStack.Screen name="WebViewModal" component={WebViewScreen} />
              </AppStack.Group>
            </AppStack.Group>
          </AppStack.Group>
        )}

        <AppStack.Screen name="NotFound" component={NotFoundScreen} options={{ title: 'Page introuvable' }} />
      </AppStack.Navigator>
    </NavigationContainer>
  );
};
