import React, { useCallback, useMemo } from 'react';
import { Header, StackHeaderProps, StackNavigationOptions } from '@react-navigation/stack';
import { HeaderBackButton as RNHeaderBackButton, HeaderBackButtonProps } from '@react-navigation/elements';
import { useTheme } from 'styled-components/native';
import { Platform } from 'react-native';
import { useLinkTo } from '@react-navigation/native';
import { useSelector } from 'react-redux';

import { Icon } from '../../ui/components/icon.component';
import { getLinkTo } from '../utils/getLinkTo.utils';
import { ROUTE_NAMES } from '../const/linking';
import { isConnected } from '../../user/user.selectors';
import { getFirstSurveyId } from '../../survey/survey.selectors';

const HeaderBackButton = (props: HeaderBackButtonProps) => {
  const linkTo = useLinkTo();
  const connected = useSelector(isConnected);

  const surveyId = useSelector(getFirstSurveyId);

  const onPress = useCallback(
    () => linkTo(connected ? getLinkTo(ROUTE_NAMES.SURVEY, { surveyId }) : '/connexion'),
    [linkTo, connected],
  );

  return <RNHeaderBackButton {...props} onPress={props.canGoBack ? props.onPress : onPress} />;
};

export const SimpleHeader = ({ options: propOptions, navigation, ...props }: StackHeaderProps) => {
  const {
    dark,
    colors: { primary },
  } = useTheme();

  const options = useMemo<StackNavigationOptions>(() => {
    return {
      ...propOptions,
      headerStyle: {
        backgroundColor: primary,
        shadowOffset: { height: 0, width: 0 },
        elevation: 0,
        height: Platform.select({ native: 64 + 48, web: 64 }),
      },
      headerTintColor: 'white',
      headerBackTitle: propOptions.headerBackTitle ? propOptions.headerBackTitle : 'Retour',
      headerBackImage: () => <Icon color="white" size={24} name="arrow-back"></Icon>,
      headerLeft: Platform.select({ web: HeaderBackButton, native: undefined }),
    };
  }, [dark, propOptions]);

  return <Header {...props} options={options} navigation={navigation} />;
};
