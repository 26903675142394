import { PARTNERS_FETCH } from './partners.actions';

const initialState = { partners: [] };

export const partners = (state = initialState, action) => {
  switch (action.type) {
    case PARTNERS_FETCH:
      return { ...state, partners: action.partners };

    default:
      return state;
  }
};
