import React, { useCallback, useState, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { StyleSheet, View, Dimensions } from 'react-native';
import styled from 'styled-components/native';
import { useNavigation } from '@react-navigation/native';
import { FlatList } from 'react-native';

import { Icon } from '../ui/components/icon.component';
import { Notification, NOTIFICATION_HEIGHT } from './components/notification.component';

import { getMessages } from './messaging.selectors';
import { messagingFetchRemoteMessage, messagingReadRemoteMessage } from './messaging.actions';
import { messageToIconName, messageToLinkProps } from './messaging.utils';
import { useThemeColor } from '../ui/utils/theme.utils';
import { analytics } from '../data/analytics';
import { Typography } from '../ui/components/typography.component';
import { ModalScreen } from '../navigation/components/modalScreen.component';
import { Separator } from '../ui/components/separator.component';

const ListEmptyWrapper = styled(View)`
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const ListEmptyIcon = styled(Icon)`
  margin-bottom: 32px;
`;

const ListEmptyText = styled(Typography)`
  margin-bottom: 24px;
`;

const ListEmptyTextTitle = styled(ListEmptyText)`
  text-align: center;
`;

const ListEmpty = () => {
  const textColor = useThemeColor('text');

  return (
    <ListEmptyWrapper>
      <ListEmptyIcon name="notifications-off" size={64} color={textColor} />
      <ListEmptyTextTitle fontSize="24">Vous n'avez pas reçu de notifications</ListEmptyTextTitle>
      <ListEmptyText fontSize="18">
        Les notifications vous permettent de rester à jour avec le contexte réglementaire, ainsi que d'être au courant
        des changements relatifs à l'application
      </ListEmptyText>
    </ListEmptyWrapper>
  );
};

const styles = StyleSheet.create({
  contentContainerStyle: { flexGrow: 1, paddingVertical: 20, paddingHorizontal: 20 },
});

const MessageSeparator = () => <Separator size={20} />;

export const MessagingScreenModal = ({ route }) => {
  const windowHeight = Dimensions.get('window').height;

  const messages = useSelector(getMessages);
  const [refreshing, setRefreshing] = useState(false);
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const flatListRef = useRef();

  const { defaultExpandMessageId = null } = route.params || {};

  const onRefresh = useCallback(async (feedback = true) => {
    if (feedback) {
      setRefreshing(true);
    }

    try {
      await dispatch(messagingFetchRemoteMessage());
    } catch {
      // Fail silently
    }

    if (feedback) {
      setRefreshing(false);
    }
  }, []);

  useEffect(() => {
    onRefresh(false);
  }, []);

  const renderItem = useCallback(
    ({ item }) => (
      <Notification
        {...item}
        iconName={messageToIconName(item)}
        defaultExpanded={item.data && defaultExpandMessageId === item.data.originalNotificationId}
        linkProps={{
          ...messageToLinkProps(item),
          onPress: () => {
            dispatch(messagingReadRemoteMessage(item));

            if (!item.data || !item.data.target) {
              return;
            }

            analytics.event('notification_navigation', { notificationTarget: item.data.target });
          },
        }}
      />
    ),
    [navigation, defaultExpandMessageId],
  );

  const keyExtractor = useCallback(item => item.id, []);

  // Optimizes render on smaller devices
  // 150 to take into account the modal's header and padding
  const initialNumToRender = useMemo(
    () => Math.min(10, Math.ceil((windowHeight - 150) / NOTIFICATION_HEIGHT)),
    [windowHeight],
  );

  const getItemLayout = useCallback(
    (data, index) => ({
      length: NOTIFICATION_HEIGHT,
      // Scroll to the notification and remove just a little bit for the user
      // to see there are other notifications above
      offset: NOTIFICATION_HEIGHT * index + Math.max((index - 1) * 20, 0),
      index,
    }),
    [],
  );

  useEffect(() => {
    if (!defaultExpandMessageId) {
      return;
    }

    const itemToScrollTo = messages.find(item => defaultExpandMessageId === item.data.originalNotificationId);
    const initialScrollIndex = messages.indexOf(itemToScrollTo) || 0;

    if (initialScrollIndex <= 0) {
      return;
    }

    flatListRef.current.scrollToIndex({ index: initialScrollIndex, animated: true });
  }, [defaultExpandMessageId]);

  return (
    <ModalScreen title="Notifications">
      <FlatList
        data={messages}
        refreshing={refreshing}
        onRefresh={onRefresh}
        contentContainerStyle={styles.contentContainerStyle}
        ListEmptyComponent={ListEmpty}
        keyExtractor={keyExtractor}
        renderItem={renderItem}
        initialNumToRender={initialNumToRender}
        ItemSeparatorComponent={MessageSeparator}
        getItemLayout={getItemLayout}
        ref={flatListRef}
        // Using initialScrollIndex puts up a weird effect at mount
        // initialScrollIndex={initialScrollIndex}
      />
    </ModalScreen>
  );
};

MessagingScreenModal.propTypes = {
  route: PropTypes.object.isRequired,
};
