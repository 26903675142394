import React, { MouseEvent, ReactChildren, useCallback } from 'react';
import { View, TouchableOpacity, Platform } from 'react-native';
import { SafeAreaView as NativeSafeAreaView } from 'react-native-safe-area-context';
import styled, { css } from 'styled-components/native';
import { useNavigation } from '@react-navigation/native';

import { Icon } from '../../ui/components/icon.component';

import { backgroundMixin, mq } from '../../ui/utils/theme.utils';
import { TYPOGRAPHIES, Typography } from '../../ui/components/typography.component';
import { ThemeProvider } from '../../ui/components/theme.component';

const HeaderView = styled(View)`
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: space-between;

  padding: 20px;
  height: 100px;

  ${mq.sm(css`
    height: 100px;
    align-items: center;
  `)}

  ${mq.md(css`
    height: 150px;
    align-items: flex-end;
  `)}
`;

const Wrapper = styled(View)`
  max-width: 800px;
  width: 100%;
  height: ${Platform.select({ web: '100vh', default: '100%' })};
`;

const ContentWrapper = styled(View)`
  flex: 1;
  border-radius: 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;

  ${backgroundMixin()}
`;

const SafeAreaView = styled(NativeSafeAreaView)`
  align-items: center;
  height: ${Platform.select({ web: '100vh', default: '100%' })};
`;

type ModalScreenProps = { children: ReactChildren; title: string };

export const ModalScreen = ({ children, title }: ModalScreenProps) => {
  const navigation = useNavigation();

  const goBack = useCallback(() => navigation.goBack(), [navigation]);

  const stopPropagation = (event: MouseEvent) => event?.stopPropagation();
  const stopPropagationProps = Platform.select({
    web: { onPress: stopPropagation, onClick: stopPropagation },
    default: { onPress: stopPropagation },
  });

  return (
    <ThemeProvider dark>
      <SafeAreaView
        edges={['top', 'left', 'right']}
        {...Platform.select({ web: { onPress: goBack, onClick: goBack }, default: { onPress: goBack } })}
      >
        <Wrapper>
          <HeaderView {...stopPropagationProps}>
            <Typography numberOfLines={2} size={TYPOGRAPHIES.XXLARGE} color="white">
              {title}
            </Typography>
            <TouchableOpacity onPress={goBack}>
              <Icon name="close" size={48} color="white" />
            </TouchableOpacity>
          </HeaderView>
          <ContentWrapper {...stopPropagationProps}>{children}</ContentWrapper>
        </Wrapper>
      </SafeAreaView>
    </ThemeProvider>
  );
};
