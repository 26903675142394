import React, { forwardRef, useCallback } from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import styled from 'styled-components';
import { get } from '../utils/theme.utils';

import { TYPOGRAPHIES, Typography } from './typography.component';

const PickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Select = styled.select<{ marginTop: number }>`
  font-size: 18px;
  line-height: 22px;

  margin-top: ${get('marginTop', 0)}px;
`;

type PickerValue = {
  key: string;
  label: string;
};

type PickerProps = {
  values: PickerValue[];
  value: string;
  onValueChange: (value: string[]) => void;

  placeholder?: string;
  style?: StyleProp<ViewStyle>;
};

export const Picker = forwardRef<HTMLSelectElement, PickerProps>(
  ({ values: availableValues = [], value, placeholder, onValueChange, style }: PickerProps, ref) => {
    const onChange = useCallback(event => onValueChange(event.target.value), [onValueChange]);

    return (
      <PickerWrapper style={style}>
        {placeholder ? (
          <Typography fontWeight="600" size={TYPOGRAPHIES.LARGE}>
            {placeholder}
          </Typography>
        ) : null}
        <Select ref={ref} onChange={onChange} value={value} marginTop={placeholder ? 10 : 0}>
          {availableValues.map(({ key, label }) => (
            <option key={key} value={key}>
              {label}
            </option>
          ))}
        </Select>
      </PickerWrapper>
    );
  },
);
