import {
  COACHING_ADD_LINK,
  COACHING_REMOVE_LINK,
  COACHING_TRAININGS_FETCH_DATA,
  COACHING_DOCUMENTS_FETCH_DATA,
  COACHING_CATEGORIES_FETCH_DATA,
} from './coaching.actions';

const initialState = {
  trainings: [],
  trainingsFetched: false,
  documents: [],
  documentsFetched: false,
  links: [],
  documentsCategories: [],
  trainingsCategories: [],
};

export const coaching = (state = initialState, action) => {
  switch (action.type) {
    case COACHING_CATEGORIES_FETCH_DATA:
      return {
        ...state,
        documentsCategories: action.documentsCategories || [],
        trainingsCategories: action.trainingsCategories || [],
      };

    case COACHING_ADD_LINK:
      if (state.links.find(link => link === action.link)) {
        return state;
      }

      return {
        ...state,
        links: [...state.links, action.link],
      };

    case COACHING_REMOVE_LINK:
      return {
        ...state,
        links: state.links.filter(link => link !== action.link),
      };

    case COACHING_TRAININGS_FETCH_DATA:
      return {
        ...state,
        trainingsFetched: true,
        trainings: action.trainings || [],
      };

    case COACHING_DOCUMENTS_FETCH_DATA:
      return {
        ...state,
        documentsFetched: true,
        documents: action.documents || [],
      };

    default:
      return state;
  }
};
