import React from 'react';
import PropTypes from 'prop-types';
import styled, { useTheme } from 'styled-components/native';

import { useFontScale } from '../../ui/components/fontScale.hook';
import { Typography } from '../../ui/components/typography.component';
import { useBreakpoint } from '../../ui/utils/theme.utils';
import { BREAKPOINTS } from '../../ui/constants/breakpoints';

const LabelText = styled(Typography)`
  margin-top: 2px;
`;

export const TabBarLabel = ({ focused, title: rawTitle, showOn = BREAKPOINTS.XSMALL }) => {
  const {
    colors: { iconFocused, icon },
  } = useTheme();

  const fontScale = useFontScale();
  const shouldShow = useBreakpoint({ [showOn]: true }, false);
  const title = useBreakpoint({ [BREAKPOINTS.XSMALL]: rawTitle, [BREAKPOINTS.MEDIUM]: rawTitle.toUpperCase() });

  if (shouldShow && fontScale < 2) {
    return (
      <LabelText numberOfLines={1} textAlign="center" color={focused ? iconFocused : icon}>
        {title}
      </LabelText>
    );
  }

  return null;
};

TabBarLabel.propTypes = {
  title: PropTypes.string.isRequired,
  showOn: PropTypes.string,
  focused: PropTypes.bool,
};
