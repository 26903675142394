import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, TextInput as NativeTextInput, View } from 'react-native';
import styled from 'styled-components/native';
import { Icon } from './icon.component';
import { useThemeColor } from '../utils/theme.utils';

const styles = StyleSheet.create({
  icon: {
    width: 30,
    alignSelf: 'center',
  },
});

const Input = styled(NativeTextInput)`
  margin-left: 10px;
  height: 40px;
  font-size: 18px;
  flex: 1;
`;

const Wrapper = styled(View)`
  border-bottom-width: 1px;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-horizontal: 10px;
  padding-bottom: 0px;
  flex-direction: row;
  align-content: center;
`;

export const TextInput = React.forwardRef(({ iconName, color, ...props }, ref) => {
  const inputForegroundColor = useThemeColor('input.foreground');

  const finalColor = color || inputForegroundColor;

  return (
    <Wrapper style={{ borderBottomColor: finalColor }}>
      {iconName ? <Icon name={iconName} size={30} style={styles.icon} color={finalColor} /> : null}
      <Input ref={ref} {...props} placeholderTextColor={finalColor} style={{ color: finalColor }} />
    </Wrapper>
  );
});

TextInput.propTypes = {
  color: PropTypes.string,
  iconName: PropTypes.string,
  style: PropTypes.object,
};
