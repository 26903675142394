import AsyncStorage from '@react-native-async-storage/async-storage';
import { SecurityUtils } from '@valotvince/equireglementation-utils';

import appPackage from '../../package.json';

export const getUniqueId = async () => {
  try {
    const uniqueId = await AsyncStorage.getItem('local-device-id');

    if (uniqueId) {
      return uniqueId;
    }
  } catch {
    // Do nothing
  }

  const uniqueId = SecurityUtils.genGuid();

  try {
    await AsyncStorage.setItem('local-device-id', uniqueId);
  } catch {
    // Do nothing
  }

  return uniqueId;
};

export const getVersion = () => appPackage.version;
export const getBuildNumber = () => 'web';
