import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Sentry from '../../utils/sentry';

interface CacheOptions {
  key: string;
  ttl?: number;
}

export const fetchWithCache = async (url: string, options: RequestInit, { key, ttl = 3600 * 24 }: CacheOptions) => {
  let data;

  try {
    const storageItem = (await AsyncStorage.getItem(key)) || '';
    const { expiration, data: storedData } = JSON.parse(storageItem);

    data = storedData;

    if (expiration > Date.now() / 1000) {
      return data;
    }
  } catch {
    // Fail silently
  }

  try {
    const response = await fetch(url, options);
    data = await response.json();

    await AsyncStorage.setItem(key, JSON.stringify({ data, expiration: Date.now() / 1000 + ttl }));
  } catch (error) {
    // Fail silently, use expired data even if the call fails
    Sentry.captureException(error);
  }

  return data;
};
