const defaultArray = [];

export const getUser = ({ user }) => user;

export const isAutoLoginDone = ({ user: { autoLoginDone } }) => autoLoginDone;
export const isConnected = ({ user: { id, autoLoginDone } }) => autoLoginDone && !!id;

export const getUsersJobs = ({ user: { usersJobs } }) => usersJobs || defaultArray;
export const getUsersActivityDomains = ({ user: { usersActivityDomains } }) => usersActivityDomains || defaultArray;

export const getSubscriptions = ({ user: { subscriptions } }) => subscriptions || defaultArray;
export const getSubscription = subscriptionId => state =>
  getSubscriptions(state).find(({ id }) => id === subscriptionId);
export const getSubscriptionForSurvey = id => state =>
  getSubscriptions(state).find(({ metadata }) => metadata && metadata.surveyId === id);

export const getDepartments = ({ user: { departments } }) => departments;

export const hasUserConnectedIntegrators = ({ user: { integrators } }) =>
  !!(integrators && Object.keys(integrators).length > 0);
