import { Platform } from 'react-native';
import { useMemo } from 'react';
import { Interpolation, ThemedStyledProps, css, useTheme, DefaultTheme } from 'styled-components/native';

import { FOOTER_HEIGHT } from './../../navigation/const/screen';
import { breakpointValues, BREAKPOINTS } from '../constants/breakpoints';

interface ObjectWithKeys {
  [key: string]: any;
}

const deepGet = (object: any, path: string) => {
  const keys = path.split('.');

  let walker = object;

  if (!walker) {
    return null;
  }

  for (const key of keys) {
    if (walker[key]) {
      walker = walker[key];
    } else {
      return null;
    }
  }

  return walker;
};

export const get =
  (propertyName: string, defaultValue?: any) => (props: ThemedStyledProps<ObjectWithKeys, DefaultTheme>) =>
    deepGet(props, propertyName) || defaultValue;

export const getThemeColor =
  (path: string) =>
  ({ theme: { colors } }: ThemedStyledProps<ObjectWithKeys, DefaultTheme>) =>
    deepGet(colors, path);

export const condition =
  (match: string | ObjectWithKeys, css: Interpolation<ThemedStyledProps<ObjectWithKeys, DefaultTheme>>) =>
  (props: ThemedStyledProps<ObjectWithKeys, DefaultTheme>) => {
    if (typeof match === 'string') {
      if (deepGet(props, match)) {
        return css;
      }
    }

    if (typeof match === 'object') {
      const keys = Object.keys(match);

      if (keys.filter(key => props[key] === match[key]).length === keys.length) {
        return css;
      }
    }

    return null;
  };

export const useThemeColor = (colorName: string) => {
  const { colors } = useTheme();

  return deepGet(colors, colorName);
};

/**
 * Returns negative value if the first breakpoint is lower than the second given breakpoint
 * Examples:
 *   left: sm, right: md, results -1
 */
export const breakpointOperator = (left: BREAKPOINTS, right: BREAKPOINTS) => {
  if (breakpointValues[left] < breakpointValues[right]) {
    return -1;
  }

  if (breakpointValues[left] > breakpointValues[right]) {
    return 1;
  }

  return 0;
};

export const useBreakpoint = (values: Partial<Record<BREAKPOINTS, any>>, defaultValue?: any) => {
  const { breakpoint } = useTheme();

  return useMemo(() => {
    const keys = Object.keys(values) as BREAKPOINTS[];

    const filteredKeys = keys.filter(key => breakpointOperator(breakpoint, key) !== -1).sort(breakpointOperator);

    if (filteredKeys.length) {
      return values[filteredKeys[filteredKeys.length - 1]];
    }

    return defaultValue;
  }, [breakpoint, values]);
};

export const backgroundMixin = () => css`
  background-color: ${getThemeColor('secondary')};

  ${condition(
    'theme.dark',
    css`
      background-color: ${getThemeColor('primary')};
    `,
  )}
`;

export const getBreakpoint = (width: number) => {
  if (width >= breakpointValues[BREAKPOINTS.XXLARGE]) {
    return BREAKPOINTS.XXLARGE;
  }

  if (width >= breakpointValues[BREAKPOINTS.XLARGE]) {
    return BREAKPOINTS.XLARGE;
  }

  if (width >= breakpointValues[BREAKPOINTS.LARGE]) {
    return BREAKPOINTS.LARGE;
  }

  if (width >= breakpointValues[BREAKPOINTS.MEDIUM]) {
    return BREAKPOINTS.MEDIUM;
  }

  if (width >= breakpointValues[BREAKPOINTS.SMALL]) {
    return BREAKPOINTS.SMALL;
  }

  return BREAKPOINTS.XSMALL;
};

// theme sm, breakpoint md SHOULD NOT === -1
// theme md, breakpoint sm OK === 1
// theme md, breakpoint md OK === 0

const mqMethod =
  (breakpoint: BREAKPOINTS) =>
  (css: Interpolation<ThemedStyledProps<ObjectWithKeys, DefaultTheme>>) =>
  (props: ThemedStyledProps<ObjectWithKeys, DefaultTheme>) => {
    if (breakpointOperator(props.theme.breakpoint, breakpoint) !== -1) {
      return css;
    }

    return null;
  };

export const mq = {
  xsm: mqMethod(BREAKPOINTS.XSMALL),
  sm: mqMethod(BREAKPOINTS.SMALL),
  md: mqMethod(BREAKPOINTS.MEDIUM),
  lg: mqMethod(BREAKPOINTS.LARGE),
  xlg: mqMethod(BREAKPOINTS.XLARGE),
  xxlg: mqMethod(BREAKPOINTS.XXLARGE),
};

const contentContainerStylePerBreakpoints = {
  [BREAKPOINTS.XSMALL]: { paddingVertical: 10, paddingHorizontal: 15 },
  [BREAKPOINTS.MEDIUM]: {
    paddingVertical: 20,
    paddingHorizontal: 30,
  },
  [BREAKPOINTS.LARGE]: {
    paddingVertical: 20,
    paddingHorizontal: 60,
  },
};

type UseScreenPaddingParams = {
  withFooter?: boolean;
};

export const useScreenPadding = ({ withFooter = false }: UseScreenPaddingParams = {}) => {
  const value = useBreakpoint(contentContainerStylePerBreakpoints, {
    paddingVertical: 10,
    paddingHorizontal: 15,
  });

  const withFooterBreakpoint = useMemo(
    () => ({
      ...value,
      paddingBottom: FOOTER_HEIGHT + value.paddingVertical,
    }),
    [value, withFooter],
  );

  if (Platform.select({ web: withFooter, default: false })) {
    return withFooterBreakpoint;
  }

  return value;
};
