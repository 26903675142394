import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/native';
import { Integrators } from '@valotvince/equireglementation-utils';
import { useNavigation } from '@react-navigation/native';

import { VALIDATION_EMAIL } from '../../ui/constants/validation';

import { KeyboardAwareScrollView } from '../../navigation/components/scrollView.component';
import { Button, ButtonTypes } from '../../ui/components/button.component';
import { userPasswordSignIn, userRedirectToIntegrator } from '../user.actions';
import { AppTitle } from '../../ui/components/appTitle.component';
import { Form } from '../../ui/components/form.component';
import { ThemeProvider } from '../../ui/components/theme.component';
import { ROUTE_NAMES } from '../../navigation/const/linking';
import { getLinkTo } from '../../navigation/utils/getLinkTo.utils';
import { getRemoteFeatureFlag } from '../../data/firebase';

const getErrorMessage = () => 'Merci de bien vouloir vérifier vos identifiants et essayer de nouveau';

const Wrapper = styled(View)`
  width: 75%;
  max-width: 500px;
  align-items: center;
`;

const SecondaryActionsWrapper = styled(View)`
  margin-top: 40px;
`;

export const SignIn = () => {
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const integratorSignIn = getRemoteFeatureFlag('feature_flag_shf');

  const onSubmit = useCallback(({ email, password }) => dispatch(userPasswordSignIn(email, password)), [dispatch]);

  const redirectToIntegrator = useCallback(
    async email => !(await dispatch(userRedirectToIntegrator(navigation, email))),
    [dispatch, navigation],
  );

  const fields = useMemo(
    () => [
      {
        name: 'email',
        iconName: 'mail',
        placeholder: 'Email',
        autoCompleteType: 'email',
        autoCapitalize: 'none',
        keyboardType: 'email-address',
        textContentType: 'emailAddress',
        validation: value => VALIDATION_EMAIL.test(value),
        validationWarning: "L'adresse email n'est pas valide",

        ...(integratorSignIn ? { beforeNextStep: redirectToIntegrator, nextSteps: ['password'] } : {}),
      },
      {
        defaultHidden: integratorSignIn,
        name: 'password',
        iconName: 'lock-closed',
        placeholder: 'Mot de passe',
        autoCompleteType: 'password',
        autoCapitalize: 'none',
        textContentType: 'password',
        secureTextEntry: true,
        validation: value => value.length >= 6,
        validationWarning: 'Le mot de passe doit faire au moins 6 caractères',
      },
    ],
    [redirectToIntegrator],
  );

  return (
    <ThemeProvider dark>
      <KeyboardAwareScrollView contentContainerStyle={{ paddingHorizontal: 0 }}>
        <AppTitle withPartners>
          <Wrapper>
            <Form
              fields={fields}
              onSubmit={onSubmit}
              actionTitle="Se connecter"
              getErrorMessage={getErrorMessage}
              testID="form-sign-in"
            />
            {integratorSignIn ? (
              <Button title="Se connecter avec SHF" type={ButtonTypes.NO_OUTLINE} to={Integrators.SHF.ssoEndpoint()} />
            ) : null}
            <SecondaryActionsWrapper>
              <Button
                title="Pas de compte ? Enregistrez vous !"
                type={ButtonTypes.NO_OUTLINE}
                to={getLinkTo(ROUTE_NAMES.REGISTER)}
              />
              <Button
                title="Mot de passe oublié ?"
                type={ButtonTypes.NO_OUTLINE}
                to={getLinkTo(ROUTE_NAMES.FORGOT_PASSWORD)}
              />
              <Button title="Données personnelles" type={ButtonTypes.NO_OUTLINE} to={getLinkTo(ROUTE_NAMES.PRIVACY)} />
            </SecondaryActionsWrapper>
          </Wrapper>
        </AppTitle>
      </KeyboardAwareScrollView>
    </ThemeProvider>
  );
};

SignIn.propTypes = {
  navigation: PropTypes.shape({
    navigate: PropTypes.func.isRequired,
  }).isRequired,
};
