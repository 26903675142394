import { StackCardInterpolationProps } from '@react-navigation/stack';
import { Animated } from 'react-native';

export const FOOTER_HEIGHT = 250;

export const transparentModalOptions = {
  headerShown: false,
  animationEnabled: true,
  detachPreviousScreen: false,
  cardStyle: { backgroundColor: 'transparent' },
  cardOverlayEnabled: true,
  cardStyleInterpolator: ({ current, inverted, layouts: { screen } }: StackCardInterpolationProps) => {
    const translateY = Animated.multiply(
      current.progress.interpolate({
        inputRange: [0, 1],
        // Little hack to prevent previous screen flickering behind
        outputRange: [screen.height * 0.08, 0],
        extrapolate: 'clamp',
      }),
      inverted,
    );

    return {
      cardStyle: {
        opacity: current.progress.interpolate({
          inputRange: [0, 0.5, 0.9, 1],
          outputRange: [0, 0.5, 0.9, 1],
        }),
        transform: [{ translateY }],
      },
      overlayStyle: {
        opacity: current.progress.interpolate({
          inputRange: [0, 1],
          outputRange: [0, 0.7],
          extrapolate: 'clamp',
        }),
      },
    };
  },
};
