"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.genGuid = void 0;
var genGuid = function () {
    var time = new Date().getTime();
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (char) {
        // eslint-disable-next-line no-bitwise
        var replacement = (time + Math.random() * 16) % 16 | 0;
        time = Math.floor(time / 16);
        if (char === 'x') {
            return replacement.toString(16);
        }
        // eslint-disable-next-line no-bitwise
        return ((replacement & 0x3) | 0x8).toString(16);
    });
};
exports.genGuid = genGuid;
