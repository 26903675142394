import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/native';
import { SafeAreaView } from 'react-native-safe-area-context';

import { Icon } from '../modules/ui/components/icon.component';
import { Button } from '../modules/ui/components/button.component';
import { backgroundMixin } from '../modules/navigation/components/scrollView.component';
import * as Sentry from '../utils/sentry';
import { Typography, TYPOGRAPHIES } from '../modules/ui/components/typography.component';

const ErrorMessage = styled(Typography).attrs({ size: TYPOGRAPHIES.LARGE, textAlign: 'center' })`
  margin-vertical: 20px;
`;

export const StyledSafeAreaView = styled(SafeAreaView)`
  ${backgroundMixin()}

  flex: 1;
  padding-vertical: 30px;
  padding-horizontal: 30px;
  justify-content: center;
  align-content: center;
  align-items: center;
`;

class AbstractErrorBoundary extends React.Component {
  constructor(...args) {
    super(...args);

    this.state = {
      error: null,
    };
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error, { componentStack }) {
    Sentry.captureException(error, { contexts: { react: { componentStack } } });
  }

  onRetry = () => {
    this.setState({ error: null });
  };

  render() {
    const { error } = this.state;
    const {
      theme: {
        colors: { icon },
      },
      children,
    } = this.props;

    if (error) {
      return (
        <StyledSafeAreaView>
          <Icon name="bug" size={64} color={icon} />
          <ErrorMessage>
            Une erreur est survenue. Merci de bien vouloir relancer l'application ultérieurement
          </ErrorMessage>
          <Button title="Ré-essayer" onPress={this.onRetry} />
          {__DEV__ ? <Typography>{error.message}</Typography> : null}
        </StyledSafeAreaView>
      );
    }

    return children;
  }
}

AbstractErrorBoundary.propTypes = {
  children: PropTypes.any,
  theme: PropTypes.object,
};

const ErrorBoundary = withTheme(AbstractErrorBoundary);

export const withErrorBoundary = Component => props =>
  (
    <ErrorBoundary>
      <Component {...props} />
    </ErrorBoundary>
  );
