"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.colors = void 0;
exports.colors = {
    tertiary: '#394648',
    green: '#84DD63',
    orangeGreen: '#FFC857',
    orange: '#FCAA67',
    orangeRed: '#D3693F',
    red: '#D33F49',
    gray: '#394648',
};
