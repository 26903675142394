import React, { forwardRef } from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';

import { Chip } from './chip.component';
import { TYPOGRAPHIES, Typography } from './typography.component';

const MultiPickerWrapper = styled(View)`
  margin-top: 20px;
`;

const ValuesWrapper = styled(View)`
  margin-top: 10px;
  flex-direction: row;
  flex-wrap: wrap;
`;

type MultiPickerValue = {
  key: string;
  label: string;
};

type MultiPickerProps = {
  values: MultiPickerValue[];
  value: string[];
  placeholder: string;
  onValueChange: (value: string[]) => void;
};

export const MultiPicker = forwardRef(
  ({ values: availableValues = [], value: values = [], placeholder, onValueChange }: MultiPickerProps, ref) => {
    return (
      <MultiPickerWrapper ref={ref}>
        <Typography fontWeight="600" size={TYPOGRAPHIES.LARGE}>{placeholder}</Typography>
        <ValuesWrapper>
          {availableValues.map(({ label, key }) => {
            const selected = values.includes(key);

            return (
              <Chip
                key={key}
                label={label}
                full={selected}
                onPress={() => {
                  if (selected) {
                    const newValues = [...values];
                    newValues.splice(values.indexOf(key), 1);

                    onValueChange(newValues);
                  } else {
                    onValueChange([...values, key]);
                  }
                }}
              />
            );
          })}
        </ValuesWrapper>
      </MultiPickerWrapper>
    );
  },
);
