import React from 'react';
import { useSelector } from 'react-redux';
import { View } from 'react-native';
import styled from 'styled-components/native';

import { getPartners } from '../../partners/partners.selectors';
import { Typography, TYPOGRAPHIES } from './typography.component';
import { AsyncImage } from './asyncImage.component';
import { useBreakpoint } from '../utils/theme.utils';
import { BREAKPOINTS } from '../constants/breakpoints';

const PartnersWrapper = styled(View)`
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  margin-top: 10px;
`;

const PartnerImage = styled(AsyncImage)`
  margin-right: 10px;
`;

const partnerHeightPerBreakpoints = {
  [BREAKPOINTS.SMALL]: 48,
  [BREAKPOINTS.MEDIUM]: 64,
};

export const Partners = () => {
  const partners = useSelector(getPartners);
  const height = useBreakpoint(partnerHeightPerBreakpoints, 32);

  return (
    <PartnersWrapper>
      {partners.map(partner => {
        if (partner.image) {
          return <PartnerImage key={partner.id} height={height} imageRef={partner.image} />;
        }

        return (
          <Typography size={TYPOGRAPHIES.SMALL} key={partner.id}>
            {partner.name}
          </Typography>
        );
      })}
    </PartnersWrapper>
  );
};
