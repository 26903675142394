import React from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';

import { Typography, TYPOGRAPHIES } from '../../ui/components/typography.component';
import { getThemeColor } from '../../ui/utils/theme.utils';

export const HEADER_SURVEY_WRAPPER_HEIGHT = 44;

const HeaderSurveyWrapper = styled(View)`
  height: ${HEADER_SURVEY_WRAPPER_HEIGHT}px;
  width: 100%;
  background-color: ${getThemeColor('primary')};
  align-items: center;
  justify-content: center;
  align-content: center;
  padding-vertical: 5px;
`;

type SubHeaderProps = {
  title: string;
};

export const SubHeader = ({ title }: SubHeaderProps) => (
  <HeaderSurveyWrapper>
    <Typography size={TYPOGRAPHIES.LARGE} color="white" testID="sub-header-title">
      {title ? title.toUpperCase() : ''}
    </Typography>
  </HeaderSurveyWrapper>
);
