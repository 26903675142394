import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import styled, { css } from 'styled-components/native';

import { Text } from './text.component';
import { condition, getThemeColor, useThemeColor } from '../utils/theme.utils';
import { useFontScale } from './fontScale.hook';
import { Icon } from './icon.component';

const Separator = styled(View)`
  height: 1px;
  background-color: ${getThemeColor('text')};
  align-self: stretch;
  margin-bottom: 20px;
`;

const ContentWrapper = styled(View)`
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;

  ${condition(
    'centered',
    css`
      justify-content: center;
    `,
  )}
`;

const Label = styled(Text)`
  font-weight: 600;
  text-transform: uppercase;
  font-size: 18px;
`;

export const Title = ({ label, icon, remoteKey, centered, separator = true, testID }) => {
  const fontScale = useFontScale();
  const textColor = useThemeColor('text');

  return (
    <View>
      <ContentWrapper style={{ marginBottom: icon ? 5 : 10 }} centered={centered}>
        {icon && <Icon name={icon} size={24 * fontScale} marginRight="10" color={textColor} />}
        <Label remoteKey={remoteKey} testID={testID}>
          {label}
        </Label>
      </ContentWrapper>
      {separator && <Separator />}
    </View>
  );
};

Title.propTypes = {
  label: PropTypes.string,
  remoteKey: PropTypes.string,
  icon: PropTypes.string,
  centered: PropTypes.bool,
  separator: PropTypes.bool,
  testID: PropTypes.string,
};
