import { USER_SIGN_OUT } from '../user/user.actions';
import {
  SURVEY_ANSWER_QUESTION,
  SURVEY_LOAD_ANSWERS,
  SURVEY_RESET,
  SURVEY_FETCH_CATEGORIES,
  SURVEY_FETCH_QUESTIONS,
  SURVEY_FETCH,
} from './survey.actions';

import { answerAndMeetSurveysQuestions } from './survey.utils';

const initialState = { surveys: {}, answers: {} };
export const survey = (state = initialState, action) => {
  switch (action.type) {
    case SURVEY_FETCH: {
      const surveys = action.surveys.reduce(
        (accumulator, data) => ({
          ...accumulator,
          [data.id]: {
            ...data,
            categories: [],
            certification: {},
          },
        }),
        {},
      );

      return {
        ...state,
        surveys,
      };
    }

    case SURVEY_FETCH_CATEGORIES:
      return {
        ...state,
        surveys: {
          ...state.surveys,
          [action.surveyId]: {
            ...state.surveys[action.surveyId],
            categories: action.categories.map(category => ({
              ...category,
              certification: {},
              questions: [],
            })),
          },
        },
      };

    case SURVEY_FETCH_QUESTIONS: {
      const stateSurvey = state.surveys[action.surveyId];

      return {
        ...state,
        surveys: {
          ...state.surveys,
          [action.surveyId]: {
            ...stateSurvey,
            categories: stateSurvey.categories.map(category => {
              if (category.id === action.categoryId) {
                return {
                  ...category,
                  questions: action.questions || [],
                  certification: {},
                };
              }

              return category;
            }),
          },
        },
      };
    }

    case SURVEY_LOAD_ANSWERS: {
      if (!action.answers || !action.answers.length) {
        return state;
      }

      const answers = action.answers.reduce((accumulator, answer) => ({ ...accumulator, [answer.id]: answer }), {});

      return {
        ...state,
        answers,
        surveys: answerAndMeetSurveysQuestions(state.surveys, answers),
      };
    }

    case SURVEY_ANSWER_QUESTION: {
      const { givenAnswer, questionId } = action;

      const answers = {
        ...state.answers,
        [questionId]: givenAnswer,
      };

      return {
        ...state,
        answers,
        surveys: answerAndMeetSurveysQuestions(state.surveys, answers),
      };
    }

    case SURVEY_RESET:
    case USER_SIGN_OUT:
      return {
        answers: {},
        surveys: answerAndMeetSurveysQuestions(state.surveys, {}),
      };

    default:
      return state;
  }
};
