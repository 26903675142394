/**
 * https://tailwindcss.com/docs/responsive-design
 */

export enum BREAKPOINTS {
  XSMALL = 'xsm',
  SMALL = 'sm',
  MEDIUM = 'md',
  LARGE = 'lg',
  XLARGE = 'xl',
  XXLARGE = 'xxl',
}

export const breakpointValues = {
  [BREAKPOINTS.XSMALL]: 480,
  [BREAKPOINTS.SMALL]: 640,
  [BREAKPOINTS.MEDIUM]: 768,
  [BREAKPOINTS.LARGE]: 1024,
  [BREAKPOINTS.XLARGE]: 1280,
  [BREAKPOINTS.XXLARGE]: 2000,
};
