import React from 'react';
import { useDispatch } from 'react-redux';

import { KeyboardAwareScrollView } from '../../navigation/components/scrollView.component';
import { userForgotPassword } from '../user.actions';
import { Form } from '../../ui/components/form.component';
import { VALIDATION_EMAIL } from '../../ui/constants/validation';
import { ModalScreen } from '../../navigation/components/modalScreen.component';
import { Separator } from '../../ui/components/separator.component';

const fields = [
  {
    autoFocus: true,
    name: 'email',
    iconName: 'mail',
    placeholder: 'Email',
    autoCompleteType: 'email',
    autoCapitalize: 'none',
    keyboardType: 'email-address',
    textContentType: 'emailAddress',
    validation: value => VALIDATION_EMAIL.test(value),
    validationWarning: "L'adresse email n'est pas valide",
  },
];

const getErrorMessage = () => "Merci de bien vouloir vérifier l'email entré et essayer de nouveau";

export const ForgotPasswordScreen = () => {
  const dispatch = useDispatch();
  const onSubmit = ({ email }) => dispatch(userForgotPassword(email));

  return (
    <ModalScreen title="Mot de passe oublié">
      <KeyboardAwareScrollView raw>
        <Separator size={40} />
        <Form
          fields={fields}
          onSubmit={onSubmit}
          actionTitle="Ré-initialiser mon mot de passe"
          completeMessage="Un e-mail vous a été envoyé avec un lien pour changer votre mot de passe"
          getErrorMessage={getErrorMessage}
          resetFieldsOnComplete
        />
      </KeyboardAwareScrollView>
    </ModalScreen>
  );
};
