export const defaultRemoteConfig = {
  texts_surveys_questions_conditionAnswered:
    "Cette question a été pré-remplie grâce à vos réponses dans d'autres questionnaires",
  texts_surveys_questions_moreInformation: "Complément d'informations :",
  texts_subscription: 'Adhésion',
  texts_subscriptions: 'Adhésions',
  texts_handle_subscriptions: 'Gérer mes adhésions',
  texts_no_active_subscriptions: "Vous n'avez pas d'adhésions actives",
  texts_eligibility: 'Éligibilité',
  texts_privacy_deviceParameter:
    'Le paramétrage réalisé via ce module sapplique à cet appareil uniquement, que vous soyez ou non connecté.',
  texts_privacy_analytics_name: "Mesure d'audience",
  texts_privacy_analytics_explanation:
    "Ces traqueurs permettent d'établir des statistiques de fréquentation de nos services. Les désactiver nous empêche de suivre et d'améliorer la qualité de nos services au moyen de ces traqueurs.",
  texts_privacy_name: 'Données personnelles',
  texts_app_explanation_level1: `Vous êtes détenteurs d’équidés, professionnel ou amateur ?
  Vous êtes perdu au milieu de la jungle réglementaire ?
  Cette appli est faite pour vous !`,
  texts_app_explanation_level2: `Elle permet en quelques minutes de :
  - faire votre bilan sur les principaux points réglementaires à respecter (mon bilan)
  - vous accompagner pour progresser si besoin (mon coaching)
  - d'aller plus loin, en vous orientant vers des démarches de qualité de la filière cheval (mes labels)`,
  texts_app_name: 'EquiPass',
  texts_app_description: "Outil d'autodiagnostic de la filière équine",
  texts_coaching_documents_name: 'Documents à télécharger',
  texts_coaching_trainings_name: 'Formations proposées',
  texts_coaching_documents_no_data: 'Aucun document à consulter dans cette catégorie',
  texts_coaching_trainings_no_data: 'Aucune formation proposée dans cette catégorie',
  texts_coaching_documents_category: 'Catégorie de document',
  texts_coaching_trainings_category: 'Catégorie de formation',
  texts_labels_name: 'Les Labels existants',
  texts_user_job: 'Quel métier exercez vous ?',
  texts_user_activity_domain: "Dans quel domaine d'activité êtes vous ?",
  texts_user_department: 'Dans quel département habitez-vous ?',
  texts_user_delete_account: 'Supprimer mon compte',
  texts_user_delete_account_warning:
    'Êtes-vous sûr de vouloir supprimer votre compte ? Si vous avez des souscriptions en cours, merci de les annuler avant de continuer. Cette action est irréversible.',
  texts_user_delete_account_confirmation: "Votre compte va être supprimé d'ici peu. A bientôt sur Equipass !",
  texts_user_delete_account_error:
    "Une erreur s'est produite lors de la suppression de votre compte. Merci de ré-essayer ultérieurement.",
  texts_iap_terms_of_use:
    "En cliquant sur Adhérer, vous vous engagez à respecter les conditions d'utilisation d'EquiPass",
  texts_passes_results_title: 'Mes résultats',
  texts_passes_certificates_title: 'Mes attestations',
  texts_passes_no_certificates_available: 'Aucune attestation disponible',
  texts_footer_download_apps: "Retrouvez l'application sur smartphones",
  texts_payment_platform_unavailable:
    "Le paiement d'une adhésion n'est pas encore disponible sur cette plateforme. L'application EquiPass est disponible sur Android et iOS.",
  texts_terms_of_use: "Conditions d'utilisation",
  texts_privacy_policy: 'Politique de confidentialité',
  texts_surveys: 'Autodiagnostics',

  texts_surveys_download_pdf_review_success:
    "Votre demande a bien été traitée. Vous allez recevoir un email d'ici quelques minutes avec votre bilan en pièce jointe. Pensez à vérifier que notre mail n'ai pas atterri dans vos spams.",
  texts_surveys_download_pdf_certificate_success:
    "Votre demande a bien été traitée. Vous allez recevoir un email d'ici quelques minutes avec votre attestation en pièce jointe. Pensez à vérifier que notre mail n'ai pas atterri dans vos spams.",

  texts_certificate_button: "Recevoir une attestation d'adhésion",
  texts_certificate_button_integrators: "J'adhère et je reçois mon attestation d'adhésion",

  links_terms_of_use: 'https://www.equipass.fr/static/terms-of-use.pdf',
  links_privacy_policy: 'https://www.equipass.fr/static/privacy-policy',
  links_app_store: 'https://apps.apple.com/fr/app/equipass/id1450188432',
  links_play_store: 'https://play.google.com/store/apps/details?id=fr.fcc.qualicheval',

  feature_flag_shf: true,
  feature_flag_payment: false,
};
