import { getCollection } from '../data/firebase';

export const PARTNERS_FETCH = 'partners/FETCH';
export const fetchPartners = () => async dispatch => {
  const partners = await getCollection('partners', { orderBy: [['order', 'asc']] });

  dispatch({
    type: PARTNERS_FETCH,
    partners,
  });

  return partners;
};
