import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { isConnected, isAutoLoginDone } from '../../user/user.selectors';
import { SignIn } from '../../user/components/signIn.component';
import { ScrollView } from '../components/scrollView.component';
import { FirstStart } from '../../user/components/firstStart.component';
import { ThemeProvider } from '../../ui/components/theme.component';
import { analytics } from '../../data/analytics';

export const Auth = ({ navigation }) => {
  const [firstStart, setFirstStart] = useState(true);
  const [hasCheckedFirstStart, setHasCheckedFirstStart] = useState(false);
  const connected = useSelector(isConnected);
  const autoLoginDone = useSelector(isAutoLoginDone);

  const checkFirstStart = async () => {
    try {
      const storedFirstStart = await AsyncStorage.getItem('firstStart');
      setFirstStart(!storedFirstStart);

      if (!storedFirstStart) {
        analytics.event('app_first_start');
      }
    } catch {
      setFirstStart(false);
    }

    setHasCheckedFirstStart(true);
  };

  useLayoutEffect(() => {
    checkFirstStart();
  }, []);

  let content = <ScrollView />;

  if (hasCheckedFirstStart && firstStart) {
    content = (
      <FirstStart
        onComplete={() => {
          analytics.event('app_first_start_complete');
          AsyncStorage.setItem('firstStart', 'ok');

          setFirstStart(false);
        }}
      />
    );
  } else if (hasCheckedFirstStart && autoLoginDone && !connected) {
    content = <SignIn navigation={navigation} />;
  }

  return <ThemeProvider dark>{content}</ThemeProvider>;
};

Auth.propTypes = {
  navigation: PropTypes.object.isRequired,
};
