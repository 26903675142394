import React, { useEffect, useState, useCallback } from 'react';
import { StatusBar, View } from 'react-native';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/native';
import { SafeAreaProvider } from 'react-native-safe-area-context';

import { AppNavigator } from './modules/navigation/app.navigator';

import { GdprScreen } from './modules/gdpr/gdpr.component';
import { gdprInit } from './modules/gdpr/gdpr.actions';

import { getThemeColor } from './modules/ui/utils/theme.utils';
import { userAutoLogin, userFetchExtraInfo, userFetchGeo, userSignInFromIntegrator } from './modules/user/user.actions';
import { surveyFetch } from './modules/survey/survey.actions';
import { fetchPartners } from './modules/partners/partners.actions';
import { SplashScreen } from './modules/ui/components/splashScreen.component';
import { CatchingUpModal } from './modules/user/components/catchingUpModal.component';
import { messagingStoreRemoteMessage } from './modules/messaging/messaging.actions';
import { fetchAndActivateRemoteConfig } from './modules/data/firebase';
import * as Sentry from './utils/sentry';
import { onMessage } from './modules/data/firebase/messaging';
import { coachingCategoriesFetchData } from './modules/coaching/coaching.actions';

const Container = styled(View)`
  flex: 1;
  background-color: ${getThemeColor('primary')};
`;

const App = () => {
  const [appReady, setAppReady] = useState(false);
  const [error, setError] = useState();
  const dispatch = useDispatch();

  const loadResources = async () => {
    setAppReady(false);

    try {
      // Async data-fetching that we don't need to wait
      dispatch(userFetchExtraInfo());
      dispatch(userFetchGeo());
      dispatch(fetchPartners());
      dispatch(coachingCategoriesFetchData());

      await Promise.all([
        dispatch(gdprInit()),
        dispatch(userAutoLogin()),
        dispatch(surveyFetch()),
        dispatch(userSignInFromIntegrator()),
        fetchAndActivateRemoteConfig(),
      ]);

      setAppReady(true);
    } catch (exception) {
      Sentry.captureException(exception);

      setAppReady(false);
      setError(exception);
    }
  };

  useEffect(() => {
    loadResources();

    return onMessage(remoteMessage => {
      dispatch(messagingStoreRemoteMessage(remoteMessage));
    });
  }, []);

  const onRetry = useCallback(() => {
    setError(null);
    setAppReady(false);

    loadResources();
  }, []);

  return (
    <SafeAreaProvider>
      <Container>
        <StatusBar backgroundColor="rgba(0,0,0,0)" translucent barStyle="light-content" />

        {appReady && (
          <>
            <AppNavigator />
            <GdprScreen />
            <CatchingUpModal />
          </>
        )}

        <SplashScreen onRetry={onRetry} error={error} visible={!appReady} />
      </Container>
    </SafeAreaProvider>
  );
};

export default App;
