import { getCollection } from '../data/firebase';
import { getUser } from '../user/user.selectors';

const userTargetingFiltering =
  ({ department, jobs, activityDomains }) =>
  ({ targeting }) => {
    const { departments, usersJobs, usersActivityDomains } = targeting || {};

    let condition = true;

    if (departments && departments.length) {
      condition = condition && !!departments.find(dep => dep === department);
    }

    if (usersJobs && usersJobs.length && jobs) {
      condition = condition && !!usersJobs.find(userJob => jobs.includes(userJob));
    }

    if (usersActivityDomains && usersActivityDomains.length && activityDomains) {
      condition =
        condition && !!usersActivityDomains.find(usersActivityDomain => activityDomains.includes(usersActivityDomain));
    }

    return condition;
  };

export const COACHING_CATEGORIES_FETCH_DATA = 'coaching/CATEGORIES_FETCH_DATA';
export const coachingCategoriesFetchData = () => async dispatch => {
  const [documentsCategories, trainingsCategories] = await Promise.all([
    getCollection('documentsCategories', {
      orderBy: [['order', 'asc']],
    }),
    getCollection('trainingsCategories', {
      orderBy: [['order', 'asc']],
    }),
  ]);

  return dispatch({
    type: COACHING_CATEGORIES_FETCH_DATA,
    documentsCategories,
    trainingsCategories,
  });
};

export const COACHING_TRAININGS_FETCH_DATA = 'coaching/TRAININGS_FETCH_DATA';
export const coachingTrainingsFetchData = () => async (dispatch, getState) => {
  const trainings = await getCollection('trainings', {
    orderBy: [['date', 'asc']],
    where: [['date', '>', Date.now()]],
  });

  dispatch({
    type: COACHING_TRAININGS_FETCH_DATA,
    trainings: trainings.filter(userTargetingFiltering(getUser(getState()))).map(({ date, ...training }) => ({
      ...training,
      date: new Date(date).toLocaleString('fr-FR').replace(/:00$/, ''),
    })),
  });

  return trainings;
};

export const COACHING_DOCUMENTS_FETCH_DATA = 'coaching/DOCUMENTS_FETCH_DATA';
export const coachingDocumentsFetchData = () => async (dispatch, getState) => {
  const documents = await getCollection('documents', { orderBy: [['order', 'asc']] });

  dispatch({
    type: COACHING_DOCUMENTS_FETCH_DATA,
    documents: documents.filter(userTargetingFiltering(getUser(getState()))),
  });

  return documents;
};
