import React from 'react';
import { Platform, TextProps as NativeTextProps, Text as NativeText, StyleProp, TextStyle } from 'react-native';
import styled, { css } from 'styled-components/native';

import { getThemeColor, condition, get } from '../utils/theme.utils';
import { getRemoteText } from '../../data/firebase';

type TextProps = NativeTextProps & {
  style?: StyleProp<TextStyle>;
  remoteKey?: string;
  children?: any;
  fontWeight?: string;
  fontSize?: string;
  textAlign?: string;
};

const StyledText = styled(NativeText)<TextProps>`
  font-family: ${Platform.select({
    android: 'Roboto',
    ios: 'System',
    web: 'inherit',
  })};
  color: ${getThemeColor('text')};

  ${condition(
    'fontWeight',
    css`
      font-weight: ${get('fontWeight')};
    `,
  )}

  ${condition(
    'fontSize',
    css`
      font-size: ${get('fontSize')}px;
    `,
  )}

  ${condition(
    'textAlign',
    css`
      text-align: ${get('textAlign')};
    `,
  )}
`;

export const Text = ({ style, remoteKey, ellipsizeMode = 'tail', children, ...props }: TextProps) => {
  return (
    <StyledText style={style} ellipsizeMode={ellipsizeMode} maxFontSizeMultiplier={1.7} {...props}>
      {remoteKey ? getRemoteText(remoteKey) : children}
    </StyledText>
  );
};
