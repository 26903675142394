import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Platform } from 'react-native';
import { useLinkTo } from '@react-navigation/native';

import { Title } from '../../ui/components/title.component';
import { Button, ButtonTypes } from '../../ui/components/button.component';
import { ScrollView } from '../../navigation/components/scrollView.component';
import { colors } from '../../ui/constants/colors';
import { surveyReset } from '../../survey/survey.actions';
import { userSignOut } from '../user.actions';
import { Alert } from '../../ui/utils/alert';
import { useBreakpoint } from '../../ui/utils/theme.utils';
import { BREAKPOINTS } from '../../ui/constants/breakpoints';
import { getRemoteText, runCloudFunction } from '../../data/firebase';
import * as Sentry from '../../../utils/sentry';

export const ProfileActionsScreen = () => {
  const dispatch = useDispatch();
  const linkTo = useLinkTo();

  const openResetAlert = useCallback(() => {
    Alert.alert(
      'Suppression des réponses',
      "Êtes-vous sûr de vouloir supprimer l'ensemble de vos réponses ? Cette action est irréversible.",
      [
        {
          text: 'Annuler',
          style: 'cancel',
        },
        { text: 'OK', onPress: () => dispatch(surveyReset()) },
      ],
      { cancelable: false },
    );
  }, []);

  const signOut = useCallback(async () => {
    await dispatch(userSignOut());

    linkTo('/');
  }, []);

  const shouldDisplayTitle = useBreakpoint({ [BREAKPOINTS.MEDIUM]: false }, true);

  const openDeleteAlert = useCallback(async () => {
    const actionText = getRemoteText('user_delete_account');

    Alert.alert(
      actionText,
      getRemoteText('user_delete_account_warning'),
      [
        {
          text: 'Annuler',
          style: 'cancel',
        },
        {
          text: actionText,
          style: 'default',
          onPress: async () => {
            try {
              Alert.alert(actionText, getRemoteText('user_delete_account_confirmation'), [{ text: 'OK' }]);

              await runCloudFunction('user-deleteAccount');
            } catch (error) {
              Sentry.captureException(error);

              Alert.alert(actionText, getRemoteText('user_delete_account_error'), [{ text: 'OK' }]);

              return;
            }

            await dispatch(userSignOut());

            linkTo('/');
          },
        },
      ],
      { cancelable: false },
    );
  }, []);

  return (
    <ScrollView withFooter>
      {shouldDisplayTitle && <Title label="Autres" icon="ellipsis-horizontal" />}
      <Button
        type={ButtonTypes.NO_OUTLINE}
        title={getRemoteText('user_delete_account')}
        color={colors.tertiary}
        onPress={openDeleteAlert}
      />
      {Platform.OS !== 'web' && (
        <Button
          type={ButtonTypes.NO_OUTLINE}
          title="Supprimer l'ensemble de mes réponses"
          color={colors.tertiary}
          onPress={openResetAlert}
        />
      )}
      <Button type={ButtonTypes.NO_OUTLINE} title="Déconnexion" onPress={signOut} />
    </ScrollView>
  );
};
