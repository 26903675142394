import React from 'react';
import { Platform, TextProps as NativeTextProps, Text as NativeText } from 'react-native';
import styled, { css } from 'styled-components/native';

import { getThemeColor, condition, get, mq } from '../utils/theme.utils';
import { getRemoteText } from '../../data/firebase';

export enum TYPOGRAPHIES {
  SMALL = 'sm',
  MEDIUM = 'md',
  LARGE = 'lg',
  XLARGE = 'xlg',
  XXLARGE = 'xxlg',
}

export const TYPOGRAPHIES_RULES = {
  [TYPOGRAPHIES.SMALL]: css`
    font-size: 14px;

    ${mq.md(css`
      font-size: 16px;
    `)}

    ${mq.xxlg(css`
      font-size: 18px;
    `)}
  `,
  [TYPOGRAPHIES.MEDIUM]: css`
    font-size: 16px;
    line-height: 20px;

    ${mq.md(css`
      font-size: 18px;
      line-height: 22px;
    `)}

    ${mq.xxlg(css`
      font-size: 20px;
    `)}
  `,
  [TYPOGRAPHIES.LARGE]: css`
    font-weight: 500;
    font-size: 18px;

    ${mq.md(css`
      font-size: 20px;
    `)}

    ${mq.lg(css`
      font-size: 21px;
    `)}

    ${mq.xxlg(css`
      font-size: 22px;
    `)}
  `,
  [TYPOGRAPHIES.XLARGE]: css`
    font-weight: 600;
    font-size: 20px;

    ${mq.md(css`
      font-size: 22px;
    `)}

    ${mq.lg(css`
      font-size: 23px;
    `)}

    ${mq.xxlg(css`
      font-size: 25px;
    `)}
  `,
  [TYPOGRAPHIES.XXLARGE]: css`
    font-weight: 600;
    font-size: 35px;

    ${mq.md(css`
      font-size: 40px;
    `)}

    ${mq.lg(css`
      font-size: 50px;
    `)}

    ${mq.xxlg(css`
      font-size: 60px;
    `)}
  `,
};

type TextProps = NativeTextProps & {
  size?: TYPOGRAPHIES;
  color?: string;
  remoteKey?: string;
  children?: any;
  fontWeight?: string;
  fontSize?: string;
  textAlign?: string;
};

const StyledText = styled(NativeText)<TextProps & { size: TYPOGRAPHIES }>`
  font-family: ${Platform.select({
    android: 'Roboto',
    ios: 'System',
    web: 'inherit',
  })};
  color: ${props => props.color || getThemeColor('text')(props)};

  ${props => TYPOGRAPHIES_RULES[props.size]}

  ${condition(
    'fontWeight',
    css`
      font-weight: ${get('fontWeight')};
    `,
  )}

  ${condition(
    'fontSize',
    css`
      font-size: ${get('fontSize')}px;
    `,
  )}

  ${condition(
    'textAlign',
    css`
      text-align: ${get('textAlign')};
    `,
  )}
`;

export const Typography = ({
  remoteKey,
  ellipsizeMode = 'tail',
  children,
  size = TYPOGRAPHIES.MEDIUM,
  ...props
}: TextProps) => {
  return (
    <StyledText ellipsizeMode={ellipsizeMode} maxFontSizeMultiplier={1.7} size={size} {...props}>
      {remoteKey ? getRemoteText(remoteKey) : children}
    </StyledText>
  );
};
