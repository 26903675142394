import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import styled from 'styled-components/native';

import { getThemeColor } from '../utils/theme.utils';

import { TYPOGRAPHIES, Typography } from './typography.component';
import { Partners } from './partners.component';

const styles = StyleSheet.create({
  headerWrapper: {
    alignItems: 'center',
    padding: 15,
  },
  logoWrapper: {
    paddingVertical: 10,
  },
  logo: {
    marginRight: 10,
    marginBottom: 10,
  },
  contentWrapper: {
    padding: 15,
    alignItems: 'center',
  },
});

const Wrapper = styled(View)`
  flex: 1;
  justify-content: space-between;
`;

const Title = styled(Typography).attrs({ size: TYPOGRAPHIES.XXLARGE })`
  margin-vertical: 25px;
`;

const Subtitle = styled(Typography).attrs({ size: TYPOGRAPHIES.XLARGE })`
  color: ${getThemeColor('icon')};
  text-align: center;
  margin-vertical: 10px;
  padding-horizontal: 20px;
`;

export const AppTitle = ({ children, withPartners = false }) => {
  return (
    <Wrapper>
      <View style={styles.headerWrapper}>
        <Title remoteKey="app_name" />
        <Subtitle remoteKey="app_description" />
      </View>
      <View style={styles.contentWrapper}>{children}</View>
      {withPartners ? <Partners /> : <View />}
    </Wrapper>
  );
};

AppTitle.propTypes = {
  children: PropTypes.any,
  withPartners: PropTypes.bool,
};
