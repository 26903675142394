import React, { useMemo } from 'react';
import { Platform, View } from 'react-native';
import styled from 'styled-components/native';
import { useNavigation } from '@react-navigation/native';

import { Typography, TYPOGRAPHIES } from '../../ui/components/typography.component';
import { Separator } from '../../ui/components/separator.component';
import { Link } from './link.component';
import { getRemoteConfigKey } from '../../data/firebase';
import { openExternalLink } from '../navigation.utils';
import { Icon } from '../../ui/components/icon.component';
import { backgroundMixin, useScreenPadding } from '../../ui/utils/theme.utils';
import { ThemeProvider } from '../../ui/components/theme.component';
import { Partners } from '../../ui/components/partners.component';
import { FOOTER_HEIGHT } from '../const/screen';

const Wrapper = styled(View)`
  min-height: ${FOOTER_HEIGHT}px;

  ${backgroundMixin()}

  border-top-color: rgb(216, 216, 216);
  border-top-width: 1px;
  border-top-style: solid;

  padding-vertical: 30px;
  padding-bottom: 20px;
`;

const PanelsWrapper = styled(View)`
  flex-direction: row;
`;

const Panel = styled(View)`
  flex: 1;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
`;

type FooterProps = {
  autoPositioning?: boolean;
};

const appStoreLinkAnalytics = ['footer_open_store', { platform: 'apple' }];
const playStoreLinkAnalytics = ['footer_open_store', { platform: 'android' }];

export const Footer = ({ autoPositioning = true }: FooterProps) => {
  const navigation = useNavigation();

  const getLinkProps = (uri: string) =>
    Platform.select({
      web: {
        to: uri,
        hrefAttrs: { target: '_blank' },
      },
      default: {
        onPress: () => openExternalLink(navigation, uri),
      },
    });

  const paddings = useScreenPadding();
  const style = useMemo(
    () => [
      paddings,
      autoPositioning
        ? {
            position: 'absolute',
            left: -paddings.paddingHorizontal,
            right: -paddings.paddingHorizontal,
            bottom: -paddings.paddingVertical,
          }
        : null,
    ],
    [autoPositioning, paddings],
  );

  return (
    <ThemeProvider>
      <Wrapper style={style}>
          <Panel>
            <Typography size={TYPOGRAPHIES.SMALL} remoteKey="footer_download_apps" textAlign="center" />
            <PanelsWrapper>
              <Link {...getLinkProps(getRemoteConfigKey('links_app_store'))} analytics={appStoreLinkAnalytics}>
                <Icon size={32} marginRight="10" name="logo-apple" color="black" />
              </Link>
              <Link {...getLinkProps(getRemoteConfigKey('links_play_store'))} analytics={playStoreLinkAnalytics}>
                <Icon size={32} name="logo-android" color="black" />
              </Link>
            </PanelsWrapper>
          </Panel>
          <Panel>
            <Link {...getLinkProps(getRemoteConfigKey('links_terms_of_use'))}>
              <Typography size={TYPOGRAPHIES.SMALL} textAlign="center" remoteKey="terms_of_use" />
            </Link>
            <Separator size={10} />
            <Link {...getLinkProps(getRemoteConfigKey('links_privacy_policy'))}>
              <Typography size={TYPOGRAPHIES.SMALL} textAlign="center" remoteKey="privacy_policy" />
            </Link>
          </Panel>
        <Partners />
      </Wrapper>
    </ThemeProvider>
  );
};

const ListFooterComponent = () => <Footer autoPositioning={false} />;

export const ListFooterProps = {
  ListFooterComponent,
  ListFooterComponentStyle: { position: 'absolute', bottom: 0, left: 0, right: 0 },
};
