import { LABELS_FETCH_DATA } from './labels.actions';

const initialState = { items: [] };

export const labels = (state = initialState, action) => {
  switch (action.type) {
    case LABELS_FETCH_DATA:
      return {
        ...state,
        labels: action.labels,
      };

    default:
      return state;
  }
};
