import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components/native';

import { useFontScale } from '../../ui/components/fontScale.hook';
import { Icon } from '../../ui/components/icon.component';

export const TabBarIcon = ({ name, focused }) => {
  const {
    colors: { iconFocused, icon },
  } = useTheme();

  return (
    <Icon
      name={focused ? name : `${name}-outline`}
      size={26 * useFontScale(1.5)}
      color={focused ? iconFocused : icon}
    />
  );
};

TabBarIcon.propTypes = {
  name: PropTypes.string.isRequired,
  focused: PropTypes.bool,
};
