import { colors as staticColors } from '@valotvince/equireglementation-utils';
import { Platform } from 'react-native';

const primary = Platform.select({ default: '#63A46C', web: '#20441f' });
const secondary = 'white';
const icon = Platform.select({ default: staticColors.tertiary, web: 'rgba(255, 255, 255, 0.75)' });

export const colors = {
  primary,
  secondary,

  icon,
  iconFocused: secondary,

  ...staticColors,
};
